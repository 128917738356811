/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { getUserID } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";
import PersonItem from "./PersonItem";

class SearchGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      sortType: 1,
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader, sortType } = this.state;
    if (!loader && this.props.groupList.hasMore) {
      this.setState({
        loader: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupList.start,
          end,
          0,
          "",
          sortType
        ),
        (data) => {
          this.props.getGroupList(data.group_list);
          this.handleGroupApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearGroupState();
      }
    );
  };
  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.props.getGroupHasMoreValue(false);
    }
    this.props.getGroupStartValue(this.props.groupList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  setSearchGroupList = (groupList) => {
    this.setState({
      searchGroupList: groupList,
    });
  };

  handleGroupDetail = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="container my-4">
                {this.props.group_list &&
                  this.props.group_list.map((data, index) => {
                    const {
                      id,
                      group_name,
                      group_type_icon,
                      person,
                      description,
                    } = data;
                    return (
                      <div
                        className="icon-box-style2 mb-2 pointer"
                        key={index}
                        onClick={() => {
                          this.handleGroupDetail(id, person);
                        }}
                      >
                        <div className="icon-box blue-bg d-flex">
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="rounded-50 bg-light">
                              <img
                                src={`data:image/png;base64,${
                                  group_type_icon && group_type_icon
                                }`}
                                style={{ width: "32px" }}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 my-3 d-inline-block">
                          <h5 className="m-0 font-18">
                            {group_name && group_name}
                          </h5>
                          {person == 0 ? (
                            <p className="text-muted font-12 mb-0">
                              {description && description}
                            </p>
                          ) : (
                            <p className="text-muted font-12 mb-0">
                              {person && person}{" "}
                              {t("GroupListScreen.contactPerson")}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="container my-4">
                {this.props.person_list &&
                  this.props.person_list.map((data, index) => {
                    return <PersonItem data={data} key={index} />;
                  })}
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SearchGroupList)));
