import { CHANNEL_DETAIL,UPDATE_CHANNEL_SENDING_STATUS,UPDATE_MESSAEG_LINK } from "../constants/action-types";

export const getChannelDetail = data => ({
         type: CHANNEL_DETAIL,
         payload: data
       });

export const updateChannelSendingStatus = sending_status => ({
        type: UPDATE_CHANNEL_SENDING_STATUS,
        payload: sending_status
      });
export const updateChannelMessageLink = message_link => ({
        type: UPDATE_MESSAEG_LINK,
        payload: message_link
      });
