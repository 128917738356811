import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

class ContactPersonInfo extends Component {
  render() {
    const { email, phone, mobile } =
      this.props.groupPersonDetail && this.props.groupPersonDetail;
    return (
      <React.Fragment>
        <div className="icon-box-style3 mb-2">
          <div className="ml-3 my-2 clearfix">
            <div className="d-flex align-items-center w-100">
              <div className="media">
                <div className="media-left mr-2">
                  <i className="zmdi zmdi-email zmdi-hc-fw font-22 align-middle"></i>
                </div>
                <div className="media-body">
                  {/* <p className="font-14 m-0">{email && email}</p> */}
                  <a href={`mailto: ${email && email} `} className="font-16 m-0">{email && email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="icon-box-style3 mb-2">
          <div className="ml-3 my-2 clearfix">
            <div className="d-flex align-items-center w-100">
              <div className="media">
                <div className="media-left mr-2">
                  <i className="zmdi zmdi-phone zmdi-hc-fw font-22 align-middle"></i>
                </div>
                <div className="media-body">
                  <a href={`tel: ${phone && phone} `} className="font-16 m-0">{phone && phone}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="icon-box-style3 mb-2">
          <div className="ml-3 my-2 clearfix">
            <div className="d-flex align-items-center w-100">
              <div className="media">
                <div className="media-left mr-2">
                  <i className="zmdi zmdi-smartphone-iphone zmdi-hc-fw font-22 align-middle"></i>
                </div>
                <div className="media-body">
                  <a href={`tel: ${mobile && mobile}`} className="font-16 m-0">{mobile && mobile}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withRouter(ContactPersonInfo));