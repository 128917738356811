/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSocialDetails,
  removechannelmessage,
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import EmailChannelSend from "./EmailChannelSend";
import FacebookChannelSend from "./FacebookChannelSend";
import SMSSend from "./SMSSend";
import TexttoSpeechSend from "./TexttoSpeechSend";
import TwitterChannelSend from "./TwitterChannelSend";

const snippet_type_distributor = ["text2speech", "sms", "email"];

class ChannelDetailSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      socialData: {},
      editMessagePermission: false,
      sendMessagePermission: false,
      deleteMessagePermission: false,
      deleteLoader: false,
    };
  }

  componentDidMount() {
    const deleteMessagePermission  = checkUserPermission("delete_message");
    const editMessagePermission = checkUserPermission("edit_message");
    const sendMessagePermission = checkUserPermission("send_message");
    this.setState({
      editMessagePermission,
      sendMessagePermission,
      deleteMessagePermission
    });
    apiCall(
      METHOD.QUERY,
      getSocialDetails(getUserID()),
      (data) => {
        this.setState({ socialData: data });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  deleteMessage = () => {
    const message_id = this.props.match.params.message_id;

    confirmAlert({
      title: this.props.t("ChannelDetail.deleteMessage"),
      message: this.props.t("ChannelDetail.deleteTitle"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removechannelmessage(getUserID(), message_id),
              (data) => {
                let mybackStackpath =
                  "/event/" +
                  this.props.match.params.event_id +
                  "/report/" +
                  this.props.match.params.report_id;
                this.props.history.push(mybackStackpath);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    const {
      socialData,
      editMessagePermission,
      sendMessagePermission,
      deleteMessagePermission,
      deleteLoader,
    } = this.state;
    const { channel_type_identifier } = this.props.channelDetail;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  {snippet_type_distributor.includes(channel_type_identifier) &&
                    this.props.channelDetail.message_status_identifier ==
                      "" && (
                      <li className="nav-item">
                        <a
                          href="javascript;:"
                          className="float-right mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.changeRouteStatus(1);
                          }}
                        >
                          <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                            {t("ChannelDetail.addRecipientLable")}
                          </p>
                          <div className="float-left rounded-40 red-bg text-center">
                            <img
                              className="m-0"
                              src="/images/meldung-neu.png"
                              alt="ico"
                            />
                          </div>
                        </a>
                      </li>
                    )}

                  {editMessagePermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(2);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("ChannelDetail.editChannelReport")}
                        </p>
                        <div className="float-left rounded-40 red-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}

{deleteMessagePermission &&  <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteMessage();
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("ChannelDetail.deleteMessage")}
                      </p>
                      {deleteLoader ? (
                        <div className="float-left rounded-40 red-bg text-center disabled">
                          <div className="spinner-border spinner-border-sm ml-1 text-blue">
                            <span className="sr-only">
                              {t("GeneralMessages.loading")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="float-left rounded-40 red-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      )}
                    </a>
                  </li>
}
                 
                  {sendMessagePermission && (
                    <li className="nav-item">
                      {channel_type_identifier &&
                        channel_type_identifier == "text2speech" && (
                          <TexttoSpeechSend
                            closeSlider={this.props.closeSlider}
                          />
                        )}

                      {channel_type_identifier &&
                        channel_type_identifier == "sms" && (
                          <SMSSend closeSlider={this.props.closeSlider} />
                        )}
                      {channel_type_identifier &&
                        channel_type_identifier == "email" && (
                          <EmailChannelSend
                            closeSlider={this.props.closeSlider}
                          />
                        )}
                      {channel_type_identifier &&
                        channel_type_identifier == "facebook" && (
                          <FacebookChannelSend
                            closeSlider={this.props.closeSlider}
                            appID={socialData.facebook_app_id}
                            appSecretKey={socialData.facebook_app_secret}
                          />
                        )}
                      {channel_type_identifier &&
                        channel_type_identifier == "twitter" && (
                          <TwitterChannelSend
                            closeSlider={this.props.closeSlider}
                            consumerKey={socialData.twitter_consumer_key}
                            consumerSecret={socialData.twitter_consumer_secret}
                          />
                        )}
                    </li>
                  )}
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([actions.asyncApiDispatchToProps])
)(withRouter(withTranslation()(ChannelDetailSetteliteSideMenu)));
