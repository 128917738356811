import { ADD_SUB_NEW_MAIL, MAILING_DETAILS } from "../constants/action-types";

const initialState = {};
export default function mailDetailReducer(state = initialState, action) {
  switch (action.type) {
    case MAILING_DETAILS:
      return action.payload;

    case ADD_SUB_NEW_MAIL:
      return {
        ...state,
        ...{
          child_element: state.child_element + 1,
          child_list: [...action.payload, ...state.child_list],
        },
      };
    default:
      return state;
  }
}
