/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ArchiveEventQuery,
  DeleteEventQuery,
  reActivateEventQuery
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";

class ReportSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editEventPermission: false,
      archiveEventPermission: false,
      newMessagePermission: false,
      deleteEventPermission: false,
      deleteEventProgress: false,
    };
  }

  componentDidMount() {
    const editEventPermission = checkUserPermission("edit_event");
    const archiveEventPermission = checkUserPermission("archive_event");
    const newMessagePermission = checkUserPermission("create_report");
    const deleteEventPermission = checkUserPermission("delete_event");
    this.setState({
      editEventPermission,
      archiveEventPermission,
      newMessagePermission,
      deleteEventPermission,
    });
  }

  reActivateEvent = () => {
    const event_id = this.props.match.params.event_id;

    apiCall(
      METHOD.QUERY,
      reActivateEventQuery(getUserID(), event_id),
      (data) => {
        toast.success(this.props.t(data.message));
        const {
          id,
          name,
          event_datetime,
          event_type_icon,
          event_creator_name,
          location,
        } = this.props.reportList;
        let event = {
          id: id,
          name: name,
          event_datetime: event_datetime,
          event_type_icon: event_type_icon,
          event_creator_name: event_creator_name,
          location: location,
        };
        let count = this.props.eventList.total_event;
        count = count && count + 1;
        this.props.setEventCount(count);
        this.props.addNewEvent([event]);
        this.props.history.push("/home");
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {}
    );
  };
  archiveEvent = () => {
    const event_id = this.props.match.params.event_id;
    apiCall(
      METHOD.QUERY,
      ArchiveEventQuery(getUserID(), event_id),
      (data) => {
        let count = this.props.eventList.total_event;
        count = count && count - 1;
        this.props.setEventCount(count);
        toast.success(this.props.t(data.message));
        this.props.closeSlider();
        this.props.deleteEvent({ id: event_id });
        this.props.history.push("/home");
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {}
    );
  };

  deleteEvent = () => {
    const event_id = this.props.match.params.event_id;
    apiCall(
      METHOD.QUERY,
      DeleteEventQuery(getUserID(), event_id),
      (data) => {
        let count = this.props.eventList.total_event;
        count = count && count - 1;
        this.props.setEventCount(count);
        toast.success(this.props.t(data.message));
        this.props.closeSlider();
        this.props.deleteEvent({ id: event_id });
        this.props.history.push("/home");
      },
      (isLoading) => {
        this.setState({
          deleteEventProgress: true,
        });
      },
      (data) => {}
    );
  };
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    const {
      isLoading,
      editEventPermission,
      archiveEventPermission,
      newMessagePermission,
      deleteEventPermission,
      deleteEventProgress,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  {editEventPermission && (
                    <li className="nav-item ">
                      <a
                        href="javascript;:"
                        className="float-right mr-3 pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(1);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("EventDetails.editEvent")}
                        </p>
                        <div className="float-left rounded-40 red-bg text-center">
                          <img
                            className="m-0"
                            src="/images/brand-icon-w.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}
                  {archiveEventPermission && this.props.is_archive == 0 && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.archiveEvent();
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("EventDetails.archiveEvent")}
                        </p>
                        <div className="float-left rounded-40 red-bg text-center">
                          {isLoading ? (
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          ) : (
                            <img
                              className="m-0"
                              src="/images/archivieren.png"
                              alt="ico"
                            />
                          )}
                        </div>
                      </a>
                    </li>
                  )}
                  {deleteEventPermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.deleteEvent();
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("EventDetails.deleteEvent")}
                        </p>
                        <div className="float-left rounded-40 red-bg text-center">
                          {deleteEventProgress ? (
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          ) : (
                            <img
                              className="m-0"
                              src="/images/archivieren.png"
                              alt="ico"
                            />
                          )}
                        </div>
                      </a>
                    </li>
                  )}
                  {newMessagePermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(2);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("EventDetails.newMessage")}
                        </p>
                        <div className="float-left rounded-40 red-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.eventListStateToProps,
        stateToProps.reporttListStateToProps,
        stateToProps.eventEventCountStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.deleteEventReportDispatchToProps,
    actions.eventListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(ReportSetteliteSideMenu)));
