import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkUser } from "../../util/utils";

function PrivateRoute({ component: Component, ...rest }) {
  
  return (
    <>
      <Route
        {...rest}
        render={props =>
          checkUser() ? (
            <Component {...props} />
          ) : (
              <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    </>
  );
}

export default PrivateRoute;
