import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

class ContactPersonOtherInfo extends Component {
    render() {
      const { other_field_list } =
        this.props.groupPersonDetail && this.props.groupPersonDetail;
      
        return (
          <React.Fragment>
            {other_field_list && other_field_list.map((item,index) => {
              return (<div className="icon-box-style3 mb-2" key={index}>
                <div className="ml-3 my-2 clearfix">
                  <div className="d-flex0 w-100">

                    <div className="float-left">
                      {item.field_name + " : " + item.field_value }
                    </div>
                  </div>
                </div>
              </div>)
            })}
          </React.Fragment>
        );
    }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withRouter(ContactPersonOtherInfo));