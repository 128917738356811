import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ChangePassQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import { RecoverPasswordSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";

 class ChangeUserPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

   handleSubmit = values => {
    const { current_password, new_password } = values;
    apiCall(
      METHOD.QUERY,
      ChangePassQuery(getUserID(), current_password, new_password),
      data => {
        toast.success(this.props.t(data.message));
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from); 
      },
      isLoading => {this.setState({isLoading})},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  render() {
    const { isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={RecoverPasswordSchema(
                  t("changePassword.currentPasswordValidation"),
                  t("changePassword.toShortValidation"),
                  t("changePassword.toLongValidation"),
                  t("changePassword.passwordValidation"),
                  t("changePassword.comparePasswordValidation")
                )}
                onSubmit={values => {
                  this.handleSubmit(values);
                }}
              >
                {({  handleSubmit }) => (
                  <React.Fragment>
                    <div className="header-bg header-blue sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          <span
                            className="pointer"
                            onClick={() => {
                              let { from } = this.props.location.state || {
                                from: { pathname: "/" }
                              };
                              this.props.history.push(from);
                            }}
                          >
                            <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                          </span>
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {t("changePassword.title")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group">
                            <label className="font-14">
                              {t("changePassword.currentPassword")}
                            </label>
                            <Field
                              name="current_password"
                              placeholder="************"
                              className="form-control"
                              type="password"
                              required
                            />
                            <ErrorMessageFormik name="current_password" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("changePassword.newPassword")}
                            </label>
                            <Field
                              name="new_password"
                              placeholder="************"
                              className="form-control"
                              required
                              type="password"
                            />
                            <ErrorMessageFormik name="new_password" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("changePassword.repearNewPassword")}
                            </label>
                            <Field
                              name="repear_password"
                              placeholder="************"
                              className="form-control"
                              type="password"
                              required
                            />
                            <ErrorMessageFormik name="repear_password" />
                          </div>

                          <div className="form-group text-center mt-4">
                            {isLoading ? (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-success btn-block"
                              >
                                {t("changePassword.title")}

                                <div className="spinner-border spinner-border-sm ml-1 text-white">
                                  <span className="sr-only">{t("GeneralMessages.loading")}</span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-success btn-block"
                              >
                                  {t("changePassword.changePasswordButton")}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(ChangeUserPassword));
