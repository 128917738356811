import {
  CHANNEL_LIST,
  UPDATE_CHANNEL_LIST,
  UPDATE_CHANNEL_DETAIL
} from "../constants/action-types";

export const getChannelList = data => ({
  type: CHANNEL_LIST,
  payload: data
});
export const updateChannelList = data => ({
  type: UPDATE_CHANNEL_LIST,
  payload: data
});

export const updateChannelDetail = data => ({
  type: UPDATE_CHANNEL_DETAIL,
  payload: data
});
// export const addNewGroup = data => ({
//   type: ADD_NEW_GROUP,
//   payload: data
// });
// export const getGroupHasMoreValue = data => ({
//   type: GROUP_HASMORE_VALUE,
//   payload: data
// });

// export const getGroupStartValue = data => ({
//   type: GROUP_START_VALUE,
//   payload: data
// });

// export const clearGroupState = data => ({
//   type: CLEAR_GROUP,
//   payload: data
// });

// export const updateGroupById = data => ({
//   type: UPDATE_GROUP_BY_ID,
//   payload: data
// });
