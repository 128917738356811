/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  adminSnippetList,
  deleteSnippet,
  getSnippetType,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID, removeDuplicatesWithoutReverse } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import AdminSetteliteSideMenu from "./AdminSetteliteSideMenu";

class SnippetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      snippet_list: [],
      snippet_type_list: [],
      options: [],
      start: 0,
      end: 10,
      hasMore: true,
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      snippet_type: 0,
      filterSnippetType: {},
    };
  }

  componentDidMount() {

      if(!checkAdminPermission()){
          this.props.history.push("/")
        }

    apiCall(
      METHOD.QUERY,
      getSnippetType(),
      (data) => {
        let options = [];
        data.snippet_type_list &&
          data.snippet_type_list.map((d) =>
            options.push({ value: d.id, label: d.name })
          );

        this.setState({
          snippet_type_list: data.snippet_type_list,
          options,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  loadSnippet = () => {
    const { start, end, hasMore, isLoading, snippet_type } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        adminSnippetList(getUserID(), start, end, snippet_type),
        (data) => {
          this.handleSnippetResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleFilterChange = (filterSnippetType) => {
    if (Object.keys(filterSnippetType).length == 0) {
      this.setState(
        {
          filterSnippetType: {},
          snippet_type: 0,
          hasMore: true,
          start: 0,
          snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadSnippet();
        }
      );
    } else {
      this.setState(
        {
          filterSnippetType,
          snippet_type: filterSnippetType.value,
          hasMore: true,
          start: 0,
          snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadSnippet();
        }
      );
    }
  };

  handleSnippetResponse = (data) => {
    const { end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_snippet = this.state.snippet_list;
    let tempSnippetList= data.snippet_list
    old_snippet = [...old_snippet,...tempSnippetList]
  //  old_snippet = removeDuplicatesWithoutReverse(old_snippet, "id");

    this.setState((prevState) => {
      return {
        start: prevState.start + end,
        snippet_list: old_snippet,
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("administration.confirmTodelete"),
      message: this.props.t("administration.confirmTodeleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteSnippet(getUserID(), id),
              (data) => {
                let afterDeletedSnippetList = this.state.snippet_list.filter(
                  (snippet) => {
                    return snippet.id != item.id;
                  }
                );

                toast.success(this.props.t(data.message));
                this.setState({
                  snippet_list: afterDeletedSnippetList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filterSnippetType: {},
        snippet_type: 0,
        hasMore: true,
        start: 0,
        snippet_list: [],
        isLoading: false,
      },
      () => {
        this.loadSnippet();
      }
    );
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  loader = (<LoadingPage key={0} />);
  render() {
    const {
      hasMore,
      end,
      snippet_list,
      options,
      deleteLoadingStatus,
      filterSnippetType,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      <Link to="/administration" className="text-light">
                        {t("administration.snippetListTitle")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 left-line left-line-custom">
                <div className="position-relative">
                  <Select
                    value={filterSnippetType}
                    onChange={(value) => this.handleFilterChange(value)}
                    options={options}
                    className="p-2 w-100 z-index-999"
                    clearable={false}
                    placeholder={t("administration.filterSnippet")}
                  />
                  {Object.keys(filterSnippetType).length != 0 && (
                    <span className="dropdown-close-icon">
                      <i
                        className="zmdi zmdi-close zmdi-hc-lg align-middle pointer"
                        onClick={() => this.clearFilter()}
                      />
                    </span>
                  )}
                </div>
                {deleteLoadingStatus && this.loader}

                <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadSnippet}
                  hasMore={hasMore}
                  useWindow={false}
                  className="left-line-custom"
                  loader={this.loader}
                  threshold={10}
                >
                  {snippet_list &&
                    snippet_list.map((item, index) => {
                      const { name, snippet_type_name } = item;
                      return (
                        <div
                          className={
                            index == snippet_list.length - 1
                              ? "container my-2 z-index-99 last"
                              : "container my-2  z-index-99"
                          }
                          key={index}
                        >
                          <a
                            href="javascript;:"
                            className="mb-4 d-block custom-hover-box "
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="rounded-40 admin-bg float-left ml-5p">
                              <span className="text-light"></span>
                            </div>
                            <div className="pl-3 pt-1 d-flex" key={index}>
                              <div className="d-flex justify-content-between w-100">
                                <div className="clearfix">
                                  <h6 className="m-0">{name && name}</h6>
                                  <p className="text-muted font-12 mb-2">
                                    {snippet_type_name && snippet_type_name}
                                  </p>
                                </div>
                                <div className="action-btns clearfix">
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.changeStatusWithStatusValue(
                                        2,
                                        item
                                      );
                                    }}
                                  />
                                  <i
                                    className="zmdi zmdi-delete zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleDelete(item);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>

              <AdminSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeStatusWithStatusValue}
              />

              <FabIcon color="rounded-red" openSlider={this.openSlider} />

              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SnippetList));
