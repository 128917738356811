import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkUserPermission } from '../../util/utils';

 class SideMenuItem extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
    }
  }
  

  componentDidMount() {
    const adminPermission = checkUserPermission("administration");
    this.setState({
      adminPermission
    });
  }
  render() {
  const { icon, title, type, link,identifier } = this.props;
  const {adminPermission} = this.state

    return (
      <React.Fragment>
      {type === 2 ? (
        <li className="nav-item" onClick={()=>{
          if(identifier=='administration'){
            if(adminPermission){
              this.props.history.push(link)
            }else{
              toast.success("permission denied")
            }
          }else{
              this.props.history.push(link)
          }
        }}>
          <a className="nav-link" href="javascript;:" onClick={(e)=>{e.preventDefault()}}>
            <span className="w-20">
              <img
                src={`data:image/png;base64,${icon && icon}`}
                alt="sidemenu_icon"
              />
            </span>
            {title && title}
          </a>
        </li>
      ) : (
        <li className="nav-item">
          <a
            className="nav-link"
            href={link && link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="w-20">
            <img
              src={`data:image/png;base64,${icon && icon}`}
              alt="sidemenu_ico"
            />
            </span>
            {title && title}
          </a>
        </li>
      )}
    </React.Fragment>
 
    )
  }
}
export default withRouter(SideMenuItem)