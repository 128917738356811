import {
  GROUP_LIST,
  ADD_NEW_GROUP,
  GROUP_HASMORE_VALUE,
  GROUP_START_VALUE,
  CLEAR_GROUP,
  UPDATE_GROUP_BY_ID,
  DELETE_GROUP
} from "../constants/action-types";

export const getGroupList = data => ({
  type: GROUP_LIST,
  payload: data
});

export const addNewGroup = data => ({
  type: ADD_NEW_GROUP,
  payload: data
});
export const getGroupHasMoreValue = data => ({
  type: GROUP_HASMORE_VALUE,
  payload: data
});

export const getGroupStartValue = data => ({
  type: GROUP_START_VALUE,
  payload: data
});

export const clearGroupState = data => ({
  type: CLEAR_GROUP,
  payload: data
});

export const updateGroupById = data => ({
  type: UPDATE_GROUP_BY_ID,
  payload: data
});
export const deleteGroup = data => ({
  type: DELETE_GROUP,
  payload: data
});
