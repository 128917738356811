/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EditMail from "../MailDetailsPersonList/EditMail";
import GeneralGGroupList from "./GeneralGGroupList";
import GeneralPersonList from "./GeneralPersonList";
import GroupListAddContacts from "./GroupListAddContacts";
import PersonList from "./PersonList";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      data : {}
    };
  }

  storeApiData = (data) => {
    this.setState({
      data
    })
  }
  changeRouteStatus = value => {
    this.setState(prevState => {
      return {
        routeStatus: value
      };
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <PersonList storeApiData={this.storeApiData} changeRouteStatus={this.changeRouteStatus} />
         )}
          {routeStatus == 1 && (
            <GeneralPersonList changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 2 && (
            <GeneralGGroupList changeRouteStatus={this.changeRouteStatus} />
          )}

          {routeStatus == 3 && (
            <EditMail data={this.state.data} changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 4 && (
            <GroupListAddContacts changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
