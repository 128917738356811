/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";

class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      range: 0,
      child_group_list: [],
      isSubGroupList: false,
      isChild_Group_Loading: true,
    };
  }

  componentWillReceiveProps = () => {
    const { child_group_list } = this.state;
    const new_list = this.props.group_header_list;
    if (
      new_list.length != 0 &&
      JSON.stringify(child_group_list) != JSON.stringify(new_list)
    ) {
      this.setState({
        child_group_list: this.props.group_header_list,
      });
      this.setState({
        isChild_Group_Loading: true,
      });
    } else {
      this.setState({
        isChild_Group_Loading: false,
      });
    }
  };
  // handleChangeGroup = group_id => {
  //   this.props.history.push(
  //     "/group-detail/" + group_id
  //   );

  //   this.props.clearStateGroupDetail({});
  //   this.loadItem();
  // };
  handleChangeGroup = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.setState({ isSubGroupList: true });
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.setState({ isSubGroupList: false });
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      this.handleScroll(group_id);

      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const { child_group_list } = this.state;
    const index =
      child_group_list && child_group_list.findIndex((group) => group.id == id);

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  render() {
    const { group_header_list,name } = this.props;
    const { isChild_Group_Loading } = this.state;
    const pathName = this.props.location.pathname;

    let group_id = 0;
    if (pathName.includes("group-detail")) {
      group_id = this.props.groupDetail.id;
    } else if (pathName.includes("group-personlist")) {
      group_id = this.props.groupDetailPersonList.id;
    }
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="header-bg header-blue sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                   <p className="mb-0 ml-auto text-white flex-fill">{name}</p>
                    <LogoutMenu />
                  </div>

                  <ul className="header-menu-tab-link mt-auto" id="ulID">
                    {isChild_Group_Loading ? (
                      <div className="loader-container">
                        <div className="spinner-border text-light"></div>
                      </div>
                    ) : (
                      group_header_list.map((data, index) => {
                        const { id, name, person } = data;
                        return (
                          <li
                            id={`li_${id}`}
                            key={index}
                            className={this.handleActiveIndex(group_id, id)}
                          >
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeGroup(id, person);
                              }}
                            >
                              {name}
                            </a>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </div>
              </div>
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetails)));
