import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router-dom";
import {
  checkUserPermission,
  getValueFromLocalStorage,
  saveDatatoLocalStorage,
} from "../../util/utils";

class LogoutMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerMenuStatus: false,
      placeHolder: "",
      selfServicePermission:false
    };
  }

  handleHeaderMenu = () => {
    this.setState((prevState) => {
      return {
        headerMenuStatus: !prevState.headerMenuStatus,
      };
    });
  };
  handleLogout = () => {
    const systemLogo = localStorage.getItem("system_logo");
    localStorage.clear();
    saveDatatoLocalStorage("system_logo", systemLogo);

    const { pathname } = this.props.location;
    const location = {
      pathname: `/`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleChangePassword = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/change-password",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handelManageProfile = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/update-profile",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  changeLanguage = () => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/change-language",
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleClickOutside = () => {
    if (this.state.headerMenuStatus) {
      this.handleHeaderMenu();
    }
  };

  componentDidMount() {

    const selfServicePermission = checkUserPermission("self_service");


    let first_name, name;
    first_name =
      getValueFromLocalStorage("first_name") &&
      getValueFromLocalStorage("first_name").charAt(0).toUpperCase();
    name =
      getValueFromLocalStorage("name") &&
      getValueFromLocalStorage("name").charAt(0).toUpperCase();

    this.setState({
      placeHolder: (first_name && first_name) + (name && name),
      image: getValueFromLocalStorage("image"),
      selfServicePermission
    });
  }

  render() {
    const { headerMenuStatus, placeHolder, image,selfServicePermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div
                className={
                  headerMenuStatus
                    ? "dropdown dropdown-usermenu ml-auto show"
                    : "dropdown dropdown-usermenu ml-auto "
                }
                style={{ verticalAlign: "center" }}
              >
                <div
                  className="rounded-40 rounded-white font-13 pointer d-flex position-relative"
                  onClick={this.handleHeaderMenu}
                >
                  <span className="user-img-text">
                    {" "}
                    {placeHolder && placeHolder}{" "}
                  </span>
                  <div className="custom-user-img">
                    {image && (
                      <img
                        className="rounded-40 "
                        alt="icon"
                        src={`data:image/png;base64,${image && image}`}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    headerMenuStatus
                      ? "dropdown-menu dropdown-menu-right  border-0 show"
                      : "dropdown-menu dropdown-menu-right  border-0"
                  }
                >
                  <div className="log-arrow-up"></div>
                 
                  

  {selfServicePermission && <a
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handelManageProfile();
                    }}
                    href="javascript;:"
                  >
                    <i className="zmdi zmdi-account zmdi-hc-lg"></i>{" "}
                    {t("logOutMenu.manageProfile")}
                  </a>}
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      this.changeLanguage();
                    }}
                    href="javascript;:"
                  >
                    <i className="zmdi zmdi-account zmdi-hc-lg"></i>
                    {t("logOutMenu.changeLanguage")}
                  </a>


                  {selfServicePermission &&   <a
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleChangePassword();
                    }}
                    href="javascript;:"
                  >
                    <i className="zmdi zmdi-shield-security zmdi-hc-lg"></i>{" "}
                    {t("logOutMenu.changePassword")}
                  </a>}
                
                  <hr className="m-0" />
                  <a
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleLogout();
                    }}
                    href="javascript;:"
                  >
                    <i className="zmdi zmdi-power zmdi-hc-lg"></i>
                    {t("logOutMenu.logOut")}
                  </a>
               
               
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(onClickOutside(LogoutMenu)));
