import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { VerifyUserQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";


class VerifyUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
    };
  }

    componentDidMount() {
        let reset_token = this.props.history.location.hash;
    reset_token = reset_token.replace("#", "");

    apiCall(
      METHOD.QUERY,
      VerifyUserQuery(reset_token),
      (data) => this.handleAfterApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  handleAfterApiCall = (data) => {
    this.setState({
      message:data.message,
    });
  };

  render() {
    const { message } = this.state
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sab-header1 sticky-top">
                <div className="container">
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("registerUser.verify_user_lable")}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="middle-section sab-middle-section1">
                <div className="container my-4 00z-index-99">
                  <h4 className="m-0">{t(message)}</h4>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(VerifyUser));