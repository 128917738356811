import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { unSubscribeUser } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

class UserUnSubScribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: ""
    };
  }

  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };

  handleOnChangeEmail = e => {
    this.setState({
      email: e.target.value
    });
  };

  handleSubmit = () => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    const { email } = this.state;

    if (!email) {
          toast.info(this.props.t("userSubscribe.emailValidation"));

    } else if (!regexEmail.test(email)) {
           toast.info(this.props.t("userSubscribe.validEmailValidation"));

    } else {
      apiCall(
        METHOD.QUERY,
        unSubscribeUser(email),
        data => {
          this.setState({ email: "" });
          toast.success(this.props.t(data.message));
        },
        isLoading => {
          this.setState({ isLoading });
        },
      (data) => {
        toast.error(this.props.t(data.message));
      }
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div>
              <div className="text-center flex-center flex-column">
                <h1 className="text-hide animated fadeIn mb-4 subscribe-user">
                  {t("userSubscribe.topHeader")}
                </h1>
                <div
                  className="card d-inline-flex col-example"
                  style={{ height: "400px" }}
                >
                  <h5 className="card-header header pt-3 peach-gradient text-white">
                    <strong>{t("userSubscribe.title")}</strong>
                  </h5>

                  <div className="card-body px-lg-5 pt-0">
                    <p>{t("userSubscribe.subTitle")}</p>
                    <p>{t("userSubscribe.description")}</p>
                    <form className="md-form" style={{ color: "#757575" }}>
                      <div className="form-group">
                        <FloatingLabel
                          placeholder={t("userSubscribe.emailPlaceHolder")}
                          type="text"
                          onChange={e => this.handleOnChangeEmail(e)}
                          value={this.state.email}
                        />
                      </div>

                      <div className="py-2 d-flex align-items-center">
                        {isLoading ? (
                          <div className="btn bg-success font-13 btn-block pointer subscribe-btn disabled">
                            {t("userSubscribe.save")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn bg-success font-13 btn-block pointer subscribe-btn"
                            onClick={this.handleSubmit}
                          >
                            {t("userSubscribe.save")}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailPersonListDispatchToProps,
    actions.asyncApiDispatchToProps
  ])
)(withTranslation()(withRouter(UserUnSubScribe)));
