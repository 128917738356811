/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import {
  DELETE_PERSON_DISTRIBUTOR,
  GROUP_PERSON_DETAIL,
} from "../constants/action-types";

const initialState = {};
export default function groupPersonDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_PERSON_DETAIL:
      return { ...action.payload };
    case DELETE_PERSON_DISTRIBUTOR:
      let deletedState = state.mailing_list.filter((item) => {
        return item.id != action.payload;
      });
      return { ...state, mailing_list: deletedState };
    default:
      return state;
  }
}
