import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUser, saveDatatoLocalStorage } from "../../util/utils";
import { LoginwithEmailSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: true,
      isLoading: false,
      systemLogo: "",
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  handleAfterApiCall = (data) => {
    this.props.getLoginDetail(data);
    saveDatatoLocalStorage("user_id", data.user_id);
    saveDatatoLocalStorage("first_name", data.first_name);
    saveDatatoLocalStorage("name", data.name);
    saveDatatoLocalStorage("image", data.image);
    saveDatatoLocalStorage("user_role", JSON.stringify(data.user_role));

    // this.props.history.push("/home");
    let { from } = this.props.location.state || { from: { pathname: "/home" } };
    //this.props.history.push(from)
    // alert(this.props.location.state);
    window.location.href = from.pathname;

    // this.props.history.push(from);
    // window.location.reload();
  };

  handlePasswordType = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        passwordType: !prevState.passwordType,
      };
    });
  };

  handleSubmit = (values) => {
    const { email, password } = values;
    apiCall(
      METHOD.QUERY,
      LoginQuery(email, password),
      (data) => this.handleAfterApiCall(data),
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    if (checkUser()) {
      this.props.history.push("/home");
    }

    this.setState({
      systemLogo: localStorage.getItem("system_logo"),
    });
  }

  handleRegisterClick = () => {
    this.props.history.push("/user/register");
  };

  render() {
    const { passwordType, isLoading, systemLogo } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="wrapper position-relative overflow-auto">
                <div className="pt-5 pb-3 d-block text-center">
                  {systemLogo ? (
                    <img
                      src={`data:image/png;base64,${systemLogo}`}
                      alt="loginLogo"
                      style={{ width: "150px", height: "150px" }}
                    />
                  ) : (
                    <img
                      src="/images/login_logo.png"
                      alt="loginLogo"
                      style={{ width: "150px", height: "150px" }}
                    />
                  )}
                </div>

                <div className="container login-container">
                  <div className="my-5">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={LoginwithEmailSchema(
                        t("LoginScreen.emailrequire"),
                        t("LoginScreen.validEmailRequire"),
                        t("LoginScreen.passwordRequire")
                      )}
                      onSubmit={(values) => {
                        this.handleSubmit(values);
                      }}
                    >
                      {({ handleSubmit }) => (
                        <form className="login-form">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {t("LoginScreen.emailAddress")}
                            </label>
                            <Field
                              type="email"
                              name="email"
                              value={this.state.password}
                              className="form-control"
                              placeholder={t("LoginScreen.emailPlaceHolder")}
                              required
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-danger"
                            >
                              <ErrorMessageFormik name="email" />
                            </small>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              {t("LoginScreen.password")}
                            </label>
                            <div className="password-group-field">
                              <Field
                                name="password"
                                value={this.state.password}
                                className="form-control"
                                placeholder={t(
                                  "LoginScreen.passwordPlaceHolder"
                                )}
                                type={passwordType ? "password" : "text"}
                                required
                              />
                              <span className="eye-icon pointer">
                                <i
                                  onClick={this.handlePasswordType}
                                  className={
                                    passwordType
                                      ? "fa fa-eye-slash fa-fw toggle-password"
                                      : "fa fa-eye fa-fw toggle-password"
                                  }
                                  toggle="#password-field"
                                ></i>
                              </span>
                              <small
                                id="emailHelp"
                                className="form-text text-danger"
                              >
                                <ErrorMessageFormik name="password" />
                              </small>
                            </div>
                            <Link to="/forgot_password">
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                {t("LoginScreen.forgotPassword")}
                              </small>
                            </Link>
                          </div>
                          <div className="form-group text-center mt-4">
                            {isLoading ? (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-success btn-block"
                              >
                                Login
                                <div className="spinner-border spinner-border-sm ml-1 text-white">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-success btn-block"
                              >
                                {t("LoginScreen.btnLogin")}
                              </button>
                            )}

                            <a
                              className="text-muted mt-3 d-block"
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleRegisterClick();
                              }}
                            >
                              {t("LoginScreen.register")}
                            </a>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <footer className="fixed-bottom footer-menu">
                <div className="container">
                  <div className="form-row f-menu">
                    <div className="col">
                      <a
                        href="https://urgentpublish.de/nutzungsbedingungen"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" font-14  "
                      >
                        {t("GeneralMessages.tearm")}
                      </a>
                      <br></br>
                      <a
                        className="font-14 "
                        href="https://urgentpublish.de/kontakt/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("GeneralMessages.privacy")}
                      </a>
                    </div>
                    <div className="col text-right">
                        <img
                          src="/images/login_logo.png"
                          alt="loginLogo"
                          style={{ width: "100px" }}
                        />
                    </div>
                  </div>
                </div>
              </footer>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.loginDetailsStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.loginDetailsDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(Login)));
