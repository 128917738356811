import React from "react";

export default function ChannelType(props) {
                 const { handleBrandSelection, item } = props;
                 const { channel_type_icon, name } = item;
                 return (
                   <React.Fragment>
                     <li
                       className="nav-item pointer"
                       onClick={() => handleBrandSelection(item)}
                     >
                       <a
                         className="nav-link"
                         href="javascript;:"
                         onClick={(e) => {e.preventDefault()}}
                       >
                         <img
                           src={`data:image/png;base64,${
                             channel_type_icon && channel_type_icon
                           }`}
                           alt="brand_icon"
                         />{" "}
                         {name && name}
                       </a>
                     </li>
                   </React.Fragment>
                 );
               }
