import React, { Component } from "react";
import { Link } from "react-router-dom";
import { checkUser } from "../../util/utils";
import HeaderGuest from "../Header/HeaderGuest";
import Login from "../BeforeLogin/Login";
import { Translation, withTranslation } from "react-i18next";


 class PageNotFound extends Component {
  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper">
              {checkUser() ? <Login /> : <HeaderGuest />}
              <div className="main-container">
                <div
                  className="section-page d-flex align-items-center"
                  style={{ minHeight: "calc(100vh - 105px)" }}
                >
                  <div className="container">
                    <div className="page-not-found-content text-center">
                      <p>{t("GeneralMessages.errorMessageLable5")}</p>
                      <h1>
                        4<i className="far fa-frown"></i>4
                      </h1>
                      <p>{t("GeneralMessages.errorMessageLable6")}</p>
                      <div className="form-action mt-4">
                        <Link
                          to="/"
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          {t("GeneralMessages.errorMessageLable7")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(PageNotFound);  
