import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

 class ContactPersonRemarks extends Component {
     render() {
        const { comment } =
          this.props.groupPersonDetail && this.props.groupPersonDetail;
        return (
          <React.Fragment>
            <div className="my-2">{comment && comment}</div>
          </React.Fragment>
        );
    }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withRouter(ContactPersonRemarks));