import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateTimeType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datePickerIsOpen: false,
      startDate: new Date(),
    };
    this.openDatePicker = this.openDatePicker.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  openDatePicker() {
    this.setState({
      ...this.state,
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  handleChange(date) {
    this.setState(
      {
        ...this.state,
        startDate: date,
        datePickerIsOpen: false,
      },
      () => {
        this.props.handleDefaultDateChange(date);
      }
    );
  }

  render() {
    const { use_date, use_time, hide_on_zero } = this.props;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <h4>{t("AdminSetteliteSideMenu.option")}</h4>

              <div className="form-group">
                <label className="font-14">
                  {t("AdminSnippetSlectiontype.Option")}
                </label>
                <div className="input-group">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    onClickOutside={this.openDatePicker}
                    open={this.state.datePickerIsOpen}
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    className="form-control combine-datepicker"
                    disabled
                  />
                  <div
                    className="input-group-append pointer"
                    onClick={this.openDatePicker}
                  >
                    <span className="input-group-text bg-white">
                      <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-22"></i>
                    </span>
                  </div>
                </div>

                <ul className="list-unstyled mb-0 mt-3">
                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetDateTimetype.use_date")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleDateCheckBox(e, "use_date")
                          }
                          checked={use_date}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetDateTimetype.use_time")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleDateCheckBox(e, "use_time")
                          }
                          checked={use_time}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetDateTimetype.hide_on_zero")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleDateCheckBox(e, "hide_on_zero")
                          }
                          checked={hide_on_zero}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(DateTimeType));
