/* eslint eqeqeq: 0 */
import moment from "moment-timezone";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ReortDetailQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { connect } from "react-redux";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import NoRecordFoundTemplate2 from "../CommanComponents/NoRecordFoundTemplate2";
import TopHeader from "../CommanComponents/TopHeader";
import ChannelSetteliteSideMenu from "./ChannelSetteliteSideMenu";
import { Translation, withTranslation } from "react-i18next";

const snippet_type_person_progress = ["text2speech", "sms", "email"];

class ChannelList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerMenuStatus: false,
      isLoading: false,
      reportDetail: {},
      reportList: [],
    };
  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  openSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleScroll = (id) => {
    const index = this.props.channelList.report_list.findIndex(
      report => report.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`)
        var element1 = document.getElementById('ulID')
        var elementPosition = element.getBoundingClientRect()
        element1.scrollLeft = (elementPosition.left)
      }, 500);
    }
  }



  handleApiResponse = (data) => {
    this.setState({
      reportDetail: data,
    });
  };

  componentDidMount() {
    const report_id = this.props.match.params.report_id;
    this.getChannelList(report_id);
  }

  setChannelID = (report_id) => {
    let event_id = this.props.match.params.event_id;
    let myPath = `/event/${event_id}/report/${report_id}`;
    this.props.history.push(myPath);
    this.getChannelList(report_id);
  };


  handleActiveIndex = (report_id, id) => {
    if (report_id == id) {
      this.handleScroll(report_id)
      return ('active')
    } else {
      return ('')
    }
  }

  getChannelList = (report_id) => {
    apiCall(
      METHOD.QUERY,
      ReortDetailQuery(report_id),
      (data) => {
        this.props.getChannelList(data);
        this.handleApiResponse(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleMessageStatus = (
    message_status,
    percentage_status,
    message_status_identifier
  ) => {
    if (
      message_status_identifier != "sending_failed" &&
      message_status_identifier != "sent_successfully"
    ) {
      let messageValue = message_status + "(" + percentage_status + " %)";
      return messageValue;
    } else {
      return message_status;
    }

  };


  loader = (<LoadingPage />);

  handleBackStack = () => {
    let mybackStackpath = '/event/' + this.props.match.params.event_id;
    let { from } = this.props.location.state || {
      from: { pathname: mybackStackpath },
    };
    this.props.history.push(from);
  };

  handelChannelClick = (message_id, event_id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: `${this.props.history.location.pathname}/message/${message_id}`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handlesendDate = (message_send_datetime, event_datetime) => {

    const event_date = moment.unix(event_datetime).tz(moment.tz.guess()).format("DD.MM.YYYY")
    const message_send_date = moment.unix(message_send_datetime).tz(moment.tz.guess()).format("DD.MM.YYYY")

    if (event_date == message_send_date) {
      return (< span className="mr-3 mb-1 mb-md-0">
        <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
        {moment
          .unix(message_send_datetime)
          .tz(moment.tz.guess())
          .format("HH:mm")}
      </span>)
    } else {

      return (< span className="mr-3 mb-1 mb-md-0">
        <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
        {moment
          .unix(message_send_datetime)
          .tz(moment.tz.guess())
          .format("DD.MM.YYYY")}
      </span>)
    }
  }

  render() {
    const {
      event_name,
      report_datetime,
      report_creator_name,
      report_type_white_icon,
      channel_list,
      event_id,
      report_id,
      report_list,
      title,
    } = this.props.channelList;
    const { isLoading } = this.state;
return (
  <Translation ns="translation">
  {(t, { i18n }) => {
    return (
      <div className="wrapper position-relative">
        <div className="header-bg header-red sab-header1 sticky-top d-flex flex-column">
          <div className="container d-flex flex-column flex-fill">
            <div className="text-center py-2 d-flex  align-items-center justify-content-between">
              <TopHeader />

              <p
                className="mb-0 ml-auto text-light pointer"
                onClick={() => this.handleBackStack(event_id)}
              >
                {event_name && event_name}
              </p>
              <LogoutMenu />
            </div>
            <ul className="header-menu-tab-link mt-auto" id="ulID">
              {report_list &&
                report_list.map((data, index) => {
                  const { id,  title } = data;
                  return (
                    <li
                      id={`li_${id}`}
                      key={index}
                      className={this.handleActiveIndex(report_id, id)}>
                      <a
                        href="javascript;:"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setChannelID(id);
                        }}
                      >
                        {title && title}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        <div className="middle-section sab-middle-section1 left-line">
          {isLoading && this.loader}
          <div className="container my-4 z-index-99">
            <div className="icon-box-style1 red-bg d-flex rounded">
              <div className="icon-box red-bg d-flex rounded">
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img
                    src={`data:image/png;base64,${
                      report_type_white_icon && report_type_white_icon
                      }`}
                    style={{ width: "32px", height: "32px" }}
                    alt="ioc"
                  />
                </div>
              </div>
              <div className="ml-3 m-2 d-inline-block">
                <h5 className="m-0 font-18 text-light">{title && title}</h5>

                <p className="font-12 mb-2 text-light">
                  {report_creator_name && report_creator_name}
                </p>
                <div className="text-muted font-12 d-flex flex-wrap">
                  <span className="mr-3 text-light">
                    <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-16" style={{ verticalAlign: '-1px' }}></i>{" "}
                    {report_datetime &&
                      moment
                        .unix(report_datetime)
                        .tz(moment.tz.guess())
                        .format("DD.MM.YYYY")}
                  </span>
                  <span className="mr-3  text-light">
                    <i className="zmdi zmdi-time zmdi-hc-fw font-16 mr-2p" style={{ verticalAlign: '-1px' }}></i>

                    {report_datetime &&
                      moment
                        .unix(report_datetime)
                        .tz(moment.tz.guess())
                        .format("HH:mm")}
                  </span>
                </div>
              </div>
              <p className="ecke-bg"></p>
            </div>

            {!isLoading && channel_list && channel_list.length == 0 && (
              <NoRecordFoundTemplate2
                title="Report List"
                message="No recort found"
              />
            )}

            {channel_list &&
              channel_list.map((item, index) => {
                const {
                  message_id,
                  channel_type_name,
                  channel_type_identifier,
                  message_datetime,
                  message_creator_name,
                  message_send_datetime,
                  message_status,
                  percentage_status,
                  message_status_identifier,
                  channel_type_secondary_icon,
                  channel_type_color,
                } = item;
                
                const sendingStatusFromTranslation = `sendingStatus.${message_status_identifier}`

                return (
                  <a
                    href="javascript;:"
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handelChannelClick(message_id, event_id);
                    }}
                    className={
                      "py-4 d-block before-line titel-whatsaap"
                    }
                  >
                    <div
                      className="custom-border-channel"
                      style={{ borderColor: channel_type_color }}
                    ></div>
                    <div
                      className="rounded-35 channel-icon float-left ml-5p"
                      style={{ background: channel_type_color }}
                    >
                      <img
                        src={`data:image/png;base64,${
                          channel_type_secondary_icon &&
                          channel_type_secondary_icon
                          }`}
                        alt="email"
                        style={{ width: "24px" }}
                      />
                    </div>
                    <div className="pl-3 pt-1 d-flex">
                      <div className="w-100">
                        <h6 className="m-0">
                          {channel_type_name && channel_type_name}
                        </h6>
                        <p className="text-muted font-12 mb-2">
                          {message_creator_name && message_creator_name}
                        </p>
                        <div className="text-muted font-12 d-flex flex-wrap">
                          <span className="mr-2 mb-1 mb-md-0">
                            <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-16 mr-2p"></i>
                            {message_datetime &&
                              moment
                                .unix(message_datetime)
                                .tz(moment.tz.guess())
                                .format("DD.MM.YYYY")}
                          </span>
                          <span className="mr-3 mb-1 mb-md-0">
                            <i className="zmdi zmdi-time zmdi-hc-fw font-16 mr-2p"></i>

                            {message_datetime &&
                              moment
                                .unix(message_datetime)
                                .tz(moment.tz.guess())
                                .format("HH:mm")}
                          </span>

                          {message_status && snippet_type_person_progress.includes(channel_type_identifier) &&
                          (
                            <React.Fragment>
                              <span className="mr-3 mb-1 mb-md-0">
                                <i className="zmdi zmdi-mail-send zmdi-mail-fw font-16 mr-2"></i>
                                {message_status_identifier != "sending_failed" && message_status_identifier != "sent_successfully" && t(sendingStatusFromTranslation)  + " (" +percentage_status + "%)"}
                                {message_status_identifier == "sending_failed" || message_status_identifier == "sent_successfully"  && t(sendingStatusFromTranslation)}
                              </span>
                            </React.Fragment>
                          )}


                              {message_send_datetime && message_status_identifier != '' &&
                                  message_status_identifier == 'sent_successfully' &&
                                  this.handlesendDate(message_send_datetime, message_datetime)
                                }
                        </div>


                      </div>
                    </div>
                  </a>
                );
              })}
          </div>

          <ChannelSetteliteSideMenu
            closeSlider={this.closeSlider}
            headerMenuStatus={this.state.headerMenuStatus}
            changeRouteStatus={this.props.changeRouteStatus}
          />

          <FabIcon color="rounded-red" openSlider={this.openSlider} />
        </div>

        <Footer type="2" />
      </div>
    );
  }}
</Translation>);
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.reporttListStateToProps,
        stateToProps.channelListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.reportListDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(ChannelList)));
