/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EditPerson from "./EditPerson";
import GroupList from "./GroupList";
import PersonDetail from "./PersonDetail";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0
    };
  }
  changeRouteStatus = value => {
    this.setState(prevState => {
      return {
        routeStatus: value
      };
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <PersonDetail changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <EditPerson changeRouteStatus={this.changeRouteStatus} />
          )}

        {routeStatus == 2 && (
            <GroupList changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
