/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import AddNewGroup from "./AddNewGroup";
import GroupList from "./GroupList";
import RearrangeGroup from "./RearrangeGroup";
import SearchGroupList from "./SearchGroupList";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectType: 0,
      searchData: {},
    };
  }

  changeStatus = (type) => {
    this.setState({
      selectType: type,
    });
  };

  getSearchData = (data) => {
    this.setState({
      searchData: data,
    });
  };
  render() {
    const { selectType } = this.state;
    return (
      <div className="wrapper position-relative">
        {selectType == 0 && (
          <GroupList
            getSearchData={this.getSearchData}
            changeStatus={this.changeStatus}
          />
        )}
        {selectType == 1 && <AddNewGroup changeStatus={this.changeStatus} />}
        {selectType == 2 && (
          <SearchGroupList
            searchData={this.state.searchData}
            changeStatus={this.changeStatus}
          />
        )}
        {selectType == 3 && <RearrangeGroup changeStatus={this.changeStatus} />}
      </div>
    );
  }
}
