/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { toast } from "react-toastify";
import { SnippetDataQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID, stringToHTML } from "../../util/utils";

class FreeTextDialogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      nestedSnippetData: {},
      content: {},
      placeholder: "",
      hide_on_zero: false,
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  componentDidMount() {
    const { id } = this.props;
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      (data) => {
        let jsonContent = JSON.parse(data.content);

        this.setState({
          nestedSnippetData: data,
          content: jsonContent,
          placeholder: jsonContent.placeholder,
          hide_on_zero: jsonContent.hide_on_zero,
          raw_value: JSON.parse(JSON.parse(data.raw_value)),
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleSubmit = (values) => {
    const {
      id,
      setDynamicValue,
      setFinalDynamicValueWithInnerSnippet,
      setDynamicValueForMultiLine,
      setDynamicValueForMultiLineWithInnerSnippet,
      closeDialogue,
    } = this.props;
    const { input_Value } = values;
    const { content, hide_on_zero, raw_value } = this.state;
    let is_multiline = false;

    if (raw_value.blocks.length > 1) {
      is_multiline = true;
    }

    if (!input_Value && hide_on_zero) {
      setDynamicValue(id, "");
      closeDialogue();
    } else if (!input_Value) {
      toast.info(this.props.t("free_text_dialogue.input_value_validation"));
    } else {
      let val = content.content;
      val = val.replace("<value>", input_Value);
      let val1 = raw_value.blocks[0].text;
      let value_tag_position = val1.search("<value>");
      val1 = val1.replace("<value>", input_Value);
      let entityMap = raw_value.entityMap;

      if (is_multiline) {
        let blocks = raw_value.blocks;
        // find the value tag
        if (entityMap == 0) {
           for (let i in blocks) {
            let block = blocks[i];
            let text = block.text;
            const value_position = text.indexOf("<value>");

            if (value_position != -1) {
              text = text.replace("<value>", input_Value);
              blocks[i].text = text;
              let inlineStyleRanges = blocks[i].inlineStyleRanges;

              for (let j in inlineStyleRanges) {
                let style = inlineStyleRanges[j];
                if (style.offset >= value_position) {
                  const input_Value_length = input_Value.length;
                  let offset = style.offset;
                  offset = offset + input_Value_length - 7;
                  style.offset = offset;
                  inlineStyleRanges[j] = style;
                }
              }
              blocks[i].inlineStyleRanges = inlineStyleRanges;
            }
          }
          setDynamicValueForMultiLine(id, raw_value);
        } else {
           for (let i in blocks) {
            let block = blocks[i];
            let text = block.text;
            const value_position = text.indexOf("<value>");

            if (value_position != -1) {
              text = text.replace("<value>", input_Value);
              blocks[i].text = text;
              let inlineStyleRanges = blocks[i].inlineStyleRanges;

              // update style with new value
              for (let j in inlineStyleRanges) {
                let style = inlineStyleRanges[j];
                if (style.offset >= value_position) {
                  const input_Value_length = input_Value.length;
                  let offset = style.offset;
                  offset = offset + input_Value_length - 7;
                  style.offset = offset;
                  inlineStyleRanges[j] = style;
                }
              }
              blocks[i].inlineStyleRanges = inlineStyleRanges;

              let entityRange = blocks[i].entityRanges;
              for (let i in entityRange) {
                let entityElement = entityRange[i];
                let offset = entityElement.offset;
                if (offset > value_position) {
                  const input_Value_length = input_Value.length;
                  offset = offset + input_Value_length - 7;
                  entityElement.offset = offset;
                  entityRange[i] = entityElement;
                }
              }
            }
          }
          setDynamicValueForMultiLineWithInnerSnippet(id, raw_value);
        }
      } else {
        if (entityMap == 0) {
          let innerReference = stringToHTML(val, Date.now());
          var children = innerReference.children;
          let referenceInSpan = children[0];

          referenceInSpan.onclick = (e) => {
            e.preventDefault();
            if (e.stopPropagation) e.stopPropagation();
            this.props.handleReferenceClick(referenceInSpan);
          };
          referenceInSpan.setAttribute("class", "reference-backgroupred");
          referenceInSpan.innerHTML = referenceInSpan.getAttribute(
            "placeholder"
          );

          setFinalDynamicValueWithInnerSnippet(id, input_Value, raw_value);
        } else {
          setDynamicValue(id, val1, raw_value, value_tag_position, input_Value);
        }
      }

      closeDialogue();
    }
  };

  close_text_dialogue = () => {
    const { hide_on_zero } = this.state;
    const { id } = this.props;
    const { setDynamicValue, closeDialogue } = this.props;

    if (hide_on_zero) {
      setDynamicValue(id, "");
      closeDialogue();
    } else {
      closeDialogue();
    }
  };
  render() {
    const { nestedSnippetData, placeholder } = this.state;
    return (
      <Bounce bottom>
        <div
          className="navbar-collapse collapse text-left bottom-half2 show"
          id="Brand-toggler"
        >
          <Formik
            initialValues={{
              input_Value: "",
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
          >
            {({ errors, touched, handleSubmit, setErrors }) => (
              <React.Fragment>
                <div className="d-flex ">
                  <button
                    className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
                    onClick={handleSubmit}
                    type="button"
                    data-toggle="collapse"
                    data-target="#Brand-toggler"
                    aria-controls="Brand-toggler"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                  >
                    <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>
                  </button>

                  <button
                    className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
                    onClick={() => {
                      this.close_text_dialogue();
                    }}
                    type="button"
                    data-toggle="collapse"
                    data-target="#Brand-toggler"
                    aria-controls="Brand-toggler"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                  >
                    <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                  </button>
                </div>

                <div className="text-center">
                  <p className="mt-4">
                    {nestedSnippetData.name && nestedSnippetData.name}
                  </p>

                  <div className="max-200 m-auto">
                    <form className="login-form">
                      <div className="form-group p-4">
                        <Field
                          type="text"
                          name="input_Value"
                          className="form-control"
                          placeholder={placeholder && placeholder}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </Bounce>
    );
  }
}
export default withTranslation()(FreeTextDialogue);
