/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { toast } from "react-toastify";
import { SnippetDataQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID, stringToHTML } from "../../util/utils";

class SelectionDialogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      nestedSnippetData: {},
      content: {},
      allow_zero: false,
      hide_on_zero: false,
      is_multi: false,
      concat: false,
      options: [],
      custom: false,
      checkOption: [],
      placeholder: "",
      custom_option: "",
      concat_value: "",
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  componentDidMount() {
    const { id } = this.props;
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      (data) => {
        let jsonContent = JSON.parse(data.content);

        this.setState({
          nestedSnippetData: data,
          content: jsonContent,
          allow_zero: jsonContent.allow_zero,
          hide_on_zero: jsonContent.hide_on_zero,
          is_multi: jsonContent.is_multiselect,
          placeholder: jsonContent.placeholder,
          concat: jsonContent.concatinate,
          options: jsonContent.options,
          custom: jsonContent.allow_custom_option,
          concat_value: jsonContent.concatinate_value,
          raw_value: JSON.parse(JSON.parse(data.raw_value)),
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.checked) {
      this.setState((prev) => ({
        checkOption: [...prev.checkOption, value],
      }));
    } else {
      this.setState((prev) => ({
        checkOption: prev.checkOption.filter((d) => d != value),
      }));
    }
  };

  concatValue = (index) => {
    const { concat_value } = this.state;
    if (index == index - 1) {
      return concat_value;
    } else {
      return ",";
    }
  };
  handleSubmit = (values) => {
    const {
      setDynamicValue,
      setFinalDynamicValueWithInnerSnippet,
      closeDialogue,
      setDynamicValueForMultiLine,
      setDynamicValueForMultiLineWithInnerSnippet,
      id,
    } = this.props;

    const {
      content,
      checkOption,
      is_multi,
      concat,
      concat_value,
      raw_value,
    } = this.state;
    let val = content.content;
    let is_multiline = false;

    if (raw_value.blocks.length > 1) {
      is_multiline = true;
    }

    let input_value = "";
    if (is_multi) {
      if (checkOption.length == 0) {
        toast.info(this.props.t("snippetDialogue.selectOption"));
      } else {
        let selectedVal = "";
        for (let i = 0; i < checkOption.length; i++) {
          if (checkOption.length == 1) {
            selectedVal = checkOption[0];
          } else {
            if (concat) {
              selectedVal =
                checkOption.slice(0, -1).join(",") +
                " " +
                concat_value +
                " " +
                checkOption.slice(-1);
            } else {
              selectedVal = checkOption.join(",");
            }
          }
        }
        input_value = selectedVal;
        val = val.replace("<value>", selectedVal);
      }
    } else {
      input_value = values;
      val = val.replace("<value>", values);
    }

    let entityMap = raw_value.entityMap;

    if (is_multiline) {
      let blocks = raw_value.blocks;
      // find the value tag
      if (entityMap == 0) {
         for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if (value_position != -1) {
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset;
                offset = offset + input_Value_length - 7;
                style.offset = offset;
                inlineStyleRanges[j] = style;
              }
            }
            blocks[i].inlineStyleRanges = inlineStyleRanges;
          }
        }
        setDynamicValueForMultiLine(id, raw_value);
      } else {
        for (let i in blocks) {
          let block = blocks[i];
          let text = block.text;
          const value_position = text.indexOf("<value>");

          if (value_position != -1) {
            text = text.replace("<value>", input_value);
            blocks[i].text = text;
            let inlineStyleRanges = blocks[i].inlineStyleRanges;

            // update style with new value
            for (let j in inlineStyleRanges) {
              let style = inlineStyleRanges[j];
              if (style.offset >= value_position) {
                const input_Value_length = input_value.length;
                let offset = style.offset;
                offset = offset + input_Value_length - 7;
                style.offset = offset;
                inlineStyleRanges[j] = style;
              }
            }
            blocks[i].inlineStyleRanges = inlineStyleRanges;

            let entityRange = blocks[i].entityRanges;
            for (let i in entityRange) {
              let entityElement = entityRange[i];
              let offset = entityElement.offset;
              if (offset > value_position) {
                const input_Value_length = input_value.length;
                offset = offset + input_Value_length - 7;
                entityElement.offset = offset;
                entityRange[i] = entityElement;
              }
            }
          }
        }
        setDynamicValueForMultiLineWithInnerSnippet(id, raw_value);
      }
    } else {
      if (entityMap == 0) {
        let innerReference = stringToHTML(val, Date.now());
        var children = innerReference.children;
        let referenceInSpan = children[0];

        referenceInSpan.onclick = (e) => {
          e.preventDefault();
          if (e.stopPropagation) e.stopPropagation();
          this.props.handleReferenceClick(referenceInSpan);
        };
        referenceInSpan.setAttribute("class", "reference-backgroupred");
        referenceInSpan.innerHTML = referenceInSpan.getAttribute("placeholder");
        setFinalDynamicValueWithInnerSnippet(id, input_value, raw_value);
      } else {
        let val1 = raw_value.blocks[0].text;
        let value_tag_position = val1.search("<value>");
        val1 = val1.replace("<value>", input_value);
        setDynamicValue(id, val1, raw_value, value_tag_position, input_value);
      }
    }

    closeDialogue();
  };

  close_text_dialogue = () => {
    const {  closeDialogue } = this.props;
    closeDialogue();
    // if (allow_zero && hide_on_zero) {
    //   setDynamicValue(id, "");
    //   closeDialogue();
    // } else if (allow_zero && !hide_on_zero) {
    //   setDynamicValue(id, " NAN ");
    //   closeDialogue();
    // } else {
    //   closeDialogue();
    // }
  };

  customOptionTextChange = (e) => {
    this.setState({
      custom_option: e.target.value,
    });
  };
  saveCustomOption = () => {
    const { custom_option, options } = this.state;
    if (custom_option) {
      let customoption_list = options;
      customoption_list.push(custom_option);
      this.setState({
        options: customoption_list,
        custom_option: "",
      });
    }
  };

  render() {
    const {
      nestedSnippetData,
      options,
      is_multi,
      placeholder,
      custom_option,
      custom,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom>
              <div
                className="navbar-collapse collapse text-left bottom-half show"
                id="Brand-toggler"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {nestedSnippetData.name && nestedSnippetData.name}
                    </h5>
                    <div className="d-flex ">
                      {is_multi && (
                        <button
                          className="navbar-toggler icon-close w-100 text-right"
                          onClick={this.handleSubmit}
                          type="button"
                          data-toggle="collapse"
                          data-target="#Brand-toggler"
                          aria-controls="Brand-toggler"
                          aria-expanded="true"
                          aria-label="Toggle navigation"
                        >
                          <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>
                        </button>
                      )}

                      <button
                        type="button"
                        className="close"
                        onClick={() => this.close_text_dialogue()}
                        aria-label="Close"
                      >
                        <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="text-left">
                      {is_multi ? (
                        <ul className="navbar-nav mr-auto">
                          {options &&
                            options.map((item, index) => {
                              return (
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onClick={this.handleChange}
                                    id={index}
                                    value={item}
                                  />
                                  <label
                                    id={index}
                                    className="custom-control-label"
                                    htmlFor={index}
                                  >
                                    {item}
                                  </label>
                                </div>
                              );
                            })}
                          {custom && (
                            <li className="nav-item pointer ">
                              <div className="d-flex text-left">
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  placeholder={placeholder}
                                  onChange={(e) =>
                                    this.customOptionTextChange(e)
                                  }
                                  value={custom_option}
                                />
                                <button
                                  className="navbar-toggler icon-close w-100 text-right"
                                  onClick={() => this.saveCustomOption()}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#Brand-toggler"
                                  aria-controls="Brand-toggler"
                                  aria-expanded="true"
                                  aria-label="Toggle navigation"
                                >
                                  <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>
                                </button>
                              </div>
                            </li>
                          )}
                        </ul>
                      ) : (
                        <ul className="navbar-nav mr-auto">
                          {options &&
                            options.map((item, index) => {
                              return (
                                <li
                                  className="nav-item pointer "
                                  key={index}
                                  onClick={() => {
                                    this.handleSubmit(item);
                                  }}
                                >
                                  <div className="d-flex ">
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        marginRight: "3px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {"- "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {item}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}

                          {custom && (
                            <li className="nav-item pointer ">
                              <div className="d-flex text-align-center">
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  placeholder={placeholder}
                                  onChange={(e) =>
                                    this.customOptionTextChange(e)
                                  }
                                  value={custom_option}
                                />
                                <button
                                  className="navbar-toggler icon-close w-100 text-right"
                                  onClick={() => this.saveCustomOption()}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#Brand-toggler"
                                  aria-controls="Brand-toggler"
                                  aria-expanded="true"
                                  aria-label="Toggle navigation"
                                >
                                  <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>
                                </button>
                              </div>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(SelectionDialogue);
