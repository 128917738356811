/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { EventListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import HomeSetteliteSideMenu from "../Event/HomeSetteliteSideMenu";
import ActiveEventList from "./ActiveEventList";
import ArchiveEventList from "./ArchiveEventList";

class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventType: 1,
      search: "",
      searchEvent: [],
      searchStart: 0,
      searchEnd: 10,
      searchHasMore: true,
      readEventPermission:false
    };
    this.searchRef = React.createRef();
  }


  componentDidMount() {
    const readEventPermission = checkUserPermission("read_event");
    this.setState({
      readEventPermission
    })
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  handleSearchResponse = (data) => {
    const { searchStart, searchEnd } = this.state;

    if (data.is_last === 1) {
      this.setState(() => {
        return {
          ...this.state,
          searchHasMore: false,
        };
      });
    }

    this.setState(
      (prevState) => {
        return {
          ...this.state,
          searchStart: searchStart + searchEnd,
          searchEvent: [...prevState.searchEvent, ...data.event_list],
          isLoading: false,
        };
      },
      () => {
        if (this.state.searchEvent.length === 0) {
          toast.info(
            this.props.t("GeneralMessages.searchErrorMessage") +
              "'" +
              this.state.search +
              "'"
          );
        }
      }
    );
  };
  loader = (<LoadingPage />);

  handleEventType = (type) => {
    this.setState(() => {
      return {
        ...this.state,
        eventType: type,
      };
    });
  };

  changeEventSearch = (e) => {
    const searchValue = e.target.value;

    this.setState({
      search: searchValue,
      searchEvent: [],
      searchHasMore: true,
      searchStart: 0,
      searchEnd: 10,
    });
  };

  clearSearch = () => {
    this.searchRef.current.value = "";

    this.setState(() => {
      return {
        ...this.state,
        search: "",
        searchEvent: [],
        searchHasMore: true,
        searchStart: 0,
        searchEnd: 10,
      };
    });
  };

  searchApi = () => {
    const {
      search,
      isLoading,
      searchStart,
      searchEnd,
      eventType,
      searchHasMore,
    } = this.state;
    if (!isLoading && searchHasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        EventListQuery(getUserID(), searchStart, searchEnd, eventType, search),
        (data) => this.handleSearchResponse(data),
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: false,
      };
    });
  };

  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/home" } };
    this.props.history.push(from);
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };
  render() {
    const { eventType, search,readEventPermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-red sticky-top">
                <div className="container">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={() => {
                        this.handleBackStack();
                      }}
                    >
                      {eventType == 1
                        ? t("ActiveArchiveEvent.ActiveEvent")
                        : t("ActiveArchiveEvent.ArchiveEvent")}
                    </p>
                    <LogoutMenu />
                  </div>
                  <div className="clearfix mt-4">
                    <div className="input-group mt-3">
                      <input
                        type="E-Mail"
                        placeholder={t("ActiveArchiveEvent.search")}
                        className="form-control"
                        ref={this.searchRef}
                        onChange={this.changeEventSearch}
                        onKeyPress={(e) =>
                          e.keyCode == 13 || (e.which == 13 && this.searchApi())
                        }
                      />
                      <div className="input-group-append ">
                        <span className="input-group-text bg-white input-group-append ">
                          <i
                            className="zmdi zmdi-search zmdi-hc-fw font-22 pointer"
                            onClick={this.searchApi}
                          ></i>
                          {search.length !== 0 && (
                            <i
                              className="zmdi zmdi-close zmdi-hc-fw font-22 pointer"
                              onClick={this.clearSearch}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="mt-35p ">
                      <ul className="header-menu-tab-link ">
                        <li
                          className={
                            eventType === 1
                              ? "active-event pointer"
                              : "pointer normal-event"
                          }
                          onClick={() => this.handleEventType(1)}
                        >
                          {t("ActiveArchiveEvent.ActiveEvent")}
                        </li>
                        <li
                          className={
                            eventType === 2
                              ? "active-event pointer"
                              : "pointer normal-event"
                          }
                          onClick={() => this.handleEventType(2)}
                        >
                          {t("ActiveArchiveEvent.ArchiveEvent")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="middle-section">
                {eventType == 1 ? (
                readEventPermission && (<ActiveEventList
                  searchData={this.state}
                  searchApi={this.searchApi}
                />)
                ) : (
                  readEventPermission && (<ArchiveEventList
                    searchData={this.state}
                    searchApi={this.searchApi}
                  />)
                )}

                <HomeSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  status={this.state.status}
                  changeStatus={this.props.changeStatus}
                />
              </div>
              <FabIcon color="rounded-red" openSlider={this.openSlider} />
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(EventList));
