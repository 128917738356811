import {
  EVENT_LIST,
  ADD_NEW_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  EVENT_START_VALUE,
  EVENT_HASMORE_VALUE,
  TOTAL_EVENT
} from "../constants/action-types";

export const getEventList = data => ({
  type: EVENT_LIST,
  payload: data
});

export const addNewEvent = data => ({
  type: ADD_NEW_EVENT,
  payload: data
});
export const editEvent = data => ({
  type: EDIT_EVENT,
  payload: data
});
export const deleteEvent = data => ({
  type: DELETE_EVENT,
  payload: data
});

export const getHasMoreValue = data => ({
  type: EVENT_HASMORE_VALUE,
  payload: data
});

export const getStartValue = data => ({
  type: EVENT_START_VALUE,
  payload: data
});
export const setEventCount = data => ({
  type: TOTAL_EVENT,
  payload: data
});
