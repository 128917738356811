/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router";
import ChannelType from "./ChannelType";

 class ChannelTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const {
      status,
      closeBrandDialogue,
      handleBrandSelection,
      BrandList,
    } = this.props;
    return (
      <React.Fragment>
        <Bounce bottom when={status && status}>
          <div
            className={
              status && status
                ? "navbar-collapse collapse text-left bottom-half-3 show"
                : "navbar-collapse collapse text-left bottom-half-3"
            }
            id="Brand-toggler"
          >
            <button
              className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
              onClick={closeBrandDialogue}
              type="button"
              data-toggle="collapse"
              data-target="#Brand-toggler"
              aria-controls="Brand-toggler"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
            </button>
            <ul className="navbar-nav mr-auto">
              {BrandList &&
                BrandList.map((item, index) => {
                  return (
                    <ChannelType
                      handleBrandSelection={handleBrandSelection}
                      key={index}
                      item={item}
                    />
                  );
                })}
            </ul>
          </div>
        </Bounce>
      </React.Fragment>
    );
  }
}
export default withRouter(withTranslation()(ChannelTypeList));
