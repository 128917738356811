import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { Translation, withTranslation } from "react-i18next";

 class ContactPersonAddress extends Component {
    render() {
           const { website, fax, business_adress, private_adress } =
             this.props.groupPersonDetail && this.props.groupPersonDetail;
      return (
        <Translation ns="translation">
          {(t, { i18n }) => {
            return (
              <React.Fragment>
                <div className="clearfix">
                  <h6>{t("contactPersonAddress.businessLable")}</h6>
                  <hr />
                </div>
                <div className="my-2">
                  <i className="zmdi zmdi-pin zmdi-hc-fw text-muted"></i>
                  <span className="font-14" style={{ whiteSpace:'pre'}}>
                    {business_adress && business_adress}
                  </span>
                </div>
                <div className="my-2">
                  <i className="zmdi zmdi-dribbble zmdi-hc-fw text-muted"></i>
                  <a href={website && website} target="_blank"className="font-14">
                    {website && website}
                  </a>
                </div>
                <div className="my-2">
                  <i className="zmdi zmdi-local-printshop zmdi-hc-fw text-muted"></i>
                  <a href={`tel: ${fax && fax} `} className="font-14 m-0">{fax && fax}</a>

                </div>
                <div className="clearfix mt-5">
                  <h6>{t("contactPersonAddress.privateLable")}</h6>
                  <hr />
                </div>
                <div className="my-2">
                  <i className="zmdi zmdi-pin zmdi-hc-fw text-muted"></i>
                  <span className="font-14">
                    {private_adress && private_adress}
                  </span>
                </div>
              </React.Fragment>
            );
          }}
        </Translation>
      );
    }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withRouter(withTranslation()(ContactPersonAddress)))