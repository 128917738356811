import {
  CHANNEL_LIST,
  UPDATE_CHANNEL_LIST,
  UPDATE_CHANNEL_DETAIL
} from "../constants/action-types";

const initialState = {
  channel: {},
};
export default function channelListReducer(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_LIST:
      return { ...state, ...action.payload };
    case UPDATE_CHANNEL_LIST:
      return {
        ...state,
        channel_list: [...state.channel_list, ...action.payload]
      };
    case UPDATE_CHANNEL_DETAIL:
     return {
       ...state,
       ...{
         title: action.payload.title,
         content: action.payload.content,
         report_type_id: action.payload.report_type_id,
         report_type_name: action.payload.report_type_name,
         report_type_icon: action.payload.report_type_icon
       }
     };
    default:
      return state;
  }
}
