import { CHANNEL_DETAIL,UPDATE_CHANNEL_SENDING_STATUS,UPDATE_MESSAEG_LINK } from "../constants/action-types";

const initialState = {
  channel: {}
};
export default function channelDetailReducer(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_DETAIL:
      return { ...action.payload };
      case UPDATE_CHANNEL_SENDING_STATUS:
        return { ...state,message_status_identifier:action.payload.message_status_identifier,message_status:action.payload.message_status };
      case UPDATE_MESSAEG_LINK:
        return { ...state,message_link:action.payload.message_link };
    default:
      return state;
  }
}
