/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import moment from "moment-timezone";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  channelDetail,
  checkCost,
  checksmscost
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import ChannelDetailSetteliteSideMenu from "./ChannelDetailSetteliteSideMenu";
import RecipientsList from "./RecipientsList";

const snippet_type_distributor = ["text2speech", "sms", "email"];
const snippet_type_cost = ["text2speech", "sms"];
const snippet_type_count = ["sms", "twitter"];
const snippet_type_person_progress = ["text2speech", "sms", "email"];

class ChannelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerMenuStatus: false,
      data: {},
      isLoading: false,
      tabSelection: 0,
      sideLineChannelColor: "",
      backgroundChannelColor: "",
      snippetCount: 0,
      cost: 0,
    };
  }

  openSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const message_id = this.props.match.params.message_id;
    const event_id = this.props.match.params.event_id;

    const readMessagePermission = checkUserPermission("read_message");
    if(readMessagePermission){
      this.channelDetailApi(message_id, event_id);
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  }

  changeMessage = (message_id) => {
    this.setState({
      tabSelection: 0,
    });
    const event_id = this.props.match.params.event_id;
    const report_id = this.props.match.params.report_id;
    const myPath = `/event/${event_id}/report/${report_id}/message/${message_id}`;
    this.props.history.push(myPath);


    const readMessagePermission = checkUserPermission("read_message");
    if(readMessagePermission){
      this.channelDetailApi(message_id, event_id);
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }

  };
  channelDetailApi = (message_id, event_id) => {
    apiCall(
      METHOD.QUERY,
      channelDetail(getUserID(), message_id, event_id),
      (data) => this.handleChannelDetailResponse(data),
      (isLoading) => this.setState({ isLoading }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleChannelDetailResponse = (data) => {
    //  this.handleChannelTypeColor(data.channel_type_identifier);
    this.props.getChannelDetail(data);

    const {
      content,
      channel_id,
      channel_type_identifier,
    } = this.props.channelDetail;

    let content_result = JSON.parse(content);
    let result = content_result.result;
    let textContent = result
      .replace(/<\s*br[^>]?>/, "\n")
      .replace(/(<([^>]+)>)/g, "");
    textContent = textContent.trim();
    textContent = textContent.replace("&nbsp;", "");

    if (snippet_type_cost.includes(channel_type_identifier)) {
      this.checkTextCost(textContent, channel_id, channel_type_identifier);
    }
    if (snippet_type_count.includes(channel_type_identifier)) {
      let length2 = textContent.length;
      this.setState({ snippetCount: length2 });
    }
  };

  handleTabSelection = (tabSelection) => {
    this.setState({
      tabSelection,
    });
  };

  checkTextCost = (markdown, channel_type_id, channel_type_identifier) => {
    if (channel_type_identifier == "sms") {
      apiCall(
        METHOD.QUERY,
        checksmscost(
          getUserID(),
          channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          this.setState({
            cost: 0,
          });
        }
      );
    } else {
      apiCall(
        METHOD.QUERY,
        checkCost(
          getUserID(),
          channel_type_id,
          JSON.stringify(markdown),
          channel_type_identifier
        ),
        (data) => {
          this.setState({
            cost: data.price,
          });
        },
        (isLoading) => {},
        (data) => {
          this.setState({
            cost: 0,
          });
        }
      );
    }
  };

  handleChannelTypeColor = (channel_type_identifier) => {
    if (channel_type_identifier == "email") {
      this.setState({
        sideLineChannelColor: "box-left-line-email",
        backgroundChannelColor: "email-bg",
      });
    } else if (channel_type_identifier == "facebook") {
      this.setState({
        sideLineChannelColor: "box-left-line-facebook",
        backgroundChannelColor: "facebook-bg",
      });
    } else if (channel_type_identifier == "twitter") {
      this.setState({
        sideLineChannelColor: "box-left-line-twitter",
        backgroundChannelColor: "twitter-bg",
      });
    } else if (channel_type_identifier == "sms") {
      this.setState({
        sideLineChannelColor: "box-left-line-sms",
        backgroundChannelColor: "sms-bg",
      });
    } else if (channel_type_identifier == "whatsapp") {
      this.setState({
        sideLineChannelColor: "box-left-line-whatsapp",
        backgroundChannelColor: "whatsapp-bg",
      });
    } else if (channel_type_identifier == "web") {
      this.setState({
        sideLineChannelColor: "box-left-line-internet",
        backgroundChannelColor: "internet-bg",
      });
    } else if (channel_type_identifier == "cms_feed") {
      this.setState({
        sideLineChannelColor: "box-left-line-newsLatter",
        backgroundChannelColor: "newsLatter-bg",
      });
    } else {
      this.setState({
        sideLineChannelColor: "box-left-line-texttoSpeech",
        backgroundChannelColor: "texttoSpeech-bg",
      });
    }
  };

  handleContent = (content) => {
    if (content) {
      let content_result = JSON.parse(content);
      let result = content_result.result;
      return result;
    }
  };

  handlebadge = () => {
    const { snippetCount } = this.state;

    if (snippetCount > 280) {
      return "badge badge-danger mr-2 mb-2 text-white badge-right";
    } else {
      return "badge badge-success mr-2 mb-2 text-white badge-right";
    }
  };

  handleBackStack = () => {
    let mybackStackpath =
      "/event/" +
      this.props.match.params.event_id +
      "/report/" +
      this.props.match.params.report_id;

    let { from } = this.props.location.state || {
      from: { pathname: mybackStackpath },
    };
    this.props.history.push(from);
  };

  handleActiveIndex = (channel_id, id) => {
    if (channel_id == id) {
      this.handleScroll(channel_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.channelDetail.channel_list.findIndex(
      (channel) => channel.channel_id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };
  handlesendDate = (message_send_datetime, event_datetime) => {
    const event_date = moment
      .unix(event_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");
    const message_send_date = moment
      .unix(message_send_datetime)
      .tz(moment.tz.guess())
      .format("DD.MM.YYYY");

    if (event_date == message_send_date) {
      return (
        <span className="mr-3 mb-1 mb-md-0">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("HH:mm")}
        </span>
      );
    } else {
      return (
        <span className="mr-3">
          <i className="zmdi zmdi-alarm-check zmdi-mail-fw font-16 mr-2"></i>
          {moment
            .unix(message_send_datetime)
            .tz(moment.tz.guess())
            .format("DD.MM.YYYY")}
        </span>
      );
    }
  };

  loader = (<LoadingPage />);
  render() {
    const {
      isLoading,
      tabSelection,
      snippetCount,
      cost,
    } = this.state;
    const {
      title,
      content,
      event_name,
      event_creator_name,
      channel_type_icon,
      channel_list,
      channel_id,
      channel_type_identifier,
      message_datetime,
      message_send_datetime,
      message_status,
      message_status_identifier,
      percentage_status,
      color,
      message_link
    } = this.props.channelDetail;
    console.log("message_status_identifier",message_status_identifier);
    const border_left = `solid 2px ${color}`;
    const sendingStatusFromTranslation = `sendingStatus.${message_status_identifier}`
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-red sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={this.handleBackStack}
                    >
                      {event_name && event_name}
                    </p>
                    <LogoutMenu />
                  </div>

                  <ul className="header-menu-tab-link mt-auto" id="ulID">
                    {channel_list &&
                      channel_list.map((item, index) => {
                        const {
                          channel_type_name,
                          message_id,
                        } = item;
                        return (
                          <li
                            id={`li_${item.channel_id}`}
                            key={index}
                            className={this.handleActiveIndex(
                              channel_id,
                              item.channel_id
                            )}
                          >
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeMessage(message_id);
                              }}
                            >
                              {channel_type_name && channel_type_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && this.loader}
                <div className="container my-4 z-index-99">
                  <div
                    className="icon-box-style1 mb-4 "
                    style={{ borderLeft: border_left }}
                  >
                    <div className="icon-box white-bg d-flex">
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div
                          className="rounded-50 "
                          style={{ background: color }}
                        >
                          <img
                            src={`data:image/png;base64,${
                              channel_type_icon && channel_type_icon
                            }`}
                            alt="ico"
                            style={{ width: "32px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 mt-2 d-inline-block">
                      <h5 className="m-0 font-18">
                        {event_name && event_name}
                      </h5>
                      <p className="text-muted font-12 mb-2">
                        {event_creator_name && event_creator_name}
                      </p>
                      <div className="text-muted font-12 d-flex flex-wrap">
                        <span className="mr-3 mb-1 mb-md-0">
                          <i
                            className="zmdi zmdi-calendar-note zmdi-hc-fw font-16 mr-2p"
                            style={{ verticalAlign: "-1px" }}
                          ></i>
                          {message_datetime &&
                            moment
                              .unix(message_datetime)
                              .tz(moment.tz.guess())
                              .format("DD.MM.YYYY")}
                        </span>
                        <span className="mr-3 mb-1 mb-md-0">
                          <i
                            className="zmdi zmdi-time zmdi-hc-fw font-16 mr-2p"
                            style={{ verticalAlign: "-1px" }}
                          ></i>
                          {message_datetime &&
                            moment
                              .unix(message_datetime)
                              .tz(moment.tz.guess())
                              .format("HH:mm")}
                        </span>

                        {message_status && snippet_type_person_progress.includes(channel_type_identifier) &&
                          (
                            <React.Fragment>
                              <span className="mr-3 mb-1 mb-md-0">
                                <i className="zmdi zmdi-mail-send zmdi-mail-fw font-16 mr-2"></i>
                                {message_status_identifier != "sending_failed" && message_status_identifier != "sent_successfully" && t(sendingStatusFromTranslation)  + " (" +percentage_status + "%)"}
                                {message_status_identifier == "sending_failed" || message_status_identifier == "sent_successfully"  && t(sendingStatusFromTranslation)}
                              </span>
                            </React.Fragment>
                          )}
                            
                            
                            {message_send_datetime && message_status_identifier != '' &&
                                  message_status_identifier == 'sent_successfully' &&
                          this.handlesendDate(
                            message_send_datetime,
                            message_datetime
                          )}
                      </div>
                    </div>
                  </div>
                  <h3 className="mr-2">
                    <b>{title && title}</b>
                  </h3>
                  <div className="clearfix">
                    <ul className="menu-tab-dark mb-10p">
                      <li className={tabSelection == 0 ? "active" : ""}>
                        <a
                          href="kanal-detailansicht-verteiler"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleTabSelection(0);
                          }}
                        >
                          {t("ChannelDetail.newsTextLable")}
                        </a>
                      </li>
                      {snippet_type_distributor.includes(
                        channel_type_identifier
                      ) && (
                        <li className={tabSelection == 1 ? "active" : ""}>
                          <a
                            href="kanal-detailansicht-verteiler"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleTabSelection(1);
                            }}
                          >
                            {t("ChannelDetail.distributorLable")}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>

                  {tabSelection == 1 ? (
                    <RecipientsList
                      channelDetailApi={(message_id, event_id) =>
                        this.channelDetailApi(message_id, event_id)
                      }
                      message_status_identifier={message_status_identifier}
                    />
                  ) : (
                    <React.Fragment>
                      <div
                        className="icon-box-style1 mb-4"
                        style={{ borderLeft: border_left }}
                      >
                        <div className="pl-3 py-3 d-flex w-100 ">
                          <div className="w-100">
                            <div
                              id="final_div"
                              dangerouslySetInnerHTML={{
                                __html: content && this.handleContent(content),
                              }}
                            />
                          </div>
                        </div>
                           
                
                      </div>
                      <div className="align-items-center justify-content-between  mr-4">
                        <p className="font-13 mb-0">
                          {event_creator_name && event_creator_name}
                        </p>
                      </div>
                      <div className="d-flex  mr-4">
                      {channel_type_identifier=='twitter' && message_link!='' && (
                         <a
                            className="font-13 mb-0"
                            style={{ float: "left" }}
                            href={message_link && message_link }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {message_link && message_link }
                          </a>
                        )}
                      </div>

                      <div className="py-2 d-flex align-items-center float-right0 ">
                     
                        {snippet_type_count.includes(
                          channel_type_identifier
                        ) && (
                          <p
                            className={this.handlebadge()}
                            style={{ float: "right" }}
                          >
                            {t("AddNewChannel.character")} {snippetCount}
                          </p>
                        )}

                        {snippet_type_cost.includes(
                          channel_type_identifier
                        ) && (
                          <p className="badge badge-success mr-2 mb-2">
                            {t("AddNewChannel.credits")} {cost && cost}
                          </p>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <ChannelDetailSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  message_status_identifier={message_status_identifier}
                  changeRouteStatus={this.props.changeRouteStatus}
                />
                <FabIcon color="rounded-red" openSlider={this.openSlider} />
              </div>
              <Footer type="2" />
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.channelDetailStateToProps,
        stateToProps.channelListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.channelDetailsDispatchToProps,
    actions.channelListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(ChannelDetails)));
