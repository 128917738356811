import React, { Component } from "react";
import Bounce from "react-reveal/Bounce";
import UserType from "./UserType";

export default class UserTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      status,
      closeBrandDialogue,
      BrandList,
    } = this.props;
    return (
      <React.Fragment>
        <Bounce bottom when={status && status}>
          <div
            className={
              status && status
                ? "navbar-collapse collapse text-left bottom-half show"
                : "navbar-collapse collapse text-left bottom-half"
            }
            id="Brand-toggler"
          >
            <button
              className="navbar-toggler icon-close mr-3 mt-3 w-100 text-right"
              onClick={closeBrandDialogue}
              type="button"
              data-toggle="collapse"
              data-target="#Brand-toggler"
              aria-controls="Brand-toggler"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <i className="zmdi zmdi-save zmdi-hc-fw font-30"></i>

              <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
            </button>

            <ul className="navbar-nav mr-auto">
              {BrandList &&
                BrandList.map((item, index) => {
                  return (
                    <UserType
                      key={index}
                      item={item}
                      checkboxChangeEvent={this.props.checkboxChangeEvent}
                      checklist={this.props.checklist}
                    />
                  );
                })}
            </ul>
          </div>
        </Bounce>
      </React.Fragment>
    );
  }
}
