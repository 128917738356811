/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddAdminSnippet from "./AddAdminSnippet";
import EditAdminSnippet from "./EditAdminSnippet";
import SnippetList from "./SnippetList";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      snipeetValue: {},
    };
  }

  changeStatus = (type) => {
    this.setState({
      routeStatus: type,
    });
  };

  changeStatusWithStatusValue = (type, snipeetValue) => {
    this.setState({
      routeStatus: type,
      snipeetValue,
    });
  };

  render() {
    const { routeStatus, snipeetValue } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <SnippetList
            changeStatusWithStatusValue={this.changeStatusWithStatusValue}
          />
        )}

        {routeStatus == 1 && (
          <AddAdminSnippet changeRouteStatus={this.changeStatus} />
        )}

        {routeStatus == 2 && (
          <EditAdminSnippet
            changeRouteStatus={this.changeStatus}
            item={snipeetValue}
          />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
