/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getinstallationvalue, getlocationdata } from "../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID, saveDatatoLocalStorage } from "../../../util/utils";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Updatesystemlogo = gql`
  mutation Updatesystemlogo($profile: UpdatesystemlogoInput!) {
    Updatesystemlogo(input: $profile) {
      success
      message
      system_logo   
    }
  }
`;

class SetUpSeystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      icon_file: undefined,
      base_64_icon: undefined,
    };
  }

  
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };



  componentDidMount() {

    if(!checkAdminPermission()){
      this.props.history.push("/")
    }

    apiCall(
      METHOD.QUERY,
      getinstallationvalue(),
      (data) => this.getInstallationResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  getInstallationResponse = (data) => {

    this.setState({
      base_64_icon: data.system_logo,
    });
  };
  getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile,
      });
    }
  };
  loader = (<LoadingPage />);
  render() {
    const {
      icon,
      isLoading,
      base_64_icon,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Updatesystemlogo}>
                {(Updatesystemlogo, { data, error, loading }) => {
                  if (data) {
                    if (data.Updatesystemlogo.success == 0) {
                      toast.error(this.props.t(data.Updatesystemlogo.message));
                    } else {
                      toast.success(this.props.t(data.Updatesystemlogo.message));
                      saveDatatoLocalStorage("system_logo", data.Updatesystemlogo.system_logo);
                      this.props.history.push("/administration")

                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (
                    <React.Fragment>
                      <div className="header-bg header-green sab-header1 sticky-top">
                        <div className="container">
                          <div className="py-2 d-flex align-items-center">
                            <span>
                              <i
                                className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                                onClick={() =>
                                  this.props.history.push("/administration")
                                }
                              ></i>
                            </span>

                            {loading ? (
                              <div className="btn btn-light font-13 ml-auto pointer">
                                {t("AdminAddUser.save")}
                                <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="btn btn-light font-13 ml-auto pointer"
                                onClick={() => {
                                  const {
                                    icon_file,
                                  } = this.state;
                                    let obj = {
                                      variables: {
                                        profile: {
                                          user_id: getUserID(),
                                          system_logo: icon_file ? icon_file : null,
                                        },
                                      },
                                    };
                                    Updatesystemlogo(obj);
                                }}
                              >
                                {t("AdminAddUser.save")}
                              </div>
                            )}
                          </div>
                          <div className="text-center mt-4 text-light">
                            <h2 className="m-0 text-light">
                              {t("administration.setupSystem")}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="middle-section sab-middle-section1">
                        {isLoading && this.loader}
                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("setUpSystem.icon")}
                                    </label>
                                    <div
                                      className="fileinput text-center fileinput-new d-flex align-items-center"
                                      data-provides="fileinput"
                                    >
                                      <div className="btn-file">
                                        <div
                                          className="thumbnail fileinput-new "
                                          style={{
                                            width: "200px",
                                            height: "200px",
                                            background: "#999",
                                          }}
                                        >
                                          {icon ? (
                                            <img src={icon} alt=""  style={{
                                              width: "200px",
                                              height: "200px",
                                            }}/>
                                          ) : base_64_icon ? (
                                            <img
                                              src={`data:image/png;base64,${
                                                base_64_icon && base_64_icon
                                              }`}
                                              style={{
                                                width: "200px",
                                                height: "200px",
                                              }}
                                              alt="icon"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.png"
                                              alt="icon"
                                              style={{
                                                width: "200px",
                                                height: "200px",
                                              }}
                                            />
                                          )}
                                        </div>
                                        <input
                                          type={"file"}
                                          file-model="myFile"
                                          name="image"
                                          accept=""
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            this.getIconUrl(
                                              event.currentTarget.files[0],
                                              file
                                            );
                                          }}
                                        />

                                        <div
                                          className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "100%",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Footer type="2" />
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SetUpSeystem));
