import { SIDE_MENU } from "../constants/action-types";

const initialState = {};
export default function sideMenuReduce(state = initialState, action) {
  switch (action.type) {
    case SIDE_MENU:
      return { ...action.payload };
    default:
      return state;
  }
}
