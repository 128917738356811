/* eslint eqeqeq: 0 */
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateReportDataQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { getUserID } from "../../util/utils";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";

class EditNewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportImage: "EB",
      report: "Einberufung",
      reportID: 0,
      channelName: "",
      // Display snippet
      content: ``,
      oldContent: ``,
      editorState: editorStateFromRaw(null),
      snippetType: "",
      snippetId: 0,
      snippetKbdId: 0,
      dialogueStatus: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    var megadraft = document.querySelector("#megadraft-editor");
    megadraft.onclick = function () {
      this.focus();
    };

    const {
      title,
      content,
      report_type_name,
      report_type_id,
      report_type_icon,
    } = this.props.channelList;

    let content_result = JSON.parse(content);
    let new_content = content_result.result;
    let old_content = content_result.content;

    if (!new_content.includes("<br")) {
      new_content = new_content.split("\n").join("<br/>");
    }

    let brandImageDefault, brandNameDefault, brandID;
    brandNameDefault = report_type_name;
    brandImageDefault = report_type_icon;
    brandID = report_type_id;

    this.editordata = new_content;
    this.setState({
      reportImage: brandImageDefault,
      reportID: brandID,
      report: brandNameDefault,
      channelName: title,
      editorState: EditorState.createWithContent(stateFromHTML(new_content)),
      oldContent: `${old_content}`,
      content: `${new_content}`,
    });
  }

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  closeDialogue = () => {
    this.setState({
      dialogueStatus: false,
    });
  };

  handleReportNameTextChange = (e) => {
    this.setState({
      channelName: e.target.value,
    });
  };

  onEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  handleModelChange = (editorState) => {
    this.editordata = editorState;
  };

  onSaveClick = () => {
    // const { editorState } = this.state;
    // let html = stateToHTML(editorState.getCurrentContent());
  };

  creatReport = () => {
    const { editorState } = this.state;

    if (this.state.channelName.length == 0) {
      toast.info(this.props.t("AddNewChannel.reportlNameValidation"));
    } else {
      let html = stateToHTML(editorState.getCurrentContent());
      this.setState(
        {
          content: html,
        },
        () => {
          this.apiCallCreateReport();
        }
      );
    }
  };
  apiCallCreateReport = () => {
    const {
      content,
      oldContent,
      reportID,
      report,
      reportImage,
      channelName,
    } = this.state;
    const { report_id, event_id } = this.props.channelList;
    apiCall(
      METHOD.QUERY,
      UpdateReportDataQuery(
        report_id,
        event_id,
        getUserID(),
        reportID,
        channelName,
        JSON.stringify(oldContent),
        JSON.stringify(content)
      ),
      (data) => {
        let content_result = JSON.parse(this.props.channelList.content);
        content_result["result"] = content;
        content_result["content"] = oldContent;

        const reportObj = {
          title: channelName,
          content: JSON.stringify(content_result),
          report_type_id: reportID,
          report_type_name: report,
          report_type_icon: reportImage,
        };

        this.props.updateChannelDetail(reportObj);
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const {
      isLoading,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-red sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    {" "}
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0)}
                      ></i>
                    </span>
                    <div
                      className="btn btn-light font-13 ml-auto pointer"
                      onClick={this.creatReport}
                    >
                      {t("AddNewReport.btnSave")}
                    </div>
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {" "}
                      {t("AddNewReport.editMessageLable")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                {isLoading && <LoadingPage />}

                <div className="container my-4 z-index-99">
                  <div className="form-group">
                    <div className="form-group max-170 m-auto text-center">
                      <a href="javascript;:" onClick={(e)=>{e.preventDefault()}} className="clearfix text-dark">
                        <label className="font-14">
                          {t("AddNewReport.type")}
                        </label>
                        <div
                          className="icon-drop-bg pointer"
                        >
                          <div className="rounded-circle">
                            <img
                              src={`data:image/png;base64,${
                                this.state.reportImage && this.state.reportImage
                              }`}
                              alt="ico"
                            />
                          </div>
                          <span className="font-12">
                            {this.state.report && this.state.report}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="font-14">{t("AddNewReport.title")}</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.handleReportNameTextChange}
                      name="title"
                      value={this.state.channelName}
                    />
                  </div>

                  <div className="icon-box-style1 box-left-line-yellow mb-4 ">
                    <div className="pl-3 py-3 flex-fill">
                      <div className="w-100">
                        <MegadraftEditor
                          editorState={this.state.editorState}
                          onChange={this.onEditorChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.channelListDispatchToProps,
  ])
)(withRouter(withTranslation()(EditNewReport)));
