import {
  GROUP_DETAIL,
  GROUP_DETAIL_CLEARSTATE,
  GROUP_DETAIL_HASMORE,
  UPDATE_GROUP_LIST,
  GROUP_DETAIL_START,
  UPDATE_GROUP_DETAILS,
  UPDATE_GROUP_DETAIL_BY_ID,
  GROUP_DETAIL_DELETE
} from "../constants/action-types";

export const getGroupDetail = data => ({
  type: GROUP_DETAIL,
  payload: data
});

export const updateGroupList = data => ({
  type: UPDATE_GROUP_LIST,
  payload: data
});

export const setHasMoreValue = data => ({
  type: GROUP_DETAIL_HASMORE,
  payload: data
});

export const setStartValue = data => ({
  type: GROUP_DETAIL_START,
  payload: data
});

export const clearStateGroupDetail = data => ({
  type: GROUP_DETAIL_CLEARSTATE,
  payload: data
});

export const updateGroupDetails = data => ({
  type: UPDATE_GROUP_DETAILS,
  payload: data
});

export const updateGroupDetailById = data => ({
  type: UPDATE_GROUP_DETAIL_BY_ID,
  payload: data
});
export const deleteGroupDetailList = data => ({
  type: GROUP_DETAIL_DELETE,
  payload: data
});
