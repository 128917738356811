/* eslint-disable */

export const LoginQuery = (email, password) => `{
  login(email: \"${email}\", password: \"${password}\") {
    message,
    success,
    installation_mode,image,
    user_id,first_name,name,
    user_group {user_group_id,user_group_name},user_role{id,identifier}
  }
}`;

export const ForgotPasswordQuery = (email) => `{
    forgotpassword(email:\"${email}\") {message,success}
}`;

export const ChangePasswordQuery = (password, token) => `{
resetpassword(reset_token:\"${token}\",password:\"${password}\") {message,success}
}`;

export const VerifyUserQuery = (token) => `{
verifyuseremail(registration_token:\"${token}\"){message,success}
}`;

export const ChangePassQuery = (user_id, old_password, new_password) => `{
changepassword(user_id:${user_id},old_password:\"${old_password}\",new_password:\"${new_password}\")
{
   message,success
 }
}`;

export const UpdatePersonStatus = (activate_hash) => `{
changepersonstatus(activate_hash:\"${activate_hash}\")
{
   message,
   success,
 }
}`;

export const SideMenuQuery = () => `{
  sidemenu {message,success,installed_version,side_menu_list{id,title,type,link,icon,identifier}}
}`;

export const EventBrandTypeQuery = (user_id,type) => `{
eventtype (user_id:${user_id},type:${type})  {message,success,event_type_list{id,name,description,identifier,icon}}
}`;
export const EventlocationTypeQuery = (user_id,type) => `{
eventtype (user_id:${user_id},type:${type})  {message,success,location_list{id,name,description}}
}`;

export const CreateEventQuery = (
user_id,
event_id,
event_type_id,
location,
name,
event_date
) => `{
createevent(user_id:${user_id} ${
event_id && ",event_id:" + event_id
},event_type_id:${event_type_id},location:\"${location}\",name:\"${name}\",description:\"Lorem\",event_date:\"${event_date}\") 
{message,success,id,name,event_datetime,event_type_icon,event_creator_name,location}
}`;

export const EventListQuery = (user_id, start, end, type, search) => `{
eventlist(user_id:${user_id},start:${start},limit:${end},type:${type},search:\"${search}\") {message,success,is_last,total_event,event_list{id,name,event_datetime,event_type_icon,event_creator_name,location}}
}`;

export const snippetlistBaseOnSnippetType = (
user_id,
start,
end,
snippet_type_id
) => `{
snippetlist(user_id:${user_id},start:${start},limit:${end},snippet_type_id:${snippet_type_id}) {message,success,is_last,snippet_list{id,name,snippet_type_id,snippet_type_name,snippet_type_identifier}}
}`;

export const ReportListQuery = (user_id, event_id) => `{
reportlist(user_id:${user_id},event_id:${event_id}) {message,success, is_archive,id,name,event_type_icon,
event_datetime,event_creator_name,location,event_type_id,event_type_name,report_list{id,report_type_id,title,report_type_name,report_datetime,report_type_icon,report_creator_name},event_list{id,name}}
}`;

export const ArchiveEventQuery = (user_id, event_id) => `{
archiveevent(user_id:${user_id},event_id:${event_id}) {message,success}
}`;

export const DeleteEventQuery = (user_id, event_id) => `{
deleteevent(user_id:${user_id},event_id:${event_id}) {message,success}
}`;
export const reActivateEventQuery = (user_id, event_id) => `{
archiveevent(user_id:${user_id},event_id:${event_id},do_active:1) {message,success}
}`;

export const updatereporteventsnippet = (user_id, id, snippet_id, name) => `{
updatereporteventsnippet(user_id:${user_id},id:${id},snippet_id:${snippet_id},name:\"${name}\") {message,success}
}`;

export const addAdminChannel = (
user_id,
name,
description,
identifier,
channel_type_id,
settings,
is_active
) => `{
addchannel(user_id:${user_id},name:\"${name}\"
,description:\"${description}\",identifier:\"${identifier}\"
,channel_type_id:${channel_type_id},settings:${settings},is_active:${is_active}
) 
{message,success}
}`;

export const updateAdminChannel = (
user_id,
id,
name,
description,
identifier,
channel_type_id,
settings,
is_active
) => `{
addchannel(user_id:${user_id},id:${id},name:\"${name}\"
,description:\"${description}\",identifier:\"${identifier}\"
,channel_type_id:${channel_type_id},settings:${settings},is_active:${is_active}) 
{message,success}
}`;

export const GetReportTypeQuery = (user_id) => `{
reporttypelist(user_id:${user_id}){message,success,report_type_list{id,name,description,identifier,icon}}}`;

export const deleteSnippet = (user_id, snippet_id) =>
`{removesnippet(snippet_id:${snippet_id},user_id:${user_id}) {message,success}}`;

export const ApproveUser = (user_id, id) =>
`{approveuser(id:${id},user_id:${user_id}) {message,success}}`;
export const deleteUser = (user_id, id) =>
`{removeuser(id:${id},user_id:${user_id}) {message,success}}`;
export const deleteReportType = (user_id, id) =>
`{removereporttype(id:${id},user_id:${user_id}) {message,success}}`;

export const deleteGroupType = (user_id, id) =>
`{removegrouptype(id:${id},user_id:${user_id}) {message,success}}`;

export const deleteEventType = (user_id, id) =>
`{removeeventtype(id:${id},user_id:${user_id}) {message,success}}`;
export const deleteMenuItem = (user_id, id) =>
`{removesidemenu(id:${id},user_id:${user_id}) {message,success}}`;

export const deleteDefaultSnippet = (user_id, id) =>
`{removereporteventsnippet(report_event_snippet_id:${id},user_id:${user_id}) {message,success}}`;

export const removepersondatatype = (user_id, id) =>
`{removepersondatatype(id:${id},user_id:${user_id}) {message,success}}`;
export const removechannelmessage = (user_id, id) =>
`{removechannelmessage(id:${id},user_id:${user_id})  {message,success}}`;

export const removelist = (user_id, id) =>
`{removelist(id:${id},user_id:${user_id})  {message,success}}`;
export const removegroupfromlist = (list_id, user_id, group_id) =>
`{removegroupfromlist(list_id:${list_id},group_id:${group_id},user_id:${user_id}) {message,success}}`;
export const removegroup = (user_id, id) =>
`{removegroup(id:${id},user_id:${user_id})  {message,success}}`;

export const removemessagelist = (user_id, id) =>
`{removemessagelist(id:${id},user_id:${user_id})  {message,success}}`;

export const removepersonfrommailinglist = (user_id, person_id, list_id) =>
  `{removepersonfrommailinglist(user_id:${user_id},person_id:${person_id},list_id:${list_id})
  {message,success}}`;
        
export const removechannel = (user_id, id) =>
`{removechannel(id:${id},user_id:${user_id}) {message,success}}`;

export const deleteLocation = (user_id, id) =>
`{removelocation(id:${id},user_id:${user_id}) {message,success}}`;
export const deleteListType = (user_id, id) =>
`{removelisttype(id:${id},user_id:${user_id}) {message,success}}`;

export const SendEmailQuery = (user_id, message_id) => `{
sendmailtomailchannel (user_id:${user_id},message_id:${message_id})
{
   message,
   success
 }}`;

export const getReportTypeDetails = (user_id, report_type_id) => `{
getreporttypedata(user_id:${user_id},id:${report_type_id}) 
{message,success,id,name,description,identifier,icon,white_icon}
}`;

export const getchanneltypedata = (user_id, id) => `{
getchanneltypedata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,identifier,icon,secondary_icon,color,allow_more_message}
}`;

export const getAdminchanneldata = (user_id, id) => `{
getchanneldata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,identifier,profile_link,channel_type_icon,channel_type_identifier,settings,channel_type_id,channel_type_name,is_active}
}`;

export const getGroupTypeDetails = (user_id, id) => `{
getgrouptypedata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,identifier,icon}
}`;

export const getEventTypeDetails = (user_id, id) => `{
geteventtypedata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,identifier,icon}
}`;

export const getMenuDetails = (user_id, id) => `{
getsidemenudata(user_id:${user_id},id:${id}) 
{message,success,id,title,link,type,icon,identifier}
}`;

export const getpersondatatypedata = (user_id, id) => `{
getpersondatatypedata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,field_type,is_default_field,identifier,is_required}
}`;

export const getlocationdata = (user_id, id) => `{
getlocationdata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,address,coordinates,icon}
}`;

export const getreporteventsnippetdata = (user_id, id) => `{
getreporteventsnippetdata(user_id:${user_id},id:${id}) 
{message,success,id,name,report_type_icon,report_type_name,event_type_name,
event_type_icon,snippet_id,snippet_name}
}`;

export const getListTypeDetails = (user_id, id) => `{
getlisttypedata(user_id:${user_id},id:${id}) 
{message,success,id,name,description,identifier,icon}
}`;

export const SendTextToSpeechQuery = (user_id, message_id) => `{
sendmailtotexttospeechchannel (user_id:${user_id},message_id:${message_id})
{
   message,
   success
 }}`;

export const SendSmsQuery = (user_id, message_id) => `{
sendmailtosmschannel(user_id:${user_id},message_id:${message_id})
{
   message,
   success
  
 }}`;

export const GetSnippetQuery = (event_id, event_type_id, report_type_id) => `{
getsnippetbasedonreporttype (event_id:${event_id}, report_type_id :${report_type_id}, event_type_id :${event_type_id})
{message,success,id,read_only,name,content,snippet_type_id,snippet_type_identifier,raw_value}}`;

export const AddAdminNewUser = (
user_id,
is_active,
name,
first_name,
email,
password,
user_group_id
) => `{
adduser(user_id:${user_id},is_active:${is_active},name:\"${name}\",first_name:\"${first_name}\"
,email:\"${email}\",password:\"${password}\",user_group_id:\"${user_group_id}\") {message,success,id,name}}`;

export const EditAdminNewUser = (
user_id,
id,
is_active,
name,
first_name,
email,
password,
user_group_id
) => `{
adduser(user_id:${user_id},id:${id},is_active:${is_active},name:\"${name}\",first_name:\"${first_name}\"
,email:\"${email}\",password:\"${password}\",user_group_id:\"${user_group_id}\") {message,success,id,name}}`;

export const getSnippetType = () => `{
snippettype
{
   message,
   success,
  snippet_type_list{
      id,
      name,
      description,
      identifier,
      icon
  }
 }}`;

export const getUserType = () =>
`{getusergrouplist {message,success,user_group_list{id,name,description}}}`;

export const getUserDetailsbyadminUserID = (user_id,id) => `{
  getuserdetail(user_id:${user_id},id:${id},type:1)
{
   message,
   success,
   user_id,
   email,
   first_name,
   name,
   is_active,
  user_group{user_group_id,user_group_name}
 }}`;

export const searchGroupQuery = (search) => `{
searchgrouplist(search:\"${search}\")
 {message,success,id,parent_id,description,name,group_creator_name,
  breadcrumb{id,name,parent_group},
  group_list{id,group_name,group_type_icon,description,group_type_id,group_creator_name,person,has_child,has_person},group_header_list{id,name},person_list{id,name,email,is_valid_email}}
}`;


export const searchGroupDetailQuery = (search,group_id) => `{
searchgrouplist(search:\"${search}\",group_id:${group_id})
 {message,success,id,parent_id,description,name,group_creator_name,
  breadcrumb{id,name,parent_group},
  group_list{id,group_name,group_type_icon,description,group_type_id,group_creator_name,person,has_child,has_person},group_header_list{id,name},person_list{id,name,email,is_valid_email}}
}`;


export const GrouptypeQuery = (user_id) => `{
grouptype (user_id:${user_id}) {message,success,group_type_list{id,name,description,identifier,icon}}
}`;
export const GroupListQuery = (
user_id,
start,
end,
group_id,
search,
sort_type
) => `{
grouplist(user_id:${user_id},start:${start},limit:${end},group_id:${group_id},search:\"${search}\",sort_type:${sort_type}) 
{message,success,is_last,id,total_contact,name,group_type_id,parent_id,description,
  group_type_icon,group_type_name,group_creator_name,total_group,
  breadcrumb{id,name,parent_group,person},
  group_list{id,group_type_icon,has_child,name,description,group_type_id,group_creator_name,person},group_header_list{id,name,person}}
}`;
export const GroupDetailPersonListQuery = (
user_id,
group_id,
start,
limit,
sort_type
) => `{
grouppersonlist(user_id:${user_id},group_id:${group_id},start:${start},limit:${limit},sort_type:${sort_type}) 
{message,success,id,name,parent_id,description,group_type_id,person,group_creator_name,group_type_icon,group_type_name,is_last,
  person_list{id,full_name,master_id,sort_order,email,is_valid_email},breadcrumb{id,name,parent_group,person},group_list{id,name,person}}
}`;


export const expandableGetPersonListGroupWise = (
user_id,
group_id,
) => `{
grouppersonlist(user_id:${user_id},group_id:${group_id},start:0,limit:0,sort_type:1) 
{message,success,
  person_list{id,full_name,email}}
}`;
export const CreateGroupQuery = (
user_id,
group_type_id,
name,
description,
parent_group_id
) => `{
creategroup(user_id:${user_id},group_type_id:${group_type_id},name:\"${name}\",description:\"${description}\",parent_group_id:${parent_group_id}) {message,success,id,name,description,group_type_id,group_type_icon,group_creator_name}
}`;

export const EditGroupQuery = (
user_id,
group_type_id,
name,
description,
parent_group_id,
group_id
) => `{
creategroup(group_id:${group_id},user_id:${user_id},group_type_id:${group_type_id},name:\"${name}\",description:\"${description}\",parent_group_id:${parent_group_id}) {message,success,id,name,description,group_type_id,group_type_icon,group_creator_name}
}`;
export const ReortDetailQuery = (report_id) => `{
reportdetail(report_id:${report_id}) 
{message,report_id ,success,event_id,channel_id,title,content,report_type_white_icon,title ,event_name,report_datetime,report_creator_name
,report_type_id,report_type_name,report_type_icon,
channel_list{message_id,channel_id,channel_type_id,channel_type_identifier,channel_type_icon,channel_type_secondary_icon,channel_type_color,channel_type_name ,message_datetime,message_creator_name,message_status,message_status_identifier,message_send_datetime,percentage_status},report_list{id,title,report_type_id,report_type_name}}
}`;

export const SnippetDataQuery = (snippet_id,user_id) => `{
getsnippetdata(snippet_id:${snippet_id}user_id:${user_id},) 
{message,success,id,name,content,snippet_type_id,snippet_type_identifier,snippet_type_icon,raw_value}
}`;

export const SnippetDataAdminQuery = (snippet_id,user_id) => `{
  getsnippetdata(snippet_id:${snippet_id}user_id:${user_id},type:1) 
  {message,success,id,name,content,snippet_type_id,snippet_type_identifier,snippet_type_icon,raw_value}
  }`;

export const CreateReportDataQuery = (
eventId,
userId,
reportTypeId,
title,
content,
result
) => `{
createeventreport(event_id:${eventId},user_id:${userId},report_type_id:${reportTypeId}
,title:\"${title}\",
content:${content},result:${result})
{message}
}`;

export const UpdateReportDataQuery = (
report_id,
eventId,
userId,
reportTypeId,
title,
content,
result
) => `{
updateeventreport(report_id:${report_id},event_id:${eventId},user_id:${userId},report_type_id:${reportTypeId}
,title:\"${title}\",
content:${content},result:${result})
{message}
}`;

export const MaillingListQuery = (user_id, start, end, search) => `{
mailinglist(user_id:${user_id}, start:${start},limit:${end},search:\"${search}\") 
{message,success,is_last,mailing_list{id,name,description,list_type_icon,list_type_id,child_element}}
}`;

export const addgrouppersonintomailinglist = (list_id, user_id, group_id) => `{
addgrouppersonintomailinglist(list_id:${list_id},user_id:${user_id},group_id:\"${group_id}\") {message,success} }`;

export const messageMaillingListQuery = (start, end, message_id) => `{
 messagemailinglist(start:${start},limit:${end},message_id:${message_id}) 
 {message,success,is_last,mailing_list{master_id,id,mail_name,description,list_type_id,person,
  person_list{person_id,name,list_id,email,status}}}
  }`;

export const MailtypeQuery = (user_id) => `{
listtype (user_id:${user_id}){message,success,list_type_list{id,name,description,identifier,icon}}
}`;

export const removepersonmessagelist = (user_id,message_id,list_id,person_id) => `{
removepersonmessagelist(user_id:${user_id},message_id:${message_id},list_id:${list_id},person_id:${person_id}) {message,success}
}`;

export const CreateMailQuery = (user_id, list_type_id, name, description) => `{
createmailinglist(user_id:${user_id},list_type_id:${list_type_id}
  ,name:\"${name}\",description:\"${description}\") 
  {message,id}
}`;

export const UpdateMailQuery = (
user_id,
list_id,
list_type_id,
name,
description
) => `{
createmailinglist(user_id:${user_id},list_id:${list_id}, list_type_id:${list_type_id}
  ,name:\"${name}\",description:\"${description}\") 
  {message,id}
}`;

export const CreateSubMailQuery = (
user_id,
list_type_id,
name,
description,
parentId
) => `{
createmailinglist(user_id:${user_id},list_type_id:${list_type_id},parent_list_id:${parentId},
  ,name:\"${name}\",description:\"${description}\") 
  {message,id}
}`;

export const MailDetailsQuery = (user_id, list_id,search) => `{
contentofmailinglist(user_id:${user_id},list_id:${list_id},search:\"${search}\") 
{message,success,id,name,description,list_type_icon,list_type_name,list_type_id,
child_element,total_person,child_list{id,name,list_type_icon,person},mailing_list{id,name}}
}`;

export const getmailinglistgroup = (user_id, list_id) => `{
getmailinglistgroup(user_id:${user_id},list_id:${list_id})  
{message,success,mailing_name,description,total_person,child_element,mailing_id,list_type_id,list_type_icon
  ,group_list{id,name,is_associated,person},
  mailing_list{mail_id,mail_name}}
}`;

export const getmailinglistgroupperson = (user_id, group_id, list_id) => `{
getmailinglistgroupperson(group_id:${group_id},user_id:${user_id},list_id:${list_id}) {message,success,person_list{id,name,email,is_deletable}}
}`;

//static
export const MailPersonListQuery = (user_id, list_id, search) => `{
listpersonlist(user_id:${user_id},list_id:${list_id},search:\"${search}\")
{message,success,id,name,list_type_icon,person,list_type_name , list_type_id, description,
group_list{id,icon,name,parent_group_name},person_list{id,name,email,is_deletable}}
}`;

export const adminOptionList = (user_id) => `{
adminoptionlist(user_id:${user_id})
{message,success,system_email_count,person_data_type_count,channel_count,snippet_count,user_count,side_menu_count,report_type_count,group_type_count,event_type_count,list_type_count,channel_type_count,location_count,default_snippet_count}
}`;
export const adminSnippetList = (user_id, start, end, snippet_type_id) => `{
snippetlist(user_id:${user_id},start:${start},limit:${end},type:1,snippet_type_id:${snippet_type_id}) {message,success,is_last,snippet_list{id,name,snippet_type_id,snippet_type_identifier,snippet_type_name}}
}`;
export const adminUserList = (user_id, start, end) => `{
userlist(user_id:${user_id},start:${start},limit:${end}) 
{message,success,is_last,user_list{id,name,group_name,status}}
}`;
export const adminReportTypeList = (user_id) => `{
reporttypelist(user_id:${user_id},type:1){message,success,report_type_list{id,name,description,identifier,icon,white_icon}}
}`;

export const adminGroupTypeList = (user_id) => `{
grouptype (user_id:${user_id},type:1){message,success,group_type_list{id,name,description,identifier,icon }}
}`;

export const adminEvnetListTypeList = (user_id,type) => `{
eventtype (user_id:${user_id},type:${type}){message,success,event_type_list{id,name,description,identifier,icon}}
}`;

export const adminLocationList = (user_id,type) => `{
eventtype  (user_id:${user_id},type:${type}){message,success,location_list{id,name,description,icon}}
}`;

export const menuAdminList = (user_id,type) => `{
sidemenu(user_id:${user_id},type:${type}) {message,success,installed_version,side_menu_list{id,title,type,link,icon,identifier}}
}`;

export const channellist = (user_id) => `{
channellist (user_id:${user_id}){message,success,is_last,channel_list{id,name,channel_type_icon,channel_type_secondary_icon,description,settings,channel_type_id,channel_type_name,channel_type_identifier}}
}`;

export const adminChannellist = (user_id) => `{
  channellist(user_id:${user_id},type:1)  {message,success,is_last,channel_list{id,name,channel_type_icon,channel_type_secondary_icon,description,settings,channel_type_id,channel_type_name,channel_type_identifier}}
  }`;

export const persondatatype = (user_id, start, end) => `{
persondatatype (user_id:${user_id},start:${start},limit:${end}) {message,success,is_last,person_data_type_list{id,name,description,field_type,is_default_field,identifier,is_required}}
}`;

export const reporteventsnippetlist = (
user_id,
start,
end,
report_type_id
) => `{
reporteventsnippetlist(user_id:${user_id},start:${start},limit:${end},report_type_id:${report_type_id})
{message,success,is_last,report_event_snippet_list{id,name,snippet_name}}
}`;

export const adminListTypeList = (user_id,type) => `{
listtype (user_id:${user_id},type:1){message,success,list_type_list{id,name,description,identifier,icon}}
}`;

export const adminChannelTypeList = (user_id) => `{
channeltype(user_id:${user_id},type:1) {message,success,channel_type_list{id,name,description,identifier,icon,color,secondary_icon}}
}`;

export const adminEmailList = (user_id) => `{
systememaillist(user_id:${user_id},start:0,limit:100) {message,success,is_last,systememail_list{id,subject}}
}`;

export const getEmailDetails = (email_id, user_id) => `{
getsystememaildata(id:${email_id},user_id:${user_id}) {message,success,id,subject,content,identifier}}`;

export const updateEmailDetails = (user_id, id, subject, content) => `{
createsystememail(user_id:${user_id},id:${id},subject:\"${subject}\",
content:${content}) {message,success,id,subject,content,identifier}}`;

export const addAdminUser = (
user_id,
is_active,
name,
first_name,
email,
password,
user_group_id
) => `{adduser(user_id:${user_id},is_active:${is_active}
      ,name:\"${name}\",first_name:\"${first_name}\",email:\"${email}\",password:\"${password}\"
      ,user_group_id:\"${user_group_id}\") {message,success,id,name}}
}`;

export const editAdminUser = (
user_id,
id,
is_active,
name,
first_name,
email,
password,
user_group_id
) => `{adduser(user_id:${user_id},id:${id},is_active:${is_active}
      ,name:\"${name}\",first_name:\"${first_name}\",email:\"${email}\",password:\"${password}\"
      ,user_group_id:\"${user_group_id}\") {message,success,id,name}}
}`;

export const refernceSnippetList = (user_id, start, end, is_require_base) => `{
snippetlist(user_id:${user_id},start:${start},is_require_base:${is_require_base},limit:${end},type:1) 
{message,success,is_last,snippet_list{id,name,snippet_type_identifier,snippet_type_name}}
}`;


//static
export const deletePersonMailQuery = (user_id, list_id, person_id) => `{
removepersonfrommailinglist(user_id:${user_id},person_id:${person_id},list_id:${list_id})
{
   message,
   success
  
 }
}`;

//static
export const removepersonfrommailinglistgroup = (
user_id,
group_id,
person_id,
list_id
) => `{
removepersonfrommailinglistgroup(group_id:${group_id},person_id:${person_id},user_id:${user_id},list_id:${list_id}) {message,success}
}`;

export const CreateReportChannelQuery = (
user_id,
report_id,
channel_id,
title,
content,
result
) => `{
createreportchannel(user_id:${user_id},report_id:${report_id},channel_id:${channel_id}
,title:\"${title}\",
content:${content},result:${result}) {message,success,message_id,channel_id,channel_type_id,channel_type_name,channel_type_icon,message_datetime,message_creator_name}
}`;

export const personDetailQuery = (user_id, person_id) => `{
persondetail(user_id:${user_id},person_id:${person_id}) 
{message,success,id,full_name,send_registration_mail,title,company_name,other_field_list{field_name,field_value},department,email,phone,mobile,comment,website,fax,business_adress,private_adress,mailing_list{id,name,list_creator_name,list_type_icon}}
}`;

export const getChannelTypeList = (user_id) => `{
channellist (user_id:${user_id}){message,success,is_last,channel_list{id,name,channel_type_identifier,description,settings,channel_type_id,channel_type_secondary_icon,channel_type_icon,channel_type_name}}
}`;

export const getPersonField = () => `{
getpersondatatype {message,success,person_data_type_list{id,name,is_required,description,field_type,is_default_field,identifier}}
}`;

export const getFieldTypeEditPerson = (user_id, person_id) => `{
personinfoingroup(user_id:${user_id},person_id:${person_id})
{
   message,
   success,
   id,
   name,
   first_name,
   title,
   contact_email_business,
   person_data{
       id,
       value
   }
 }
}`;

export const createPerson = (
user_id,
group_id,
first_name,
last_name,
title,
person_data,
contact_email_business,
send_registration_mail
) => `{
createpersongroup(send_registration_mail:${send_registration_mail},user_id:${user_id},group_id:${group_id},name:\"${last_name}\"
,first_name:\"${first_name}\",title:\"${title}\",contact_email_business:\"${contact_email_business}\",person_data:${person_data})
{
   message,
   success,
   id,
   name,
   email,
   sort_order,
   is_valid_email
 }
}`;

export const updatePerson = (
user_id,
person_id,
first_name,
last_name,
title,
contact_email_business,
person_data,
sendRegistrationEmail
) => `{
updatepersongroup(send_registration_mail:${sendRegistrationEmail},user_id:${user_id},person_id:${person_id},name:\"${last_name}\"
,first_name:\"${first_name}\",title:\"${title}\",
contact_email_business:\"${contact_email_business}\",person_data:${person_data})
{
   message,
   success,id,name,company_name,department,email,phone,mobile,comment,website,fax,business_adress,private_adress,mailing_list{id,name,list_creator_name,list_type_icon}
 }
}`;

export const deletePerson = (user_id, group_id, person_id) => `{
removepersonfromgroup(user_id:${user_id},person_id:${person_id},group_id:${group_id})
{
   message,
   success
 }
}`;

export const generalPersonsList = (user_id, start, limit, search) => `{
personlist(user_id:${user_id},start:${start},limit:${limit},search:\"${search}\",is_group_base:1)
{
   message,
   success,
   is_last,
   person_list{
       id,
       name
   }
 }
}`;

export const generalPerson = (user_id, start, limit, search) => `{
personlist(user_id:${user_id},start:${start},limit:${limit},search:\"${search}\",is_group_base:0)
{
   message,
   success,
   is_last,
   person_list{
       id,
       name
   }
 }
}`;

//static
export const addGeneralPersonToMaillingList = (
user_id,
person_id,
list_id
) => `{
addpersonintomailinglist(user_id:${user_id},person_id:${person_id},list_id:${list_id})
{
   message,
   success
  
 }
}`;

//Static
export const addGeneralGroupToMaillingList = (user_id, group_id, list_id) => `{
addgroupintomailinglist(user_id:${user_id},group_id:\"${group_id}\",list_id:${list_id})
{
   message,
   success
  
 }
}`;

export const getSocialDetails = (user_id) => `{
getsocialdetail(user_id: ${user_id})
{
message,
  success,
  facebook_app_id,
  facebook_app_secret,
  twitter_consumer_key,
  twitter_consumer_secret
}
}`;

export const getFacebookList = (user_id, user_access_token) => `{
getfacebookuserpage(user_access_token:\"${user_access_token}\",user_id:${user_id})
{message,success,page_list{id,name}}}`;

export const maketwitteroauthtoken = (
oauth_token_secret,
oauth_token,
user_id
) => `{
maketwitteroauthtoken(oauth_token_secret:\"${oauth_token_secret}\",
oauth_token:\"${oauth_token}\",user_id:${user_id})
{message,success}}`;

export const makefacebookuserpageaccesstoken = (
page_id,
user_access_token,
user_id
) => `{
makefacebookuserpageaccesstoken(page_id:\"${page_id}\",user_access_token:\"${user_access_token}\",user_id:${user_id})
{message,success}}`;

export const sendFacebookPost = (user_id, message_id) => `{
postmessagetofacebookchannel(user_id:${user_id},message_id:${message_id} 
)
{
   message,
   success
 }
}`;

export const sendTwitterPost = (user_id, message_id) => `{
postmessagetotwitterchannel(user_id:${user_id},message_id:${message_id})
{
   message,
   success,
   message_link
 }
}`;

//Static message_id
export const assignMailingListtoChannelMessage = (
user_id,
message_id,
list_id
) => `{
assignmailinglisttochannelmessage(user_id:${user_id},
message_id:${message_id},list_id:\"${list_id}\")
{
   message,
   success
 }
}`;
//Static message_id
export const channelDetail = (user_id, message_id, event_id) => `{
messagedetail(user_id:${user_id},message_id:${message_id},event_id:${event_id})
{
   message,
   success,
   id,
   title,
   content,
   event_id,
   report_id,
   event_name,
   event_datetime,
   event_creator_name,
    message_send_datetime,
    message_datetime,
   message_status,
   message_link,
   message_status_identifier,
   percentage_status,
   channel_type_icon,
   color,
   is_email_channel_type,
   channel_type_id,
   channel_type_name,
   channel_id ,
   channel_type_identifier,
    channel_list{
      message_id,
      channel_id,
      channel_type_id,
      channel_type_name
  }
 }
}`;

export const reArrangePersonListQuery = (user_id, group_id, person_list) => `{
changegrouppersonorder(user_id:${user_id},
group_id:${group_id}, person_list:${person_list})
{
   message,
   success
 }
}`;

export const changegrouporder = (user_id, group_list) => `{
changegrouporder(user_id:${user_id},
group_list:${group_list})
{
   message,
   success
 }
}`;

export const adminCreateSnippet = (
user_id,
snippet_type_id,
name,
content,
raw_value
) => `{
createsnippet(user_id:${user_id},snippet_type_id:${snippet_type_id}
,name:\"${name}\",content:${content},raw_value:${raw_value}) {message,success,id,name,content,snippet_type_id}
}`;

export const updateAminCreateSnippet = (
user_id,
snippet_id,
snippet_type_id,
name,
content,
raw_value
) => `{
createsnippet(user_id:${user_id},snippet_id:${snippet_id},snippet_type_id:${snippet_type_id}
,name:\"${name}\",content:${content},raw_value:${raw_value}) {message,success,id,name,content,snippet_type_id}
}`;

export const personSubscriptionDetail = (activate_hash) => `{
personsubscriptiondetail(activate_hash:\"${activate_hash}\")
  {
      message,success,id,name,first_name,title,contact_email_business,
      person_data{id,value},
      mailing_list{id,name,is_subscribe}
  }
}`;
export const subscribeUser = (email) => `{
   requestmyprofile(email:\"${email}\")
{
   message,success
 }
}`;

export const getUserDetails = (user_id,id) => `{
   getuserdetail(user_id:${user_id},id:${id})
{
   message,
   success,
   user_id,
   email,
   first_name,
   name,image
 }
}`;

export const updateUserDetails = (user_id, email, name, first_name) => `{
   updateuserdetail(user_id:${user_id},email:\"${email}\"
   ,name:\"${name}\",first_name:\"${first_name}\")
{
   message,
   success
 }
}`;

export const registerUser = (email, name, first_name, password) => `{
   registeruser(email:\"${email}\"
   ,name:\"${name}\",first_name:\"${first_name}\",password:\"${password}\")
{
   message,
   success
 }

}`;
export const registerUserVerifyEmail = (token) => `{
    verifyuseremail(registration_token:\"${token}\")
{
   message,
   success
 }
}`;

export const getServerType = () => `{
    getinstallationvalue{message,success,value,system_logo}
}`;

export const unSubscribeUser = (email) => `{
   requestmyprofile(email:\"${email}\")
{
   message,success
 }
}`;
export const checkCost = (user_id, channel_id, message) => `{
  checkcost(user_id:${user_id},channel_id :${channel_id},message:${message}) {message,success,price}
}`;
export const checksmscost = (user_id, channel_id, message) => `{
  checksmscost(user_id:${user_id},channel_id :${channel_id},message:${message}) {message,success,price}
}`;

export const getinstallationvalue = (user_id, channel_id, message) => `{
getinstallationvalue {message,success,value,system_logo}
}`;


export const updateReportChannel = (
user_id,
message_id,
report_id,
channel_id,
title,
content,
result
) => `{
   updatereportchannel(user_id:${user_id},message_id:${message_id},report_id:${report_id},
    channel_id:${channel_id},
    title:\"${title}\",
    content:${content},result:${result})
     {message,success,message_id,channel_id,channel_type_id,channel_type_name,channel_type_icon,message_datetime,message_creator_name}
}`;

export const updatePersonSubScription = (
activate_hash,
first_name,
last_name,
title,
contact_email_business,
person_data,
subscribe_list_ids
) => `{
updatepersonsubscriptiondetail(activate_hash:\"${activate_hash}\"
,name:\"${last_name}\",first_name:\"${first_name}\",title:\"${title}\",
contact_email_business:\"${contact_email_business}\",person_data:${person_data},
subscribe_list_ids:\"${subscribe_list_ids}\")
{
   message,success,id,name,first_name,title,
      person_data{id,value},
      mailing_list{id,name,is_subscribe}
 }
}`;

export const testing = (t1) => `{
createpersongroup(user_id:2,group_id:2,name:"a2",first_name:"b2",title:"c2",
person_data:${t1})
{
   message,
   success,
 }
}`;

export const movepersongroup = (
user_id,
group_id,
person_id,
old_group_id
) => `{
movepersongroup(user_id:${user_id},group_id:${group_id}
  ,person_id:${person_id},old_group_id:${old_group_id}) {message,success}
}`;

//


