import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { removelist } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";


class PersonListSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      deleteMailPermission:false,
      editMailPermission:false,

    };
  }

 componentDidMount() {
  const deleteMailPermission = checkUserPermission("delete_list");
  const editMailPermission = checkUserPermission("edit_list");
  
   this.setState({
    deleteMailPermission,
    editMailPermission
   })
 }

  deleteList = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    const mail_id = fields[1];
    confirmAlert({
      title: this.props.t("personList.deleteList"),
      message: this.props.t("personList.deleteListMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removelist(getUserID(), list_id),
              (data) => {
                let mybackStackpath =
                  "/mail-detail/" + mail_id;
                this.props.history.push(mybackStackpath);
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => { },
        },
      ],
    });

  }
  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    const {deleteMailPermission,editMailPermission} = this.state
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                
                {editMailPermission && <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={e => {
                        e.preventDefault();
                        this.props.changeRouteStatus(1);
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("personDetail.addPerson")}
                      </p>
                      <div className="float-left rounded-40 yellow-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
                  }
                     {editMailPermission &&   <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={e => {
                        e.preventDefault();
                        this.props.changeRouteStatus(4);
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("personDetail.addGroupContacts")}
                      </p>
                      <div className="float-left rounded-40 yellow-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>}
                
                  {editMailPermission &&  <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={e => {
                        e.preventDefault();
                        this.props.changeRouteStatus(2);
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("personList.addGroup")}
                      </p>
                      <div className="float-left rounded-40 yellow-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>}
                 

                {deleteMailPermission && <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={e => {
                        e.preventDefault();
                        this.deleteList()
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("personList.deleteGroup")}
                      </p>
                      <div className="float-left rounded-40 yellow-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
}


{editMailPermission && (  <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={e => {
                        e.preventDefault();
                        this.props.changeRouteStatus(3);
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("personList.editGroup")}
                      </p>
                      <div className="float-left rounded-40 yellow-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
               )}
             
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.deleteEventReportDispatchToProps,
    actions.asyncApiDispatchToProps
  ])
)(withTranslation()(withRouter(PersonListSetteliteSideMenu)));
