/* eslint eqeqeq: 0 */
import { css } from "glamor";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageNotFound from "./components/CommanComponents/PageNotFound";
import ProductionHelmetTag from "./components/CommanComponents/ProductionHelmetTag";
import PrivateRoute from "./components/CommanComponents/securedRoutes";
import TestServerHelmetTag from "./components/CommanComponents/TestServerHelmetTag";
import { getServerType } from "./constants/apiParams";
import apiCall, { METHOD } from "./constants/baseUrl";
import { appRoutes, privateRoutes } from "./constants/routes";
import { getValueFromLocalStorage, saveDatatoLocalStorage } from "./util/utils";

export const toastSuccessOption = {
  className: css({
    background: "#A2C037 !important",
    color: "white !important",
  }),
};

export const toastErrorOption = {
  className: css({
    background: "#B31218 !important",
    color: "white !important",
  }),
};

export const toastInfoOption = {
  className: css({
    background: "#1E8DCB !important",
    color: "white !important",
  }),
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      server_mode: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    const server_type = getValueFromLocalStorage("server_type");
    if (!server_type) {
      apiCall(
        METHOD.QUERY,
        getServerType(),
        (data) => {
          saveDatatoLocalStorage("server_type", data.value);
          saveDatatoLocalStorage("system_logo", data.system_logo);
          this.setState({
            server_mode: data.value,
          });
        },
        (isLoading) => {
          this.setState({ isLoading });
        },
        (data) => {}
      );
    } else {
      this.setState({
        server_mode: server_type,
      });
    }
  }

  render() {
    const { server_mode, isLoading } = this.state;
    return (
      <React.Fragment>
        {!isLoading && (
          <React.Fragment>
            {server_mode == "TEST" ? (
              <TestServerHelmetTag />
            ) : (
              <ProductionHelmetTag />
            )}
            <Router>
              <Switch>
                {appRoutes.map((routeProps, index) => (
                  <Route key={index} {...routeProps} />
                ))}
                {privateRoutes.map((routeProps, index) => {
                  return <PrivateRoute key={index} {...routeProps} />;
                })}
                <Route component={PageNotFound} />
              </Switch>
            </Router>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default withTranslation()(App);
