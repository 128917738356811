/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddSubMail from "./AddSubMail";
import EditMail from "./EditMail";
import MailDetails from "./MailDetails";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
    };
  }
  changeRouteStatus = (value) => {
    this.setState({
      routeStatus: value,
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <MailDetails changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <AddSubMail
              item={{
                id: 1,
                name: "Group",
                description: "Group",
                identifier: "group",
                icon:
                  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgB7ZQxDoAgDEW/xoPgGVi4/84Z4B4MWkxcNPQzVE0MbyhDm5T+X5ig4L3f0EGMcWrlZgwIN+16dW9x9WN4QDn0Et2THA62ZPFjPT1wsMfV8LjJ3zaoO639MyxPG1iw9BSxW2qoE4QQHAisRp2glJLkjYDUqPmfr+lo8GqDDHtyDTsZwyQcDhELBwAAAABJRU5ErkJggg==",
                __typename: "Listtypelist",
              }}
              changeRouteStatus={this.changeRouteStatus}
            />
          )}

          {routeStatus == 2 && (
            <AddSubMail
              item={{
                id: 2,
                name: "Simple List",
                description: "Simple List",
                identifier: "list",
                icon:
                  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB3ZTLCcMwDIYt51JysjNBjh2hY3ST0Ftupceemo7QTTJCRugEsW/JKalcQiiBWkLQBxEYP9AnGUn8SjEty7KjtdaFhecLl0s4TiHgOI4lHjfT2qVpCl3X1RQLimHh17iZxbN3zlmK1erDxkqA5bku3wDgxkB5Pej7vsaah3JswxUTnrE8pfoHI5tsjMm11gX+eo/XfHpusETNMAwn7/1dnACDFxioigYAOLRtW4kSvBnPpUXHlZoiKjjXZy0mFbQYl7w4iQSN4uYpkgoaxX1P7KSCRnFzD6SC9nMhfDZZKmgcDqSCxuVAKmhcTiu5oLG4B4W9ojGP+hlUAAAAAElFTkSuQmCC",
                __typename: "Listtypelist",
              }}
              changeRouteStatus={this.changeRouteStatus}
            />
          )}

          {routeStatus == 3 && (
            <EditMail changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
