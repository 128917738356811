import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { saveDatatoLocalStorage } from '../../util/utils';

 class TwitterAcknowledge extends Component {
     componentDidMount() {
        //  alert("test")
         let hashValue =this.props.history.location.hash

        //  let hashValue =`{%22oauth_token%22:%221231895791423410176-0G34kcrZiieXogGjoJqxzsoRt5itQj%22,%22oauth_token_secret%22:%22f6yjNtpQDWUEwmbfntcBxNuosviIROvsewm1h7ZLKKGNl%22,%22user_id%22:%221231895791423410176%22,%22screen_name%22:%22UrgentpublishD%22}`
         hashValue = decodeURI(hashValue)
         hashValue = hashValue.replace("#", "");

         
         hashValue= JSON.parse(hashValue)
         console.log("hashValue",hashValue);

         const oauth_token = hashValue.oauth_token
         const oauth_token_secret = hashValue.oauth_token_secret
         const user_id = hashValue.user_id
         const screen_name = hashValue.screen_name

         console.log("oauth_token",oauth_token);
         console.log("oauth_token_secret",oauth_token_secret);
         console.log("user_id",user_id);
         console.log("screen_name",screen_name);


         saveDatatoLocalStorage("twitter_oauth_token",oauth_token)
         saveDatatoLocalStorage("twitter_oauth_token_secret",oauth_token_secret)
         saveDatatoLocalStorage("twitter_user_id",user_id)
         saveDatatoLocalStorage("twitter_screen_name",screen_name)
         window.close()
     }
    render() {
        return (
           <div className="pointer" >
            Please close dialogue
           </div>
        )
    }
}
export default withRouter(TwitterAcknowledge)
