/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { GroupListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import NoRecordFound from "../CommanComponents/NoRecordFound";
import GroupDetails from "./GroupDetails";
import GroupDetailsSetteliteSideMenu from "./GroupDetailsSetteliteSideMenu";
import SubGroupItem from "./SubGroupItem";

class GroupDetailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: 1,
      isLoading: false,
      headerMenuStatus: false,
      hasMore: true,
      end: 10,
      group_header_list: [],
    };
  }

  loadItem = () => {
    const rootGroup = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;
    if (!isLoading && this.props.groupDetail.hasMore) {
      this.setState({
        isLoading: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupDetail.start,
          end,
          rootGroup,
          "",
          sortType
        ),
        (data) => {
          this.setState({
            group_header_list: data.group_header_list,
          });
          if (this.props.groupDetail.id) {
            this.props.updateGroupList(data);
          } else {
            this.props.getGroupDetail(data);
          }
          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loader = (<LoadingPage key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setHasMoreValue(false);
    }

    this.props.setStartValue(this.props.groupDetail.start + end);
    this.setState((prevState) => {
      return {
        isLoading: false,
      };
    });
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupDetail();
      }
    );
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleChangeGroup = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (!person || person == 0) {
      this.setState({ isSubGroupList: true });
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.setState({ isSubGroupList: false });
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  componentDidMount() {
    const groupListPermission = checkUserPermission("read_group");
    this.setState({
      groupListPermission
    })
  }

  render() {
    const { sortType, end, isLoading, group_header_list,groupListPermission } = this.state;
    const {
      name,
      group_type_icon,
      total_contact,
      breadcrumb,
    } = this.props.groupDetail && this.props.groupDetail;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <GroupDetails
                group_header_list={group_header_list}
                current_id={this.props.groupDetail.id}
                name={name}
              />
              

              <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                  <div className="icon-box-style2 mr-4 mb-4">
                    <div className="icon-box blue-bg d-flex">
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="rounded-50 bg-light">
                          <img
                            src={`data:image/jpg;base64,${
                              group_type_icon && group_type_icon
                            }`}
                            style={{ width: "32px" }}
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 my-3 d-inline-block">
                      <h5 className="m-0 font-18">{name && name} </h5>
                      <p className="text-muted font-12 mb-0">
                        {total_contact && total_contact}{" "}
                        {total_contact && total_contact == 1
                          ? t("GroupListScreen.singularPerson")
                          : t("GroupListScreen.pluralPerson")}
                      </p>
                    </div>
                  </div>

                  <div className="d-block flex-fill">
                    <div className="ml-2 my-2 mb-3 d-flex">
                      <div className="d-flex w-100">
                        <div className="clearfix d-flex0 0align-items-center">
                          <p className="font-12 m-0">
                            <React.Fragment>
                              <Link
                                to="/group-list"
                                className="text-muted"
                                data-tip={"Start"}
                              >
                                {t("GroupListScreen.start")}
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                              </Link>
                              {" > "}
                            </React.Fragment>
                            {breadcrumb &&
                              breadcrumb.map((item, index) => {
                                if (index == 0) {
                                  return (
                                    <React.Fragment key={index}>
                                      <a
                                        href="javascript;:"
                                        className="text-muted"
                                        data-tip={item.name}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleChangeGroup(item.id,item.person);
                                        }}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        {item.name}
                                      </a>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index != 0 &&
                                  index != breadcrumb.length - 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      <a
                                        href="javascript;:"
                                        className="text-muted"
                                        data-tip={item.name}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleChangeGroup(item.id);
                                        }}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        [...]
                                      </a>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (index == breadcrumb.length - 1) {
                                  return (
                                    <React.Fragment key={index}>
                                      {name && name}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-4 z-index-99">
                    <div className="mb-4">
                      <div className="clearfix sorting-filter">
                        <span className="font-13 text-muted mr-2">
                          {t("GroupListScreen.sorting")}
                        </span>
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="First group"
                        >
                          <button
                            type="button"
                            onClick={() => this.changeSortType(1)}
                            className={
                              sortType == 1
                                ? "btn btn-dark"
                                : "btn btn-outline-secondary"
                            }
                          >
                            {t("GroupListScreen.a-z")}
                          </button>

                          <button
                            type="button"
                            onClick={() => this.changeSortType(2)}
                            className={
                              sortType == 2
                                ? "btn btn-dark"
                                : "btn btn-outline-secondary"
                            }
                          >
                            {t("GroupListScreen.orderOfPriority")}
                          </button>
                          <button
                            type="button"
                            onClick={() => this.changeSortType(3)}
                            className={
                              sortType == 3
                                ? "btn btn-dark"
                                : "btn btn-outline-secondary"
                            }
                          >
                            {t("GroupListScreen.crisisContacts")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {groupListPermission ? (   <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={this.props.groupDetail.hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-4 mr-4">
                    {!!this.props.groupDetail.group_list.length &&
                      this.props.groupDetail.group_list.map((data, index) => {
                        return (
                          <SubGroupItem data={data} key={index} index={index} />
                        );
                      })}

                    {!isLoading &&
                      this.props.groupDetail.group_list &&
                      this.props.groupDetail.group_list.length == 0 && (
                        <NoRecordFound title="Group List" name="group list" />
                      )}
                  </div>
                </InfiniteScroll>
) :(<span>{t("GeneralMessages.permissionError")}</span>)}
             
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>
              <GroupDetailsSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeRouteStatus}
              />

              <Footer type="3" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupDetailItem)));
