import React from "react";

export default function FabIcon(props) {
  const { openSlider, color } = props;
  return (
    <div className="btn-fixed-bottom clearfix z-index-99">
      <button
        className="navbar-toggler collapsed float-right"
        onClick={openSlider}
        type="button"
        data-toggle="collapse"
        data-target="#Brand-toggler"
        aria-controls="Brand-toggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div className={`rounded-40 ${color} float-right`}>
          <i className="zmdi zmdi-more-vert zmdi-hc-fw font-22"></i>
        </div>
      </button>
    </div>
  );
}
