/* eslint eqeqeq: 0 */
import { editorStateFromRaw, MegadraftEditor } from "megadraft";
import "megadraft/dist/css/megadraft.css";
import LinkInput from "megadraft/lib/entity_inputs/LinkInput";
import icons from "megadraft/lib/icons";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { refernceSnippetList } from "../../../../constants/apiParams";
import apiCall, { METHOD } from "../../../../constants/baseUrl";
import { getUserID } from "../../../../util/utils";

/**
 * All Editor Related method
 */

/*
 * This component represents the reference in the editor. In this case it is a span
 * */
// const SnippetReferenceComponent = ({ entityKey, children, contentState }) => {
//   const { url } = contentState.getEntity(entityKey).getData();

//   return (
//     <span
//       className="editor__link"
//       href={url}
//       title={url}
//       style={{ backgroundColor: "red" }}
//     >
//       {children}
//     </span>
//   );
// };

/*
 * This is the dialog to select the reference to assign to the selected text
 * The list should be loaded dynamically
 * */
class ReferenceInput extends React.Component {
  constructor(props) {
    super(props);
    // load pages from somewhere
    this.pages = [
      { type: "text", title: "Text", id: "1" },
      { type: "selection", title: "Selection", id: "2" },
      { type: "number", title: "Number", id: "42" },
      // ...
    ];
    this.state = {
      snippet_list: [],
    };
  }

  componentDidMount() {
      apiCall(
      METHOD.QUERY,
      refernceSnippetList(getUserID(), 0, 100, 0),
      (data) => this.handleSnippetResponse(data),
      (isLoading) => { },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleSnippetResponse = (data) => {
    this.setState((prevState) => {
      return {
        snippet_list: [...prevState.snippet_list, ...data.snippet_list],
      };
    });
  };

  onPageChange = (event) => {
    const index = event.target.value;
    this.props.setEntity(this.state.snippet_list[index]);
  };

  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
      <select className="toolbar__input " onChange={this.onPageChange}>
              <option className="white-text-color">{t("GeneralMessages.snippetSelectReference")}</option>

        {this.state.snippet_list.map(
          ({ snippet_type_name, name, id }, index) => (
            <option className="reference-text-color" key={index} value={index}>
              {name}
            </option>
          )
        )}
            </select>
          );
        }}
      </Translation>
    );
  }
}

const entityInputs = {
  LINK: LinkInput,
  INTERNAL_PAGE_LINK: ReferenceInput,
};

// const myDecorator = new DraftJS.CompositeDecorator([
//   {
//     strategy: createTypeStrategy("INTERNAL_PAGE_LINK"),
//     component: SnippetReferenceComponent,
//   },
// ]);

const styleMap = {
  INTERNAL_PAGE_LINK: {
    textDecoration: "line-through",
  },
};

const editor_actions = [
  { type: "inline", label: "B", style: "BOLD", icon: icons.BoldIcon },
  { type: "inline", label: "I", style: "ITALIC", icon: icons.ItalicIcon },
  // these actions correspond with the entityInputs above
  {
    type: "entity",
    label: "Page Link",
    style: "link",
    entity: "INTERNAL_PAGE_LINK",
    icon: icons.LinkIcon,
  },

  { type: "separator" },
  {
    type: "block",
    label: "UL",
    style: "unordered-list-item",
    icon: icons.ULIcon,
  },
  {
    type: "block",
    label: "OL",
    style: "ordered-list-item",
    icon: icons.OLIcon,
  },
  { type: "block", label: "H2", style: "header-two", icon: icons.H2Icon },
  {
    type: "block",
    label: "QT",
    style: "blockquote",
    icon: icons.BlockQuoteIcon,
  },
];

/*
 * Here we define the output for the server
 * */
// const entityInputStyles = {
//   LINK: (data) => {
//     return {
//       element: "a",
//       attributes: {
//         href: data.url,
//       },
//     };
//   },
//   INTERNAL_PAGE_LINK: (data) => {
//     return {
//       element: "reference",
//       attributes: {
//         id: data.id,
//         placeholder: data.name,
//         type: data.snippet_type_identifier,
//       },
//     };
//   },
// };



class ConditionalDialogue extends Component {
 constructor(props) {
   super(props)
 
   this.state = {
     refChildNodes: [],
     editorState: editorStateFromRaw(null),
   }
   this.getAlert = this.getAlert.bind(this);
 }
 

  focus = (index) => {
    this[`editor${index}_ref`].focus()
  };

componentDidMount() {
  let element = document.getElementById("editor0")
  // var megadraft = document.querySelector("#megadraft-editor");
  let self = this
  element.addEventListener('click',function(e){
  self[`editor0_ref`] && self[`editor0_ref`].focus()
 });
  // element.onclick = function () { this.focus(); };

  this.props.setClick(this.getAlert);
}
getAlert(index) {
  let element = document.getElementById(`editor${index}`)
  // var megadraft = document.querySelector("#megadraft-editor");
  let self = this
  element.addEventListener('click',function(e){
    self[`editor${index}_ref`] && self[`editor${index}_ref`].focus()
 });
}

  render() {
    const { option_list, handleConditionalDynamicOption, handleOnEditorStateChange, conditional_editor_content, handleDeleteConditionalOption,  addConditionalDynamicOption} = this.props
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <h4>{t("AdminSetteliteSideMenu.option")}</h4>

              <div className="form-group" >
                <label className="font-14">
                  {t("AdminSnippetSlectiontype.condition")}
                </label>

                <React.Fragment>
                  {option_list && option_list.map((option, index) => (
                    <div className="mb-2" key={index} style={{ padding: "4px", background:"#e7e6ed",borderRadius:"6px"}}>
                    <div className="form-row">
                      <div className="col-10">
                        <span >
                          <input
                            type="text"
                            className="form-control mb-2"
                            onChange={handleConditionalDynamicOption(index)}
                              value={option}
                              placeholder={t("AdminSnippetSlectiontype.condition") + (index+1)}
                          />
                        </span>
                      </div>
                      <div className="col-2">
                          {index != 0 && <i className="zmdi zmdi-close zmdi-hc-fw font-24  pointer"
                            onClick={handleDeleteConditionalOption(index)}></i>}
                        </div>
                      </div>
                      <div
                        className="icon-box-style1 box-left-line-yellow mb-2"
                        
                      >
                      <div className="pl-3 d-flex">
                        <div className="w-100">

                            <MegadraftEditor
                              id={`editor${index}`}
                              editorState={conditional_editor_content[index]}
                              customStyleMap={styleMap}
                              onChange={handleOnEditorStateChange(index)}
                              actions={editor_actions}
                              placeholder={t("AdminSnippetSlectiontype.contentPlaceholder") + (index + 1)}
                              entityInputs={entityInputs}
                              ref={el => { this[`editor${index}_ref`] = el }}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="rounded-40 mt-2 rounded-red float-center pointer lh-36" onClick={addConditionalDynamicOption}>
                    <i className="zmdi zmdi-plus zmdi-hc-fw font-22"></i>
                  </div>
                </React.Fragment>

                <ul className="list-unstyled mb-0 mt-3">
                 

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.hide_on_zero")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(
                              e,
                              "hide_on_zero"
                            )
                          }
                          checked={this.props.hide_on_zero}
                        />
                      </div>
                    </div>
                  </li>

                </ul>

              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(ConditionalDialogue));

 
