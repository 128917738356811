/* eslint eqeqeq: 0 */
import React, { useState } from "react";
import { Translation, withTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import loadMoreSnippetSelector from "./loadMoreSnippetSelector";

function SnippetAsyncSelector(props) {
  const [value, onChange] = useState(
    props.snippet_id != 0 && props.snippet_name
      ? { value: props.snippet_id, label: props.snippet_name }
      : null
  );
  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    return bottomBorder < scrollTop;
  };

  const onChangeValue = (value) => {
    props.getSnippetID(value);
  };

  return (
    <Translation ns="translation">
      {(t, { i18n }) => {
        return (
          <AsyncPaginate
            value={value}
            loadOptions={loadMoreSnippetSelector}
            placeholder={t("manageDefaultSnippet.snippetTitle")}
            onChange={(value) => {
              onChange();
              onChangeValue(value);
            }}
            shouldLoadMore={shouldLoadMore}
          />
        );
      }}
    </Translation>
  );
}

export default withTranslation()(SnippetAsyncSelector);
