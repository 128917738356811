/* eslint eqeqeq: 0 */
import { snippetlistBaseOnSnippetType } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";

// const sleep = (ms) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, ms);
//   });

const loadOptions = async (search, prevOptions) => {
  let hasMore = true;
  let options = [];
  let p = new Promise((resolver, reject) =>
    apiCall(
      METHOD.QUERY,
      snippetlistBaseOnSnippetType(
        getUserID(),
        prevOptions?.length ? prevOptions.length : 0,
        10,
        1
      ),
      (data) => {
        if (data.is_last == 1) {
          hasMore = false;
        }
        let temp_snippet_list = data.snippet_list;
        temp_snippet_list.map((d) =>
          options.push({ value: d.id, label: d.name })
        );

        resolver(options);
      },
      (isLoading) => {},
      (data) => {}
    )
  );
  
  let data = await p.then((options) => options);
console.log("data",data)
  return {
    options: options,
    hasMore,
  };
};

export default loadOptions;
