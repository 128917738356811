/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { GroupDetailPersonListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import NoRecordFound from "../CommanComponents/NoRecordFound";
import GroupDetails from "../GroupDetail/GroupDetails";
import PersonItem from "./PersonItem";
import PersonListSetteliteSideMenu from "./PersonListSetteliteSideMenu";

class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeIndex: 0,
      headerMenuStatus: false,
      hasMore: true,
      end: 10,
      child_group_id: 0,
      start: 0,
      sortType: 1,
      child_group_list: [],
      personListPermission:false
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const personListPermission = checkUserPermission("read_person");


    const child_id = this.props.match.params.group_id;
    this.setState({
      child_group_id: child_id,
      personListPermission
    });
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        groupList: data.group_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeGroup = (group_id, person) => {
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + group_id);
    } else {
      this.props.history.push("/group-personlist/" + group_id);
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearStateGroupPersonDetail();
      }
    );
  };

  loadItem = () => {
    const child_id = this.props.match.params.group_id;
    const { end, isLoading, sortType } = this.state;

    if (!isLoading && this.props.groupDetailPersonList.hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupDetailPersonListQuery(
          getUserID(),
          child_id,
          this.props.groupDetailPersonList.start,
          end,
          sortType
        ),
        (data) => {
          this.setState({ child_group_list: data.group_list });
          if (this.props.groupDetailPersonList.id) {
            this.props.updatePersonList(data);
          } else {
            this.props.getPersonList(data);
          }

          this.handleGroupDetailApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  loader = (<LoadingPage key={0} />);

  handleGroupDetailApiResponse = (data) => {
    const { end } = this.state;
    this.setState({
      isLoading: true,
    });

    if (data.is_last == 1) {
      this.props.setPersonListHasMoreValue(false);

      this.setState(() => {
        return {
          ...this.state,
          hasMore: false,
        };
      });
    }

    this.props.setPersonListStartValue(
      this.props.groupDetailPersonList.start + end
    );
    this.setState((prevState) => {
      return {
        isLoading: false,
        start: prevState.start + end,
      };
    });
  };

  handleActiveIndex = (group_id, id) => {
    if (group_id == id) {
      this.handleScroll(group_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.groupDetailPersonList.group_list.findIndex(
      (group) => group.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  render() {
    const { end, sortType, isLoading, child_group_list,personListPermission } = this.state;
    const {
      name,
      group_type_icon,
      person_list,
      breadcrumb,
      person,
    } = this.props.groupDetailPersonList && this.props.groupDetailPersonList;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
             <GroupDetails
                group_header_list={child_group_list}
                current_id={this.props.groupDetailPersonList.id}
                name={name}
              />
              <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                  <div className="icon-box-style2 mb-4">
                    <div className="icon-box blue-bg d-flex">
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="rounded-50 bg-light">
                          <img
                            src={`data:image/png;base64,${
                              group_type_icon && group_type_icon
                            }`}
                            style={{ width: "32px" }}
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 my-3 d-inline-block">
                      <h5 className="m-0 font-18">{name && name}</h5>
                      <p className="text-muted font-12 mb-0">
                        {person && person}{" "}
                        {person && person == 1
                          ? t("GroupListScreen.singularPerson")
                          : t("GroupListScreen.pluralPerson")}
                      </p>
                      {/* <p className="text-muted font-12 mb-0">
                        {group_creator_name && group_creator_name}
                      </p> */}
                    </div>
                  </div>

                  <div className="d-block flex-fill">
                    <div className="ml-2 my-2 mb-3 d-flex">
                      <div className="d-flex w-100">
                        <div className="clearfix d-flex0 0align-items-center">
                          <p className="font-12 m-0">
                            <React.Fragment>
                              <Link
                                to="/group-list"
                                className="text-muted"
                                data-tip={"Start"}
                              >
                                {t("GroupListScreen.start")}
                                <ReactTooltip
                                  place="top"
                                  type="info"
                                  effect="float"
                                />
                              </Link>
                              {" > "}
                            </React.Fragment>
                            {breadcrumb &&
                              breadcrumb.map((item, index) => {
                                if (index == 0 && breadcrumb.length != 1) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={"/group-detail/" + item.id}
                                        className="text-muted"
                                        data-tip={item.name}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        {item.name}
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index != 0 &&
                                  index != breadcrumb.length - 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      <Link
                                        to={"/group-detail/" + item.id}
                                        className="text-muted"
                                        data-tip={item.name}
                                      >
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="float"
                                        />
                                        [...]
                                      </Link>
                                      {" > "}
                                    </React.Fragment>
                                  );
                                } else if (
                                  index == breadcrumb.length - 1 ||
                                  breadcrumb.length == 1
                                ) {
                                  return (
                                    <React.Fragment key={index}>
                                      {name && name}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="clearfix sorting-filter">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupDetailPersonListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          className={
                            sortType == 1
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                          onClick={() => this.changeSortType(1)}
                        >
                          {t("GroupDetailPersonListScreen.a-z")}
                        </button>

                        <button
                          type="button"
                          className={
                            sortType == 3
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                          onClick={() => this.changeSortType(3)}
                        >
                          {t("GroupDetailPersonListScreen.ranking")}
                        </button>

                        <button
                          type="button"
                          className={
                            sortType == 2
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                          onClick={() => this.changeSortType(2)}
                        >
                          {t("GroupDetailPersonListScreen.KRISENKONTAKTE")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
             
             {personListPermission?(<InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={this.props.groupDetailPersonList.hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-4 mr-4">
                    {!!person_list.length &&
                      person_list.map((data, index) => {
                        return (
                          <PersonItem data={data} key={index} index={index} />
                        );
                      })}

                    {!isLoading && person_list.length == 0 && (
                      <NoRecordFound title="Person List" name="person list" />
                    )}
                  </div>
                </InfiniteScroll>
                ):(<span>{t("GeneralMessages.permissionError")}</span>)}
                
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>
              <PersonListSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeRouteStatus}
              />
              <Footer type="3" />
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupDetailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(PersonList)));
