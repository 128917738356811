import React from "react";
import { Translation, withTranslation } from "react-i18next";

 function LoadingPage() {
   return (
     <Translation ns="translation">
       {(t, { i18n }) => {
         return (
           <div className="d-flex justify-content-center">
             <div className="spinner-border text-info" role="status">
               <span className="sr-only"> {t("GeneralMessages.loading")}</span>
             </div>
           </div>
         );
       }}
     </Translation>
   );
}
export default withTranslation()(LoadingPage);