import moment from "moment-timezone";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
 class EventItem extends Component {

  eventItemClick = (id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/event/" + id,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };
  render() {
    const { data } = this.props;
    const {
      id,
      event_type_icon,
      name,
      event_creator_name,
      event_datetime,
      location,
    } = data;

    return (
      <div
        className="icon-box-style1 d-flex mb-2 pointer"
        onClick={() => this.eventItemClick(id)}
        key={this.props.index}
      >
        <div className="icon-box red-bg d-flex">
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="rounded-50 bg-light">
              <img
                src={`data:image/png;base64,${
                  event_type_icon && event_type_icon
                }`}
                alt="ico"
                style={{ width: "32px" }}
              />
            </div>
          </div>
        </div>
        <div className="ml-3 mt-2 d-inline-block mb-2">
          <h5 className="m-0 font-18">{name && name} </h5>
          <p className="text-muted font-12 mb-2">
            {event_creator_name && event_creator_name}
          </p>
          <div className="text-muted font-12 d-flex flex-wrap">
            <span className="mr-3">
              <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-16" style={{ verticalAlign: '-1px' }}></i>
            
              {event_datetime &&
                moment
                  .unix(event_datetime)
                  .tz(moment.tz.guess())
                  .format("DD.MM.YYYY")}
            </span>
            <span className="mr-3">
              <i className="zmdi zmdi-time zmdi-hc-fw font-16" style={{ verticalAlign: '-1px' }}></i>{" "}
              {event_datetime &&
                moment
                  .unix(event_datetime)
                  .tz(moment.tz.guess())
                  .format("HH:mm")}
            </span>
            <span className="mr-3">
              <i className="zmdi zmdi-pin zmdi-hc-fw font-16"></i>{" "}
              {location && location}
            </span>

          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(EventItem);