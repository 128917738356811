/* eslint eqeqeq: 0 */
import moment from "moment-timezone";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ReportListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import NoRecordFoundTemplate2 from "../CommanComponents/NoRecordFoundTemplate2";
import TopHeader from "../CommanComponents/TopHeader";
import ReportSetteliteSideMenu from "./ReportSetteliteSideMenu";
class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      eventList: [],
      range: 0,
      is_child_array_equal: true,
      headerMenuStatus: false,
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const event_id = this.props.match.params.event_id;
    const readReportList = checkUserPermission("read_report");
   
   if(readReportList){
    apiCall(
      METHOD.QUERY,
      ReportListQuery(getUserID(), event_id),
      (data) => {
        this.props.getReportList(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
   }else{
    toast.info(this.props.t("GeneralMessages.permissionError"));
   }
    
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        eventList: data.event_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeEvent = (event_id) => {
    this.setState({ scrollset: false });
    let pathname = `/event/${event_id}`;
    this.props.history.push(pathname);
    const readReportList = checkUserPermission("read_report");
    if(readReportList){
      this.getReportList(event_id);
    }else{
    toast.info(this.props.t("GeneralMessages.permissionError"));
   }
  };
  getReportList = (eventID) => {
    apiCall(
      METHOD.QUERY,
      ReportListQuery(getUserID(), eventID),
      (data) => {
        this.props.getReportList(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  };
  handelReportClick = (event_id, report_id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: `${this.props.history.location.pathname}/report/${report_id}`,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  handleActiveIndex = (event_id, id) => {
    if (event_id == id) {
      this.handleScroll(event_id);
      return "active";
    } else {
      return "";
    }
  };
  handleScroll = (event_id) => {
    const index = this.props.reportList.event_list.findIndex(
      (event) => event.id == event_id
    );
    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${event_id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  render() {
    const {
      id,
      name,
      event_datetime,
      event_creator_name,
      event_type_icon,
      location,
      report_list,
      event_list,
    } = this.props.reportList;
    const event_id = id;
    const { isLoading } = this.state;

    return (
      <Translation ns="translation">
      {(t, { i18n }) => {
        return (
      <>
        <div className="header-bg header-red sab-header1 sticky-top d-flex flex-column">
          <div className="container d-flex flex-column flex-fill">
            <div className="text-center py-2 d-flex  align-items-center justify-content-between">
              <TopHeader />
              <p
                className="mb-0 ml-auto text-light pointer"
                onClick={this.handleBackStack}
              >
                {name && name}
              </p>
              <LogoutMenu />
            </div>
            <ul className="header-menu-tab-link mt-auto " id="ulID">
              {event_list &&
                event_list.map((data, index) => {
                  const { id, name } = data;

                  return (
                    <li
                      id={`li_${id}`}
                      key={index}
                      className={this.handleActiveIndex(event_id, id)}
                    >
                      <a
                        href="javascript;:"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleChangeEvent(id);
                        }}
                      >
                        {name && name}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="middle-section sab-middle-section1 left-line">
          <div className="container my-4 z-index-99">
            <div className="icon-box-style1 d-flex">
              <div className="icon-box red-bg d-flex">
                <div className="d-flex align-items-center justify-content-center w-100">
                  <div className="rounded-50 bg-light">
                    <img
                      src={`data:image/png;base64,${
                        event_type_icon && event_type_icon
                      }`}
                      alt="ico"
                      style={{ width: "32px", height: "32px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-3 mt-2 d-inline-block mb-2">
                <h5 className="m-0 font-18">{name && name}</h5>
                <p className="text-muted font-12 mb-2">
                  {event_creator_name && event_creator_name}
                </p>
                <div className="text-muted font-12 d-flex flex-wrap">
                  <span className="mr-3">
                    <i
                      className="zmdi zmdi-calendar-note zmdi-hc-fw font-16"
                      style={{ verticalAlign: "-1px" }}
                    ></i>{" "}
                    {event_datetime &&
                      moment
                        .unix(event_datetime)
                        .tz(moment.tz.guess())
                        .format("DD.MM.YYYY")}
                  </span>
                  <span className="mr-3">
                    <i
                      className="zmdi zmdi-time zmdi-hc-fw font-16"
                      style={{ verticalAlign: "-1px" }}
                    ></i>
                    {event_datetime &&
                      moment
                        .unix(event_datetime)
                        .tz(moment.tz.guess())
                        .format("HH:mm")}
                  </span>
                  <span className="mr-3">
                    <i className="zmdi zmdi-pin zmdi-hc-fw font-16"></i>
                    {location && location}
                  </span>
                </div>
              </div>
            </div>
            {!isLoading && report_list && report_list.length == 0 && (
              <NoRecordFoundTemplate2 title="Report List" />
            )}

            {report_list &&
              report_list.map((item, index) => {
                const {
                  id,
                  report_datetime,
                  report_type_icon,
                  title,
                  report_creator_name,
                } = item;
                return (
                  <a
                    href="javascript;:"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handelReportClick(event_id, id);
                    }}
                    key={index}
                    className="py-4 d-block before-line sprechblase"
                  >
                    <div className="sprechblase-bg float-left ml-5p pointer">
                      <img
                        src={`data:image/png;base64,${
                          report_type_icon && report_type_icon
                        }`}
                        alt="ico"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </div>
                    <div className="pl-3 pt-1 d-flex">
                      <div className="w-100">
                        <h6 className="m-0">{title && title}</h6>
                        <p className="text-muted font-12 mb-2">
                          {report_creator_name && report_creator_name}
                        </p>
                        <div className="text-muted font-12">
                          <span className="mr-3">
                            <i className="zmdi zmdi-calendar-note zmdi-hc-fw font-16"></i>{" "}
                            {report_datetime &&
                              moment
                                .unix(report_datetime)
                                .tz(moment.tz.guess())
                                .format("DD.MM.YYYY")}
                          </span>
                          <span className="mr-3">
                            <i className="zmdi zmdi-time zmdi-hc-fw font-16"></i>{" "}
                            {report_datetime &&
                              moment
                                .unix(report_datetime)
                                .tz(moment.tz.guess())
                                .format("HH:mm")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
          </div>
          <ReportSetteliteSideMenu
            closeSlider={this.closeSlider}
            headerMenuStatus={this.state.headerMenuStatus}
            changeRouteStatus={this.props.changeRouteStatus}
            is_archive={this.props.reportList.is_archive}
          />

          <FabIcon color="rounded-red" openSlider={this.openSlider} />
        </div>
        <Footer type="2" />
      </>
        );
      }}
    </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.reporttListStateToProps,
        stateToProps.eventListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.reportListDispatchToProps,
    actions.eventListDispatchToProps,
    actions.editEventReportDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withTranslation()(withRouter(ReportList)));
