// TOKEN ACTIONS
export const ADD_TOKEN = "ADD_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const GET_INITIAL_TOKEN = "GET_INITIAL_TOKEN";

// API CALLS ACTIONS
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const STARTED = "STARTED";
export const DELETE = "DELETE";

//AUTH ACTIONS
export const LOGIN_REQUEEST = "LOGIN_REQUEEST";
export const LOGIN_DETAILS = "LOGIN_DETAILS";

// EVENTLIST
export const EVENT_LIST = "EVENT_LIST";
export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const EVENT_START_VALUE = "EVENT_START_VALUE";
export const EVENT_HASMORE_VALUE = "EVENT_HASMORE_VALUE";
export const DELETE_EVENT = "DELETE_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const TOTAL_EVENT = "TOTAL_EVENT";

// REPORTLIST
export const REPORT_LIST = "REPORT_LIST";
export const UPDATE_REPORT_LIST = "UPDATE_REPORT_LIST";
export const EDIT_EVENT_REPORT = "EDIT_EVENT_REPORT";

// Group List
export const ADD_NEW_GROUP = "ADD_NEW_GROUP";
export const CLEAR_GROUP = "CLEAR_GROUP";
export const UPDATE_GROUP_BY_ID = "UPDATE_GROUP_BY_ID";
export const GROUP_LIST = "GROUP_LIST";
export const GROUP_HASMORE_VALUE = "GROUP_HASMORE_VALUE";
export const GROUP_START_VALUE = "GROUP_START_VALUE";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_PERSON_DISTRIBUTOR = "DELETE_PERSON_DISTRIBUTOR";

export const MAILING_LIST = "MAILING_LIST";
export const MAILING_DETAILS = "MAILING_DETAILS";
export const MAILING_HASMORE_VALUE = "MAILING_HASMORE_VALUE";
export const MAILING_START_VALUE = "MAILING_START_VALUE";
export const ADD_NEW_MAIL = "ADD_NEW_MAIL";
export const ADD_SUB_NEW_MAIL = "ADD_SUB_NEW_MAIL";
export const UPDATE_PERSON_COUNT = "UPDATE_PERSON_COUNT";

export const MAIL_PERSONLIST_START_VALUE = "MAIL_PERSONLIST_START_VALUE";
export const MAIL_PERSONLIST_HASMORE_VALUE = "MAIL_PERSONLIST_HASMORE_VALUE";
export const EDIT_MAIL_PERSONLIST = "EDIT_MAIL_PERSONLIST";
export const DELETE_MAIL_PERSONLIST = "DELETE_MAIL_PERSONLIST";
export const MAIL_PERSONLIST = "MAIL_PERSONLIST";
export const DELETE_PERSON = "DELETE_PERSON";
export const ADD_GENERAL_PERSON = "ADD_GENERAL_PERSON";
export const ADD_NEW_PERSON = "ADD_NEW_PERSON";

export const GROUP_DETAIL = "GROUP_DETAIL";
export const GROUP_DETAIL_HASMORE = "GROUP_DETAIL_HASMORE";
export const GROUP_DETAIL_START = "GROUP_DETAIL_START";
export const UPDATE_GROUP_LIST = "UPDATE_GROUP_LIST";
export const UPDATE_GROUP_DETAILS = "UPDATE_GROUP_DETAILS";
export const UPDATE_GROUP_DETAIL_BY_ID = "UPDATE_GROUP_DETAIL_BY_ID";
export const UPDATE_PERSON_DETAILS = "UPDATE_PERSON_DETAILS";
export const GROUP_DETAIL_CLEARSTATE = "GROUP_DETAIL_CLEARSTATE";
export const GROUP_DETAIL_DELETE = "GROUP_DETAIL_DELETE";

export const GROUP_PERSONLIST = "GROUP_PERSONLIST";
export const GROUP_PERSONLIST_HASMORE = "GROUP_PERSONLIST_HASMORE";
export const GROUP_PERSONLIST_START = "GROUP_PERSONLIST_START";
export const UPDATE_PERSON_LIST = "UPDATE_PERSON_LIST";
export const GROUP_PERSONLIST_CLEARSTATE = "GROUP_DETAIL_CLEARSTATE";
export const ADD_NEW_PERSONLIST = "ADD_NEW_PERSONLIST";


export const GROUP_PERSON_DETAIL = "GROUP_PERSON_DETAIL";

export const CHANNEL_LIST = "CHANNEL_LIST";
export const CHANNEL_DETAIL = "CHANNEL_DETAIL";
export const UPDATE_MESSAEG_LINK = "UPDATE_MESSAEG_LINK";

export const UPDATE_CHANNEL_DETAIL = "UPDATE_CHANNEL_DETAIL";
export const UPDATE_CHANNEL_LIST = "UPDATE_CHANNEL_LIST";
export const REARRANGE_GROUP_PERSON_LIST = "REARRANGE_GROUP_PERSON_LIST";
export const UPDATE_CHANNEL_SENDING_STATUS = "UPDATE_CHANNEL_SENDING_STATUS";


export const SIDE_MENU = "SIDE_MENU";
export const SNIPPET_LIST = "SNIPPET_LIST";

