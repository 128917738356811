import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { checkUserPermission } from "../../util/utils";

class HomeSetteliteSideMenu extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       createEventPermission:false
    }
  }
  componentDidMount() {
    const event_create_permission = checkUserPermission("create_event");
    this.setState({
      event_create_permission
    })
  }
  render() {
    const { status, closeSlider } = this.props;

    const {event_create_permission} = this.state
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={status}>
              <div
                className={
                  status
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  {event_create_permission &&  <li className="nav-item">
                    <a
                      href="javascript;:"
                      onClick={e => {
                        e.preventDefault();
                        this.props.changeStatus(1);
                      }}
                      className="float-right mr-3"
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("HomeScreen.newEvent")}
                      </p>
                      <div className="float-left rounded-40 red-bg text-center">
                        <img
                          className="m-0"
                          src="images/brand-icon-w.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>}
                 
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(HomeSetteliteSideMenu);
