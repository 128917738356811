/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePersonMailQuery,
  getmailinglistgroup
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import PersonListSetteliteSideMenu from "../MailDetailsPersonList/PersonListSetteliteSideMenu";
import GroupItem from "./GroupItem";

class PersonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      list_id: 0,
      mail_id: 0,
      data: { person_list: [], group_list: [] },
      searchValue: "",
    };
  }

  handleBackStack = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const mail_id = fields[1];

    let { from } = this.props.location.state || {
      from: { pathname: "/mail-detail/" + mail_id },
    };
    this.props.history.push(from);
  };
  openSlider = () => {
    this.setState(() => {
      return {
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      this.handleScroll(mail_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.state.header_list.findIndex((mail) => mail.id == id);

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };

  componentDidMount() {
    const readListPermission  = checkUserPermission("read_list");
    if(readListPermission){
      const mail_list_id = this.props.match.params.mail_person_id;
      const fields = mail_list_id.split("-");
      const list_id = fields[0];
      const mail_id = fields[1];
  
      this.setState({
        list_id: list_id,
        mail_id: mail_id,
      });
      apiCall(
        METHOD.QUERY,
        getmailinglistgroup(getUserID(), list_id),
        (data) => {
          this.props.storeApiData(data);
          this.setState({
            header_list: data.mailing_list,
            group_list: data.group_list,
            data,
          });
          // this.props.getMailDetails(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  }

  handleAfterApiCall = (data) => {
    this.setState(() => {
      return {
        ...this.state,
        mailList: data.mailing_list,
      };
    });
  };

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (id) => {
    this.setState(
      {
        list_id: id,
      },
      () => {
        const { mail_id } = this.state;
        this.props.history.push(
          "/mail-details-personlist/" + id + "-" + mail_id
        );
        this.loadPersonList(id);
      }
    );
  };

  loadPersonList = (mail_id) => {
    apiCall(
      METHOD.QUERY,
      getmailinglistgroup(getUserID(), mail_id),
      (data) => {
        this.props.storeApiData(data);
        this.setState({
          header_list: data.mailing_list,
          group_list: data.group_list,
          data,
        });

        
        // this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleDelete = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this person.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deletePersonMailQuery(getUserID(), this.state.list_id, item.id),
              (data) => {
                toast.success(this.props.t(data.message));
                let person_count = this.props.mailPersonList.person;
                person_count = person_count - 1;
                this.props.deletePersonFromPersonList(item);
                this.props.updatePersonCount(person_count);
              },
              (isLoading) => this.handleLoader(),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  updatePersonCount = (group_id) => {
    let temp_data = this.state.data;
    let group_list = temp_data.group_list;
    const index = group_list.findIndex((group) => group.id == group_id);
    group_list[index].person = group_list[index].person - 1;
    temp_data.group_list = group_list;
    this.setState({ data: temp_data });
  };

  delete_group_item = (id) => {
    let after_delete_group_list = this.state.group_list.filter((item) => {
      return item.id != id;
    });

    this.setState({
      group_list: after_delete_group_list,
    });
  };

  handleAlphabatice = (name, index, list) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    let resulAlpha = "";
    if (index == 0) {
      resulAlpha = name.charAt(0);
    } else {
      let oldName = list[index - 1].name;
      oldName = oldName.charAt(0).toUpperCase() + oldName.slice(1);
      const digit1 = oldName.charAt(0);
      const digit2 = name.charAt(0);

      if (digit1 != digit2) {
        resulAlpha = digit2;
      } else {
        resulAlpha = "";
      }
    }
    return resulAlpha;
  };
  render() {
    const {
      header_list,
      group_list,
      data,
    } = this.state;
    console.log("data in cdm ",data);
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-yellow sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={this.handleBackStack}
                    >
                      {data.mailing_name && data.mailing_name}
                    </p>
                    <LogoutMenu />
                  </div>

                  <ul className="header-menu-tab-link mt-auto" id="ulID">
                    {header_list &&
                      header_list.map((data, index) => {
                        return (
                          <li
                            id={`li_${data.mail_id}`}
                            key={index}
                            className={this.handleActiveIndex(
                              this.state.data.mailing_id,
                              data.mail_id
                            )}
                          >
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeMail(data.mail_id);
                              }}
                            >
                              {data.mail_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 custom-middle-section">
                <div className="container mt-4 z-index-99">
                  <div className="d-flex">
                    <div className="icon-box-style2 mb-3">
                      <div className="icon-box yellow-bg d-flex">
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="rounded-50 bg-light">
                            <img
                              src={`data:image/png;base64,${
                                data.list_type_icon && data.list_type_icon
                              }`}
                              alt="ico"
                              style={{ width: "32px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill">
                        <div className="align-self-center">
                          <h5 className="m-0 font-18 d-block">
                            {data.mailing_name && data.mailing_name}
                          </h5>
                          <p className="text-muted font-12 m-0">
                            <p className="text-muted font-12 m-0">
                              {data.total_person && data.total_person}{" "}
                              {data.total_person == 1
                                ? t("GroupListScreen.singularPerson")
                                : t("GroupListScreen.pluralPerson")}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {group_list &&
                    group_list.map((item, index) => {
                      return (
                        <GroupItem
                          delete_group_item={this.delete_group_item}
                          updatePersonCount={this.updatePersonCount}
                          key={index}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                </div>
                <PersonListSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeRouteStatus}
                />
                <FabIcon color="rounded-yellow" openSlider={this.openSlider} />
              </div>

              <Footer type="4" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.mailDetailStateToProps,
        stateToProps.mailPersonListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.mailPersonListDispatchToProps,
  ])
)(withTranslation()(withRouter(PersonList)));
