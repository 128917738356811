/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  addGeneralPersonToMaillingList,
  generalPersonsList
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";
import SearchGeneralPerson from "./SearchGeneralPerson";
class GeneralPersonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person_list: [],
      start: 0,
      end: 10,
      hasMore: true,
      isLoading: false,
      addPersonLoading: false,
      searchPersonList: [],
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loadPersonList = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        generalPersonsList(getUserID(), start, end, ""),
        (data) => this.handlePersonListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  setSearchArrayList = (arrayList) => {
    this.setState({
      searchPersonList: arrayList,
    });
  };

  handlePersonListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        person_list: [...prevState.person_list, ...data.person_list],
        isLoading: false,
      };
    });
  };

  handleAddClick = (person_id) => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    apiCall(
      METHOD.QUERY,
      addGeneralPersonToMaillingList(getUserID(), person_id, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
      },
      (isLoading) => {
        this.setState({
          addPersonLoading: true,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
    this.props.changeRouteStatus(0);
  };

  loader = (<LoadingPage key={0} />);

  render() {
    const {
      end,
      hasMore,
      person_list,
      addPersonLoading,
      searchPersonList,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-yellow sab-header1 sticky-top">
                <div className="container pb-2">
                  <div className="py-1 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>
                  </div>

                  <div className="text-center  text-light">
                    <h2 className="m-0 text-light">
                      {t("generalGroupList.addPerson")}
                    </h2>
                  </div>
                  <SearchGeneralPerson searchArray={this.setSearchArrayList} />
                </div>
              </div>
              <div className="middle-section sab-middle-section1">
                {searchPersonList.length != 0 ? (
                  <div className="container my-4 00z-index-99">
                    {searchPersonList &&
                      searchPersonList.map((item, index) => {
                        const { id, name } = item;
                        return (
                          <div className="form-group" key={index}>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <label className="font-14">{name && name}</label>

                              {addPersonLoading ? (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm btn-small disabled"
                                  onClick={() => this.handleAddClick(id)}
                                >
                                  <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                  <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                    <span className="sr-only">
                                      {t("GeneralMessages.loading")}
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm btn-small"
                                  onClick={() => this.handleAddClick(id)}
                                >
                                  <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadPersonList}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                    <div className="container my-4 00z-index-99">
                      {person_list &&
                        person_list.map((item, index) => {
                          const { id, name } = item;
                          return (
                            <div className="form-group" key={index}>
                              <div className="d-flex align-items-center justify-content-between mb-1">
                                <label className="font-14">
                                  {name && name}
                                </label>

                                {addPersonLoading ? (
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm btn-small disabled"
                                    onClick={() => this.handleAddClick(id)}
                                  >
                                    <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                    <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                      <span className="sr-only">
                                        {t("GeneralMessages.loading")}
                                      </span>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm btn-small"
                                    onClick={() => this.handleAddClick(id)}
                                  >
                                    <i className="zmdi zmdi-plus zmdi-hc-fw"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </InfiniteScroll>
                )}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(withRouter(GeneralPersonList));
