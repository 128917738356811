/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChannelDetails from "./ChannelDetails";
import ChannelMailLinkIndex from "./ChannelMailLinkIndex";
import EditNewChannelReport from "./EditNewChannelReport";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
    };
  }

  changeStatus = (type) => {
    this.setState({
      routeStatus: type,
    });
  };

  render() {
    const { routeStatus } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <ChannelDetails changeRouteStatus={this.changeStatus} />
        )}
        {routeStatus == 1 && (
          <ChannelMailLinkIndex changeRouteStatus={this.changeStatus} />
        )}
        {routeStatus == 2 && (
          <EditNewChannelReport changeRouteStatus={this.changeStatus} />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
