import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { personDetailQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import ContactPersonAddress from "./ContactPersonAddress";
import ContactPersonDistributor from "./ContactPersonDistributor";
import ContactPersonInfo from "./ContactPersonInfo";
import ContactPersonRemarks from "./ContactPersonRemarks";
import PersonDetailSetteliteSideMenu from "./PersonDetailSetteliteSideMenu";
import ContactPersonOtherInfo from "./ContactPersonOtherInfo";

class PersonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headerMenuStatus: false,
      person_id: 0,
      clickStatus: 0,
      group_id:0
    };
  }


  handleBackStack = () => {
    let { from } = this.props.location.state || { from: { pathname: "/group-list" } };
    this.props.history.push(from);
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false
      };
    });
  };

  componentDidMount() {

    const read_person_permission = checkUserPermission("read_person");

    if(read_person_permission){
      const group_person_id = this.props.match.params.group_person_id;
      const fields = group_person_id.split("-");
      const person_id = fields[1];

    this.setState({
      person_id: person_id,
    });

    apiCall(
      METHOD.QUERY,
      personDetailQuery(getUserID(), person_id),
      (data) => {
        this.props.getPersonDetail(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
    
  }

  handleTabEvent = (type) => {
     this.setState({
       clickStatus: type
     });
  }

  handleLoader = status => {
    this.setState( {
        isLoading: status
    });
  };

  loader = (<LoadingPage />);

  render() {
    const { full_name, title, company_name, department } =
      this.props.groupPersonDetail && this.props.groupPersonDetail;
    const { clickStatus } = this.state;
    
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light pointer" onClick={() => { this.handleBackStack()}}>
                      {t("PersonDetails.title")}
                    </p>
                    <LogoutMenu />
                  </div>

                  <div className="text-center mt-1 text-light">
                    <a href="#1" className="clearfix mb-3">
                      <div className="rounded-40 bg-light m-auto">
                        <i className="zmdi zmdi-account zmdi-hc-fw font-24 text-dark"></i>
                      </div>
                    </a>
                    <h2 className="m-0 text-light">{`${title && title}  ${full_name && full_name}`}</h2>
                    <p className="text-light m-0 font-13">
                      {company_name && company_name}
                    </p>
                    <p className="text-light m-0 font-13">
                      {department && department}
                    </p>
                  </div>
                </div>
              </div>
              <div className="middle-section">
                <div className="container my-4 z-index-99">
                  <ul className="menu-tab-link mb-4">
                    <li className={clickStatus === 0 ? "active" : ""}>
                      <div
                        className="pointer"
                        onClick={() => this.handleTabEvent(0)}
                      >
                        {t("PersonDetails.tab_title_contact")}
                      </div>
                    </li>
                    <li className={clickStatus === 1 ? "active" : ""}>
                      <div
                        className="pointer"
                        onClick={() => this.handleTabEvent(1)}
                      >
                        {t("PersonDetails.tab_title_address")}
                      </div>
                    </li>
                    <li className={clickStatus === 2 ? "active" : ""}>
                      <div
                        className="pointer"
                        onClick={() => this.handleTabEvent(2)}
                      >
                        {t("PersonDetails.tab_title_distributor")}
                      </div>
                    </li>
                    <li className={clickStatus === 3 ? "active" : ""}>
                      <div
                        className="pointer"
                        onClick={() => this.handleTabEvent(3)}
                      >
                        {t("PersonDetails.tab_title_remarks")}
                      </div>
                    </li>
                    <li className={clickStatus === 4 ? "active" : ""}>
                      <div
                        className="pointer"
                        onClick={() => this.handleTabEvent(4)}
                      >
                        {t("PersonDetails.tab_title_others")}
                      </div>
                    </li>
                  </ul>

                  {clickStatus === 0 && (
                    <ContactPersonInfo data={this.props.groupPersonDetail} />
                  )}
                  {clickStatus === 1 && (
                    <ContactPersonAddress data={this.props.groupPersonDetail} />
                  )}
                  {clickStatus === 2 && (
                    <ContactPersonDistributor
                      data={this.props.groupPersonDetail}
                    />
                  )}
                  {clickStatus === 3 && (
                    <ContactPersonRemarks data={this.props.groupPersonDetail} />
                  )}
                  {clickStatus === 4 && (
                    <ContactPersonOtherInfo data={this.props.groupPersonDetail} />
                  )}
                </div>

                <PersonDetailSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeRouteStatus}
                />
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>

              <Footer type="3" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withTranslation() (withRouter(PersonDetail)));
