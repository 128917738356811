import {
  REPORT_LIST,
  EDIT_EVENT_REPORT,
  UPDATE_REPORT_LIST
} from "../constants/action-types";

export const getReportList = data => ({
  type: REPORT_LIST,
  payload: data
});
export const editEventReport = data => ({
  type: EDIT_EVENT_REPORT,
  payload: data
});
export const updateReportlList = data => ({
  type: UPDATE_REPORT_LIST,
  payload: data
});