/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { toast } from "react-toastify";
import { SnippetDataQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID, stringToHTML } from "../../util/utils";

class ConditionDialogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      nestedSnippetData: {},
      content: {},
      allow_zero: false,
      options: [],
      value: [],
      option_raw_value: [],
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  componentDidMount() {
    const { id } = this.props;
    // alert(id)
    apiCall(
      METHOD.QUERY,
      SnippetDataQuery(id,getUserID()),
      (data) => {
        let contentObject = JSON.parse(data.content);
        let hide_on_zero = JSON.parse(contentObject.hide_on_zero);

        let optionWithValue = JSON.parse(JSON.parse(contentObject.options));

        let option_raw_value = contentObject.raw_value;
        let options = [];
        let value = [];
        for (var key in optionWithValue) {
          options.push(key);
          value.push(optionWithValue[key]);
        }

        this.setState({
          nestedSnippetData: contentObject,
          content: contentObject.content,
          hide_on_zero,
          options,
          value,
          option_raw_value,
          raw_value: JSON.parse(JSON.parse(data.raw_value)),
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.checked) {
      this.setState((prev) => ({
        checkOption: [...prev.checkOption, value],
      }));
    } else {
      this.setState((prev) => ({
        checkOption: prev.checkOption.filter((d) => d != value),
      }));
    }
  };

  mergeRootAndReferenceRawValue = (raw_value, refrence_rawValue) => {
    let rawValue = raw_value;
    let referenceRawValue = refrence_rawValue;
    let rawValueBlocks = rawValue.blocks;
    let rawValueEntityMap = rawValue.entityMap;
    let refernceEntityMap = referenceRawValue.entityMap;
    let referenceRawValueBlocks = referenceRawValue.blocks;
    let valuetagBlockIndex;
    let _0blockText = referenceRawValueBlocks[0].text;
    let _0blockEntityRange = referenceRawValueBlocks[0].entityRanges;
    let _0blockInlineStyleRanges = referenceRawValueBlocks[0].inlineStyleRanges;
    let _newBlocks = [];
    let value_position;
    let totalTextLength;
    let value_position_end;
    let textAfterValueTag;
    let removeTextafterValuetag;
    let t_entityMap = [];
    let tempInStyleRanges = [];
    let tempEntityRanges = [];

    // purani entity ne t_entityMap ma store karo
    for (let i in rawValueEntityMap) {
      t_entityMap = [...t_entityMap, rawValueEntityMap[i]];
    }

     for (let i in rawValueBlocks) {
      let _block = rawValueBlocks[i];
      let _blockEntityRange = _block.entityRanges;
      let _blockInlineStyleRanges = _block.inlineStyleRanges;
      let text = _block.text;
      totalTextLength = text.length;
      value_position = text.indexOf("<value>");

      if (value_position != -1) {
        valuetagBlockIndex = i;
        value_position_end = value_position + 7;
        textAfterValueTag = text.slice(value_position_end, totalTextLength);
        //removeTextafterValuetag = text.replace(textAfterValueTag,'')
        removeTextafterValuetag = text.slice(0, value_position_end);

        for (let p in rawValueBlocks) {
          let _tempBlock = rawValueBlocks[p];
          if (p < valuetagBlockIndex) {
            _newBlocks.push(_tempBlock);
          }
        }
        // setup 0 position block
        let _new_entityRange = [];
        let _newInlineStyle = [];
        text = removeTextafterValuetag.replace("<value>", _0blockText);

        _block.text = text;

        //  --- Start --- update entity ranges at 0 potion
        for (let k in _blockEntityRange) {
          if (_blockEntityRange[k].offset < value_position) {
            _new_entityRange.push(_blockEntityRange[k]);
          } else {
            tempEntityRanges.push(_blockEntityRange[k]);
          }
        }

        for (let m in _0blockEntityRange) {
          let entityItem = _0blockEntityRange[m];
          const key = entityItem.key;
          let offset = entityItem.offset;
          t_entityMap = [...t_entityMap, refernceEntityMap[key]];
          entityItem.key = t_entityMap.length - 1;
          entityItem.offset = offset + value_position;
          _new_entityRange.push(entityItem);
        }
        _block.entityRanges = _new_entityRange;

        // -- start update style at 0 potion

        for (let l in _blockInlineStyleRanges) {
          let style = _blockInlineStyleRanges[l];
          if (_blockInlineStyleRanges[l].offset < value_position) {
            _newInlineStyle.push(style);
          } else {
            tempInStyleRanges.push(style);
          }
        }

        for (let o in _0blockInlineStyleRanges) {
          let style = _0blockInlineStyleRanges[o];
          let offset = style.offset;
          style.offset = offset + value_position;
          _newInlineStyle.push(style);
        }

        _block.inlineStyleRanges = _newInlineStyle;
        rawValueBlocks[i] = _block;
        _newBlocks.push(_block);
      }
    }

    // ----------------------- add middle blocks of reference option value--------- Only last block will remain----

    for (let i in referenceRawValueBlocks) {
      if (
        i != 0 &&
        i != referenceRawValueBlocks.length - 1 &&
        referenceRawValueBlocks.length != 1
      ) {
        let block = referenceRawValueBlocks[i];
        let key_value = new Date().getTime() + Math.random() + i;
        // key_value = parseInt(key_value)
        block.key = key_value;
        let tempEntityRange = block.entityRanges;
        for (let itempEntityRange in tempEntityRange) {
          let range = tempEntityRange[itempEntityRange];
          const key = range.key;
          t_entityMap = [...t_entityMap, refernceEntityMap[key]];
          range.key = t_entityMap.length - 1;
          tempEntityRange[itempEntityRange] = range;
        }
        block.entityRanges = tempEntityRange;
        referenceRawValueBlocks[i] = block;
        _newBlocks.push(block);
      }
    }
    /* ***** 0 index and last index vagar na  reference block add karva entitymap and entityrange add karva */

    if (
      referenceRawValueBlocks.length - 1 != 0 &&
      referenceRawValueBlocks.length != 1 &&
      referenceRawValueBlocks.length != 1
    ) {
      let last_reference_block =
        referenceRawValueBlocks[referenceRawValueBlocks.length - 1];
      let last_reference_text = last_reference_block.text;
      const last_reference_text_length = last_reference_text.length;

      last_reference_text = last_reference_text + textAfterValueTag;
      last_reference_block.text = last_reference_text;
      let _last_new_inlineStyleRanges = [];
      for (let m in tempInStyleRanges) {
        // include varu check karvanu baki che bold
        if (tempInStyleRanges[m].offset > value_position) {
          let offset = tempInStyleRanges[m].offset;
          offset = offset + last_reference_text_length - 7 - value_position;
          tempInStyleRanges[m].offset = offset;
          _last_new_inlineStyleRanges.push(tempInStyleRanges[m]);
        }
      }
      _last_new_inlineStyleRanges = _last_new_inlineStyleRanges.concat(
        last_reference_block.inlineStyleRanges
      );
      last_reference_block.inlineStyleRanges = _last_new_inlineStyleRanges;

      /**************Start******************Update entity ranges and entity map of last block ******************/
      let main_block_entityRanges = tempEntityRanges;
      let last_block_entityRanges = [];
      main_block_entityRanges = main_block_entityRanges.map((item, i) => {
        let _blockEntityRange = rawValueBlocks[valuetagBlockIndex].entityRanges;
        if (item.offset > value_position) {
          let new_entityRange = this.removeItem(_blockEntityRange, item);
          let offset = item.offset;

          item = {
            ...item,
            offset: offset + last_reference_text_length - 7 - value_position,
          };
          _newBlocks[valuetagBlockIndex].entityRanges = new_entityRange;
          last_block_entityRanges.push(item);
        }
      });
      console.log(main_block_entityRanges)
      let tempEntityRange = last_reference_block.entityRanges;
      for (let itempEntityRange in tempEntityRange) {
        let range = tempEntityRange[itempEntityRange];
        const key = range.key;
        t_entityMap = [...t_entityMap, refernceEntityMap[key]];
        range.key = t_entityMap.length - 1;
        tempEntityRange[itempEntityRange] = range;
      }

      last_block_entityRanges = [
        ...last_block_entityRanges,
        ...tempEntityRange,
      ];

      last_reference_block.entityRanges = last_block_entityRanges;
      let key_value = new Date().getTime() + Math.random();
      // key_value = parseInt(key_value);
      last_reference_block.key = key_value;
      referenceRawValueBlocks[
        referenceRawValueBlocks.length - 1
      ] = last_reference_block;
      _newBlocks.push(last_reference_block);
    }
    /**************END**********************Update text and style**********END*******************/

    t_entityMap = { ...t_entityMap };
    rawValue.entityMap = t_entityMap;

    for (let index = 0; index < rawValueBlocks.length; index++) {
      if (index > valuetagBlockIndex) {
        const element = rawValueBlocks[index];
        _newBlocks.push(element);
      }
    }
    rawValue.blocks = _newBlocks;
    return rawValue;
  };

  removeItem = (arr, item) => {
    for (var i = arr.length; i--; ) {
      if (arr[i] === item) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  handleSubmit = (index) => {
    const {
      closeDialogue,
      id,
      setDynamicValueForMultiLine,
      setDynamicValueForMultiLineWithInnerSnippet,
      setFinalDynamicValueWithInnerSnippet,
      setDynamicValue
    } = this.props;
    const { content, value, raw_value, option_raw_value } = this.state;
    let snippet_raw_value = raw_value;
    let reference_raw_value = JSON.parse(option_raw_value[index]);
    let text = reference_raw_value.blocks[0].text;
    let value_tag_potion;
    console.log(value_tag_potion)
    let is_multiline = false;

    if (raw_value.blocks.length > 1) {
      is_multiline = true;
    }

    if (reference_raw_value.blocks.length > 1) {
      is_multiline = true;
    }

    let entityMap = raw_value.entityMap;

    let val = content;
    val = val.replace("<value>", value[index]);
    let val1 = raw_value.blocks[0].text;
    let value_tag_position = val1.search("<value>");
    val1 = val1.replace("<value>", text);

    if (is_multiline) {
      // find the value tag
      let t_entityMap = [];
      // purani entity ne t_entityMap ma store karo
      for (let i in entityMap) {
        t_entityMap = [...t_entityMap, entityMap[i]];
      }

      let finalRawValue = this.mergeRootAndReferenceRawValue(
        raw_value,
        reference_raw_value
      );
      let finalEntityMap = finalRawValue.entityMap;
      let length = Object.values(finalEntityMap).length;
      if (length > 0) {
        setDynamicValueForMultiLineWithInnerSnippet(id, finalRawValue);
      } else {
        setDynamicValueForMultiLine(id, finalRawValue);
      }
    } else {
      // let length = Object.values(entityMap).length
      // if(length > 0){
      // if (reference_raw_value.blocks[0].entityRanges.length > 0) {
      let mainBlockEntityRanges = snippet_raw_value.blocks[0].entityRanges;
      let reference_entityRanges = reference_raw_value.blocks[0].entityRanges;
      let newEntityRanges = [];

      let referenceInlineStyleRanges =
        reference_raw_value.blocks[0].inlineStyleRanges;
      let mainBlockInlineStyleRanges =
        snippet_raw_value.blocks[0].inlineStyleRanges;
      let newInlineStyleRanges = [];

      let refernceEntityMap = reference_raw_value.entityMap;
      let rawValueEntityMap = snippet_raw_value.entityMap;
      let value_text = snippet_raw_value.blocks[0].text;
      let t_entityMap = [];

      for (let i in rawValueEntityMap) {
        t_entityMap = [...t_entityMap, rawValueEntityMap[i]];
      }
      value_tag_potion = value_text.search("<value>");
      value_text = value_text.replace("<value>", text);

      for (let k in mainBlockEntityRanges) {
        let entityRange = mainBlockEntityRanges[k];
        let offset = entityRange.offset;
        let newTextLength = text.length;
        if (offset > value_tag_position) {
          offset = offset + newTextLength - 7;
          entityRange.offset = offset;
          newEntityRanges.push(entityRange);
        } else {
          newEntityRanges.push(entityRange);
        }
      }

      for (let m in reference_entityRanges) {
        let _entityRanges = reference_entityRanges[m];
        let offset = _entityRanges.offset;
        let key = _entityRanges.key;
        offset = offset + value_tag_position;
        _entityRanges.offset = offset;
        t_entityMap = [...t_entityMap, refernceEntityMap[key]];
        _entityRanges.key = t_entityMap.length - 1;
        newEntityRanges.push(_entityRanges);
      }
      for (let n in mainBlockInlineStyleRanges) {
        let inlineStyleRange = mainBlockInlineStyleRanges[n];
        let offset = inlineStyleRange.offset;
        let newTextLength = text.length;
        if (offset > value_tag_position) {
          offset = offset + newTextLength - 7;
          inlineStyleRange.offset = offset;
          newInlineStyleRanges.push(inlineStyleRange);
        } else {
          newInlineStyleRanges.push(inlineStyleRange);
        }
      }

      for (let p in referenceInlineStyleRanges) {
        let _inlineStyleRanges = referenceInlineStyleRanges[p];
        let offset = _inlineStyleRanges.offset;
        offset = offset + value_tag_position;
        _inlineStyleRanges.offset = offset;
        newInlineStyleRanges.push(_inlineStyleRanges);
      }

      t_entityMap = { ...t_entityMap };
      snippet_raw_value.entityMap = t_entityMap;
      snippet_raw_value.blocks[0].inlineStyleRanges = newInlineStyleRanges;
      snippet_raw_value.blocks[0].entityRanges = newEntityRanges;
      snippet_raw_value.blocks[0].text = value_text;
      // }

      // let innerReference = stringToHTML(val, Date.now());
      // var children = innerReference.children;
      // let referenceInSpan = children[0];

      // referenceInSpan.onclick = (e) => {
      //   e.preventDefault();
      //   if (e.stopPropagation) e.stopPropagation();
      //   this.props.handleReferenceClick(referenceInSpan);
      // };
      // referenceInSpan.setAttribute("class", "reference-backgroupred");
      // referenceInSpan.innerHTML = referenceInSpan.getAttribute("placeholder");

      if (val.includes("<refe")) {
        let innerReference = stringToHTML(val, Date.now());
        var children = innerReference.children;
        let referenceInSpan = children[0];
  
        referenceInSpan.onclick = (e) => {
          e.preventDefault();
          if (e.stopPropagation) e.stopPropagation();
          this.props.handleReferenceClick(referenceInSpan);
        };
        referenceInSpan.setAttribute("class", "reference-backgroupred");
        referenceInSpan.innerHTML = referenceInSpan.getAttribute("placeholder");
        setFinalDynamicValueWithInnerSnippet(
          id,
          text,
          snippet_raw_value,
          value_tag_potion
        );
      } else {
        let val1 = raw_value.blocks[0].text;
        console.log("val1 before",reference_raw_value);
        let value_tag_position = val1.search("<value>")
        val1 = val1.replace("<value>", text);
        console.log("snippet_raw_value",reference_raw_value);
        setDynamicValue(id, val1, snippet_raw_value, value_tag_position, value[index]);
      }
  
    }
    closeDialogue();
  };
  cancelDialogue = () => {
    const { closeDialogue } = this.props;
    closeDialogue();
  };
  render() {
    const { nestedSnippetData, options } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom>
              <div
                className="navbar-collapse collapse text-left bottom-half show"
                id="Brand-toggler"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {nestedSnippetData.name && nestedSnippetData.name}
                    </h5>
                    <div className="d-flex ">
                      <button
                        type="button"
                        className="close"
                        onClick={this.cancelDialogue}
                        aria-label="Close"
                      >
                        <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="text-center">
                      <ul className="navbar-nav mr-auto">
                        {options &&
                          options.map((item, index) => {
                            return (
                              <li
                                className="nav-item pointer "
                                key={index}
                                onClick={() => {
                                  this.handleSubmit(index);
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "3px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {"- "}
                                  </span>
                                  <span>{item}</span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default withTranslation()(ConditionDialogue);
