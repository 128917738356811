import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";

class NoRecordFound extends Component {
  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div>
              <div className={this.props.viewBorder ? "card" : ""}>
                {this.props.showHeader && (
                  <div className="card-header">
                    <h5 className="m-0">
                      {this.props.title ? this.props.title : "Warning"}
                    </h5>
                  </div>
                )}
                <div className="card-body">
                  <div className="clearfix">
                    <div className="media d-flex align-items-center border-bottom-0 mb-0">
                      <div className="media-body">
                        <p className="mb-1">
                          {t("GeneralMessages.errorMessageLable1")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default (withTranslation()(NoRecordFound));  