export const loginDetailsStateToProps = state => {
  return { loginDetails: state.loginDetailReducer };
};

export const eventListStateToProps = state => {
  return { eventList: state.evntListReducer };
};

export const eventStartStateToProps = state => {
  return { start: state.evntListReducer };
};
export const eventHasMoreStateToProps = state => {
  return { hasMore: state.evntListReducer };
};

export const eventEventCountStateToProps = state => {
  return { total_event: state.evntListReducer };
};

export const mailListStateToProps = state => {
  return { mailList: state.mailReducer };
};

export const mailDetailStateToProps = state => {
  return { mailDetail: state.mailDetailReducer };
};

export const reporttListStateToProps = state => {
  return { reportList: state.reportListReducer };
};

export const asyncApiStateToProps = state => {
  return { apiState: state.apiReducer };
};

export const appMapStateToProps = (data, state) => {
  let tempObj = {};
  for(let i = 0 ; i<data.length; i++){
    let d = data[i]
    let reducerObj = d(state);
    Object.keys(reducerObj).map(key => (tempObj[key] = reducerObj[key]));
  }
  return tempObj;
};

export const groupListStateToProps = state => {
  return { groupList: state.groupReducer };
};

export const groupDetailStateToProps = state => {
  return { groupDetail: state.groupDetailReducer };
};

export const groupDetailPersonListStateToProps = state => {
  return { groupDetailPersonList: state.groupDetailPersonListReducer };
};

export const groupPersonDetailStateToProps = state => {
  return { groupPersonDetail: state.groupPersonDetailReducer };
};


export const mailPersonListStateToProps = state => {
  return { mailPersonList: state.mailPersonListReducer };
};

export const channelListStateToProps = state => {
  return { channelList: state.channelListReducer };
};

export const channelDetailStateToProps = state => {
  return { channelDetail: state.channelDetailReducer };
};

export const sideMenuStateToProps = state => {
  return { sideMenu: state.sideMenuReduce };
};
