/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { MailDetailsQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import MailDetailsSetteliteSideMenu from "../MailDetails/MailDetailsSetteliteSideMenu";
import MailDetailItemListView from "./MailDetailItemListView";

class MailDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mailList: [],
      activeIndex: 0,
      range: 0,
      headerMenuStatus: false,
      displayType: 0,
      search: "",
      searchMailList: [],
    };
  }
  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {

    const readListPermission  = checkUserPermission("read_list");
    if(readListPermission){
      const mail_id = this.props.match.params.mail_id;
      apiCall(
        METHOD.QUERY,
        MailDetailsQuery(getUserID(), mail_id, ""),
        (data) => {
          this.props.getMailDetails(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }else{
      toast.info(this.props.t("GeneralMessages.permissionError"));
    }
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };

  handleChangeMail = (mailId) => {
    this.props.history.push("/mail-detail/" + mailId);
    this.getMailList(mailId);
  };

  getMailList = (mailId) => {
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), mailId, ""),
      (data) => {
        this.props.getMailDetails(data);
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  loader = (<LoadingPage key={0} />);

  handleBackStack = () => {
    let { from } = this.props.location.state || {
      from: { pathname: "/mail-list" },
    };
    this.props.history.push("/mail-list", { from });
    // window.location.assign("/mail-list");
  };

  handleActiveIndex = (mail_id, id) => {
    if (mail_id == id) {
      this.handleScroll(mail_id);
      return "active";
    } else {
      return "";
    }
  };

  handleScroll = (id) => {
    const index = this.props.mailDetail.mailing_list.findIndex(
      (mail) => mail.id == id
    );

    if (index > 3) {
      setTimeout(function () {
        var element = document.getElementById(`li_${id}`);
        var element1 = document.getElementById("ulID");
        var elementPosition = element.getBoundingClientRect();
        element1.scrollLeft = elementPosition.left;
      }, 500);
    }
  };
  setSearchMailList = (mailList) => {
    this.setState({
      searchMailList: mailList,
    },()=>{
      console.log("searchMailList",mailList)
    });
  };
  render() {
    const {
      id,
      name,
      list_type_icon,
      child_element,
      child_list,
      mailing_list,
      total_person,
      
    } = this.props.mailDetail;
    const mail_id = this.props.mailDetail.id;
    const { isLoading, displayType,searchMailList } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <>
              <div className="header-bg header-yellow sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p
                      className="mb-0 ml-auto text-light pointer"
                      onClick={() => {
                        this.handleBackStack();
                      }}
                    >
                      {name && name}
                    </p>
                    <LogoutMenu />
                  </div>

                  <ul className="header-menu-tab-link mt-auto" id="ulID">
                    {mailing_list &&
                      mailing_list.map((data, index) => {
                        const { id, name } = data;
                        return (
                          <li
                            id={`li_${id}`}
                            key={index}
                            className={this.handleActiveIndex(mail_id, id)}
                          >
                            <a
                              href="javascript;:"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeMail(id);
                              }}
                            >
                              {name && name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="middle-section sab-middle-section1 left-line">
                {isLoading && this.loader}
                <div className="container my-3 z-index-99">
               
                 
                    <div className="d-flex">
                      <div className="icon-box-style2 mb-3">
                        <div className="icon-box yellow-bg d-flex">
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="rounded-50 bg-light">
                              <img
                                src={`data:image/png;base64,${
                                  list_type_icon && list_type_icon
                                }`}
                                alt="ico"
                                style={{ width: "32px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ml-100p m-3 clearfix d-flex align-items-center flex-fill">
                          <div className="align-self-center">
                            <h5 className="m-0 font-18 d-block">
                              {name && name}
                            </h5>
                            <p className="text-muted font-12 m-0">
                              {child_element && child_element}{" "}
                              {t("mailDetail.distributorLable")}
                            </p>
                          </div>
                          <div className="align-self-center ml-auto">
                            <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26 text-dark"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  {searchMailList.length != 0 ? (
                    <>
                      
                        <div className="container my-3">
                          {searchMailList &&
                            searchMailList.map((item, index) => {
                              return (
                                <MailDetailItemListView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                      
                    </>
                  ) : (
                        <div className="0container my-3">
                          {child_list &&
                            child_list.map((item, index) => {
                              return (
                                <MailDetailItemListView
                                  key={index}
                                  item={item}
                                  id={id}
                                />
                              );
                            })}
                        </div>
                  )}

                  <FabIcon
                    color="rounded-yellow"
                    openSlider={this.openSlider}
                  />
                </div>
              </div>
              <MailDetailsSetteliteSideMenu
                closeSlider={this.closeSlider}
                headerMenuStatus={this.state.headerMenuStatus}
                changeRouteStatus={this.props.changeRouteStatus}
              />
              <Footer type="4" />
            </>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.mailDetailStateToProps, stateToProps.mailListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.reportListDispatchToProps,
    actions.mailListDispatchToProps,
    actions.mailDetailsDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailDetails)));
