/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { MaillingListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";
import { Translation, withTranslation } from "react-i18next";

class MailList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail_list: [],
      start: 0,
      end: 10,
      hasMore: true
    };
  }

  loadMailingList = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true
      });

      apiCall(
        METHOD.QUERY,
        MaillingListQuery(getUserID(), start, end, ""),
        (data) => this.handleMailListResponse(data),
        (isLoading) => { },
        (data) => {
          toast.error(this.props.t(data.message));
          this.setState({
            hasMore: false,
            isLoading: false,
          });
        }
      );
    }
  };

  handleMailListResponse = data => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState(prevState => {
      return {
        ...this.state,
        start: start + end,
        mail_list: [...prevState.mail_list, ...data.mailing_list],
        isLoading: false
      };
    });
  };


  loader = (<LoadingPage key={0} />);

  handleSubMailList = mail_id => {
    this.props.changeMailRouteStatus(1, mail_id);
  };

  handleCheckBox = (e, checkMailID) => {
    if (e.target.checked) {
      this.props.addSelectedMailId(checkMailID)
    } else {
      this.props.removeSelectedMailId(checkMailID);
    }
  }

  render() {
    const { end, hasMore, mail_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
      <>
        <InfiniteScroll
          pageStart={end}
          loadMore={this.loadMailingList}
          hasMore={hasMore}
          useWindow={false}
          loader={this.loader}
        >
          {mail_list &&
            mail_list.map((item, index) => {
              const { name, id, child_element } = item;
              return (
                <div className="icon-box-style3 mb-2 " key={index}>
                  <div className="ml-3 my-2 d-flex">
                    <div className="d-flex w-100">
                      <div className="align-self-center">
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            onChange={e => this.handleCheckBox(e, id)}
                            checked={this.props.checkedList.includes(id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <a
                        href="javascript;:"
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleSubMailList(id)
                        }}
                        className="clearfix d-flex align-items-center flex-fill"
                      >
                        <div className="align-self-center">
                          <h5 className="m-0 font-18 d-block">
                            {name && name}
                          </h5>
                          <p className="text-muted font-12 m-0">
                            {child_element && child_element}  {t("ChannelDetail.distributorLable")}

                          </p>
                        </div>
                        <div className="align-self-center ml-auto">
                          <span className="text-dark">
                            <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </InfiniteScroll>
            </>);
        }}
      </Translation>
    );
  }
}
export default withTranslation()(MailList) 
