/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { messageMaillingListQuery, removemessagelist, removepersonmessagelist } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import LoadingPage from "../CommanComponents/LoadingPage";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getUserID } from "../../util/utils";

class RecipientsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail_list: [],
      start: 0,
      end: 10,
      hasMore: true,
    };
  }

  deletelist = (id) => {
    confirmAlert({
      title: this.props.t("ChannelDetail.deleteMaillist"),
      message: this.props.t("ChannelDetail.deleteMailListMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removemessagelist(getUserID(), id),
              (data) => {
                toast.success(this.props.t(data.message));
                let mail_list = this.state.mail_list;
                let deletedState = mail_list.filter((item) => {
                  return item.master_id != id;
                });
               
                this.setState({
                  mail_list: deletedState,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

 

  loadMailingList = () => {
    const message_id = this.props.match.params.message_id;
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        messageMaillingListQuery(start, end, message_id),
        (data) => this.handleMailListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleMailListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        mail_list: [...prevState.mail_list, ...data.mailing_list],
        isLoading: false,
      };
    });
  };

 
  deleteMailPerson = (mailId,list_id, personId) => {
    const message_id = this.props.match.params.message_id;
    confirmAlert({
      title: this.props.t("ChannelDetail.deletePerson"),
      message: this.props.t("ChannelDetail.deletePersonTitle"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersonmessagelist(
                getUserID(),
                message_id,
                list_id,
                personId
              ),
              (data) => {
                toast.success(this.props.t(data.message));
                let mail_list = this.state.mail_list;
                const mailIndex = mail_list.findIndex(
                  (mail) => mail.master_id == mailId
                );
                console.log("mail_idindex",mailIndex);
                if (mailIndex != -1) {
                  const personList = mail_list[mailIndex].person_list;
                  let afterDeletePersonList = personList.filter((person) => {
                    return person.person_id != personId;
                  });
                  mail_list[mailIndex].person_list = afterDeletePersonList;
                  mail_list[mailIndex].person = mail_list[mailIndex].person -1
                }
                this.setState({
                  mail_list,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };


  // deleteMailPerson = (mailId, personId) => {
  //   const message_id = this.props.match.params.message_id;
  //   confirmAlert({
  //     title: this.props.t("ChannelDetail.deletePerson"),
  //     message: this.props.t("ChannelDetail.deletePersonTitle"),
  //     buttons: [
  //       {
  //         label: this.props.t("personDetail.yes"),
  //         onClick: () => {
  //           apiCall(
  //             METHOD.QUERY,
  //             removepersonmessagelist(
  //               getUserID(),
  //               message_id,
  //               mailId,
  //               personId
  //             ),
  //             (data) => {
  //               toast.success(this.props.t(data.message));
  //               let mail_list = this.state.mail_list;
  //               const mailIndex = mail_list.findIndex(
  //                 (mail) => mail.id == mailId
  //               );
  //               if (mailIndex != -1) {
  //                 const personList = mail_list[mailIndex].person_list;
  //                 let afterDeletePersonList = personList.filter((person) => {
  //                   return person.person_id != personId;
  //                 });
  //                 mail_list[mailIndex].person_list = afterDeletePersonList;
  //                 mail_list[mailIndex].person = mail_list[mailIndex].person -1
  //               }
  //               this.setState({
  //                 mail_list,
  //               },()=>{
  //                 console.log("person",mail_list);
  //               });
  //             },
  //             (isLoading) => {
  //               this.setState({ deleteLoader: isLoading });
  //             },
  //             (data) => {
  //               toast.error(this.props.t(data.message));
  //             }
  //           );
  //         },
  //       },
  //       {
  //         label: this.props.t("personDetail.no"),
  //         onClick: () => {},
  //       },
  //     ],
  //   });
  // };
  
  
  loader = (<LoadingPage key={0} />);

  render() {
    const { end, hasMore, mail_list } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="mt-3">
              <InfiniteScroll
                pageStart={end}
                loadMore={this.loadMailingList}
                hasMore={hasMore}
                useWindow={false}
                loader={this.loader}
              >
                {mail_list &&
                  mail_list.map((item, index) => {
                    const { mail_name, master_id, person, person_list } = item;
                    return (
                      <React.Fragment>
                        <div className="icon-box-style3 mb-2" key={index}>
                          <div className="ml-3 my-2 d-flex w-100">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <a
                                href="javascript;:"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                className="clearfix d-flex justify-content-between flex-fill"
                              >
                                <div className="align-self-center ">
                                  <h5 className="m-0 font-18 d-block">
                                    {mail_name && mail_name}
                                  </h5>
                                  <p className="text-muted font-12 m-0">
                                    {person && person}{" "}
                                    {t("AddNewChannel.lblDestributor")}
                                  </p>
                                </div>
                              </a>
                             
                              { this.props.channelDetail.message_status_identifier =='' && <img
                                src="/images/trash-simple.png"
                                alt="delete icon"
                                className="pointer mr-2"
                                onClick={() => { this.deletelist(master_id) }}
                                style={{ width: "22px", height: "22px" }}
                              />}
                              
                            </div>
                          </div>
                        </div>

                        {person_list && person_list.map((item1, index) => {
                           const { person_id, name, email, status } = item1;
                          return (
                            <a
                                href="javascript;:"
                                className="d-block flex-fill mb-3"
                                key={index}
                              >
                                <div className="rounded-40 float-left">
                                  {status && status == "sent_successfully" ? (
                                    <i className="zmdi zmdi-check-circle zmdi-hc-fw font-22 text-success"></i>
                                  ) : (
                                    <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-22 text-blue"></i>
                                  )}
                                </div>
                                <div className="pl-2 pt-1 d-flex">
                                  <div className="d-flex justify-content-between w-100">
                                    <div className="clearfix">
                                      <h6 className="m-0">{name && name} </h6>
                                      <p className="text-muted font-12 m-0">
                                        {email && email}
                                      </p>
                                    </div>
                                    <a
                                      href="javascript;:"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteMailPerson(
                                          item.master_id,
                                          item1.list_id,
                                          person_id
                                        );
                                      }}
                                      className="text-dark"
                                    >
                                      <i className="fa fa-trash-alt "></i>
                                    </a>
                                  </div>
                                </div>
                              </a>

                          );
                        })}
                      </React.Fragment>
                    );
                  })}
              </InfiniteScroll>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.channelDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([])
)(withRouter(withTranslation()(RecipientsList)));
