/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  persondatatype,
  removepersondatatype,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import PersonDataTypeSetteliteMenu from "./PersonDataTypeSetteliteMenu";

class PersonDataTypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      person_data_type_list: [],
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      start: 0,
      end: 10,
      hasMore: true,
    };
  }

  loadListTypeList = () => {
    const { start, end, hasMore, isLoading } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        persondatatype(getUserID(), start, end),
        (data) => this.handleUserApiResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleUserApiResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        person_data_type_list: [
          ...prevState.person_data_type_list,
          ...data.person_data_type_list,
        ],
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

componentDidMount() {
  if(!checkAdminPermission()){
    this.props.history.push("/")
  }

}

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t(
        "ManagePersonDataType.confirmTodeletePersonDataTypeTitle"
      ),
      message: this.props.t(
        "ManagePersonDataType.confirmTodeletePersonDataTypeMessage"
      ),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersondatatype(getUserID(), id),
              (data) => {
                let afterDeletedPersonDataTypeList = this.state.person_data_type_list.filter(
                  (person_data_type) => {
                    return person_data_type.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  person_data_type_list: afterDeletedPersonDataTypeList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  loader = (<LoadingPage key={0} />);
  render() {
    const { person_data_type_list, hasMore, end } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      <Link to="/administration" className="text-light">
                        {t("ManagePersonDataType.title")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 left-line left-line-custom">
                <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadListTypeList}
                  hasMore={hasMore}
                  className="left-line left-line-custom"
                  useWindow={false}
                  loader={this.loader}
                >
                  {person_data_type_list &&
                    person_data_type_list.map((item, index) => {
                      const { name, description } = item;
                      return (
                        <div
                          className={
                            index == person_data_type_list.length - 1
                              ? "container my-4 z-index-99 last"
                              : "container my-4 z-index-99 "
                          }
                          key={index}
                        >
                          <a
                            href="javascript;:"
                            className="mb-4 d-block custom-hover-box"
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="rounded-40 admin-bg float-left ml-5p mb-2">
                              <span className="text-light"></span>
                            </div>
                            <div className="pl-3 pt-1 d-flex" key={index}>
                              <div className="d-flex justify-content-between w-100">
                                <div className="clearfix">
                                  <h6 className="m-0">{name && name}</h6>
                                  <p className="text-muted font-12 mb-2">
                                    {description && description}
                                  </p>
                                </div>
                                <div className="action-btns clearfix">
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.changeStatusWithStatusValue(
                                        2,
                                        item
                                      );
                                    }}
                                  />
                                  <i
                                    className="zmdi zmdi-delete zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleDelete(item);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </InfiniteScroll>

                <PersonDataTypeSetteliteMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeStatusWithStatusValue}
                />
                <FabIcon color="rounded-red" openSlider={this.openSlider} />
              </div>

              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(PersonDataTypeList));
