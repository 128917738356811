import React, { Suspense } from "react";
import AdminstrationMenu from "../components/Administration/AdminstrationMenu";
import SnippetList from "../components/Administration/SnippetManagement/Index";
import ChannelListIndex from "../components/Channel/Index";
import ChannelDetailIndex from "../components/ChannelDetail/Index";
import ChangePersonStatus from "../components/CommanComponents/ChangePersonStatus";
import LoadingPage from "../components/CommanComponents/LoadingPage1";
import EventIndex from "../components/Event/Index";
import Index from "../components/Group/Index";
import GroupDetailIndex from "../components/GroupDetail/Index";
import GroupPersonList from "../components/GroupDetailPersonList/Index";
import MailIndex from "../components/Mail/Index";
import MailDetailIndex from "../components/MailDetails/Index";
import MailPersonListIndex from "../components/MailDetailsPersonList/Index";
import PersonDetailIndex from "../components/PersonDetail/Index";
import ChangeUserPassword from "../components/Profile/ChangeUserPassword";
import ManageProfile from "../components/Profile/ManageProfile";
import RegisterUser from "../components/Profile/RegisterUser";
import UpdateProfile from "../components/Profile/UpdateProfile";
import UserSubScribe from "../components/Profile/UserSubScribe";
import UserUnSubScribe from "../components/Profile/UserUnSubScribe";
import ReportIndex from "../components/Report/Index";
import VerifyUser from "../components/Profile/VerifyUser";
import FacebookConnection from "../components/ChannelDetail/FacebookConnection";
import TwitterConnection from "../components/ChannelDetail/TwitterConnection";
import UserList from "../components/Administration/UserManagement/Index";
import EmailList from "../components/Administration/EmailMangement/Index";
import ReportList from "../components/Administration/ManageReportType/Index";
import GroupList from "../components/Administration/ManageGroupType/Index";
import EventListType from "../components/Administration/ManageEventListType/Index";
import ListType from "../components/Administration/ManageListType/Index";
import MenuList from "../components/Administration/ManageMenu/Index";
import LocationList from "../components/Administration/ManageLocation/Index";
import PersonDataTypeList from "../components/Administration/ManagePersonDataType/Index";
import AdminChannelListIndex from "../components/Administration/ManageChannel/Index";
import ChannelType from "../components/Administration/ManageChannelType/Index";
import ReportEventList from "../components/Administration/LinkReportAndSnippet/Index";
import ChangeLanguage from "../components/Profile/ChangeLanguage";
import TwitterAcknowledge from "../components/ChannelDetail/TwitterAcknowledge";
import SetUpSeystem from "../components/Administration/SetupSystem/SetUpSeystem";
const Login = React.lazy(() => import("../components/BeforeLogin/Login"));
const ForgotPassword = React.lazy(() =>
  import("../components/BeforeLogin/ForgotPassword")
);
const ChangePassword = React.lazy(() =>
  import("../components/BeforeLogin/ChangePassword")
);
const Home = React.lazy(() => import("../components/Home/Index"));

export const appRoutes = [
         {
           exact: true,
           path: "/",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <Login />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/user/register",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <RegisterUser />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/forgot_password",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ForgotPassword />
             </Suspense>
           ),
         },
         {
           path: "/activate",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ChangePersonStatus />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/recover_password",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ChangePassword />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/profile",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <UpdateProfile />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/manage-profile",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <UserSubScribe />
             </Suspense>
           ),
         },
         {
           path: "/verify-user",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <VerifyUser />
             </Suspense>
           ),
         },
         {
           exact: true,
           path: "/unsubscribe",
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <UserUnSubScribe />
             </Suspense>
           ),
         },
       ];

export const privateRoutes = [
         {
           path: "/home",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <Home />
             </Suspense>
           ),
         }, {
           path: "/twitter-acknowledge",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <TwitterAcknowledge />
             </Suspense>
           ),
         },
         {
           path: "/setup-system",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <SetUpSeystem />
             </Suspense>
           ),
         },
         {
           path: "/event-list",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <EventIndex />
             </Suspense>
           ),
         },
         {
           path: "/group-list",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <Index />
             </Suspense>
           ),
  }, {
           path: "/change-language",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <ChangeLanguage/>
      </Suspense>
    ),
  },
         {
           path: "/group-detail/:group_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <GroupDetailIndex />
             </Suspense>
           ),
         },
         {
           path: "/group-personlist/:group_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <GroupPersonList />
             </Suspense>
           ),
         },
         {
           path: "/person-detail/:group_person_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <PersonDetailIndex />
             </Suspense>
           ),
         },
         {
           path: "/mail-list",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <MailIndex />
             </Suspense>
           ),
         },
         {
           path: "/event/:event_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ReportIndex />
             </Suspense>
           ),
         },
         {
           path: "/mail-detail/:mail_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <MailDetailIndex />
             </Suspense>
           ),
         },
         {
           path: "/manage-default-snippet",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ReportEventList />
             </Suspense>
           ),
         },
         {
           path: "/event/:event_id/report/:report_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ChannelListIndex />
             </Suspense>
           ),
         },
         {
           path: "/connect-facebook",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <FacebookConnection />
             </Suspense>
           ),
         },
         {
           path: "/connect-twitter",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <TwitterConnection />
             </Suspense>
           ),
         },
         {
           path: "/event/:event_id/report/:report_id/message/:message_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ChannelDetailIndex />
             </Suspense>
           ),
         },
         {
           path: "/mail-details-personlist/:mail_person_id",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <MailPersonListIndex />
             </Suspense>
           ),
         },
        
         {
           path: "/update-profile",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ManageProfile />
             </Suspense>
           ),
         },
         {
           path: "/change-password",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <ChangeUserPassword />
             </Suspense>
           ),
         },
         {
           path: "/administration",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <AdminstrationMenu />
             </Suspense>
           ),
  },
         {
           path: "/manage-user",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <UserList />
             </Suspense>
           ),
         },
         {
           path: "/manage-snippet",
           exact: true,
           component: () => (
             <Suspense fallback={<LoadingPage />}>
               <SnippetList />
             </Suspense>
           ),
  },
  {
    path: "/manage-emails",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <EmailList />
      </Suspense>
    ),
  },
  {
    path: "/manage-report-type",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <ReportList />
      </Suspense>
    ),
  }, {
    path: "/manage-group-type",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <GroupList />
      </Suspense>
    ),
  }, {
    path: "/manage-eventlist-type",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <EventListType />
      </Suspense>
    ),
  }, {
    path: "/manage-list-type",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <ListType />
      </Suspense>
    ),
  }, {
    path: "/manage-channel-type",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <ChannelType />
      </Suspense>
    ),
  }, {
    path: "/manage-menu",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <MenuList />
      </Suspense>
    ),
  }, {
    path: "/manage-location",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <LocationList />
      </Suspense>
    ),
  }, {
    path: "/manage-person-datatype",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <PersonDataTypeList />
      </Suspense>
    ),
  }, {
    path: "/channel-list",
    exact: true,
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <AdminChannelListIndex />
      </Suspense>
    ),
  },
       ];
