/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery, movepersongroup } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { getUserID } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";
import NoRecordFound from "../CommanComponents/NoRecordFound";

class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      searchPersonList: [],
      sortType: 1,
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {
    const { end, loader, sortType } = this.state;
    if (!loader && this.props.groupList.hasMore) {
      this.setState({
        loader: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupList.start,
          end,
          0,
          "",
          sortType
        ),
        (data) => {
          this.props.getGroupList(data.group_list);
          this.handleGroupApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearGroupState();
      }
    );
  };
  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.props.getGroupHasMoreValue(false);
    }
    this.props.getGroupStartValue(this.props.groupList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  handleGroupSelection = (groupId) => {
    const { id } = this.props.groupPersonDetail && this.props.groupPersonDetail;

    apiCall(
      METHOD.QUERY,
      movepersongroup(getUserID(), groupId, id, 0),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  render() {
    const { end } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sab-header1 sticky-top">
                <div className="container pb-4">
                  <div className="py-2 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>
                  </div>

                  <div className="text-center mt-2  text-light">
                    <h2 className="m-0 text-light">
                      {t("personDetail.changeGroup")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
                <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={this.props.groupList.hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-4">
                    {this.props.groupList &&
                      this.props.groupList.groupList.map((data, index) => {
                        const {
                          id,
                          name,
                          group_type_icon,
                          person,
                          description,
                        } = data;
                        return (
                          // <GroupItem data={data} key={index} index={index} />

                          <div
                            className="icon-box-style2 mb-2 pointer"
                            onClick={() => this.handleGroupSelection(id)}
                          >
                            <div className="icon-box blue-bg d-flex">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="rounded-50 bg-light">
                                  <img
                                    src={`data:image/png;base64,${
                                      group_type_icon && group_type_icon
                                    }`}
                                    style={{ width: "32px" }}
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ml-3 my-3 d-inline-block">
                              <h5 className="m-0 font-18">{name && name}</h5>
                              {person == 0 ? (
                                <p className="text-muted font-12 mb-0">
                                  {description && description}
                                </p>
                              ) : (
                                <p className="text-muted font-12 mb-0">
                                  {person && person}{" "}
                                  {t("GroupListScreen.contactPerson")}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}

                    {this.props.groupList &&
                      this.props.groupList.length == 0 && (
                        <NoRecordFound title="Group List" name="group list" />
                      )}
                  </div>
                </InfiniteScroll>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupListStateToProps,
        stateToProps.groupPersonDetailStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupList)));
