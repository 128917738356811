import { ADD_SUB_NEW_MAIL, MAILING_DETAILS } from "../constants/action-types";

export const getMailDetails = (data) => ({
  type: MAILING_DETAILS,
  payload: data,
});

export const addSubMail = (data) => ({
  type: ADD_SUB_NEW_MAIL,
  payload: data,
});
