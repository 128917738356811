import {
  GROUP_PERSONLIST,
  GROUP_PERSONLIST_CLEARSTATE,
  GROUP_PERSONLIST_HASMORE,
  GROUP_PERSONLIST_START,
  UPDATE_PERSON_LIST,
  UPDATE_PERSON_DETAILS,
  ADD_NEW_PERSONLIST,
  GROUP_DETAIL_DELETE,
  REARRANGE_GROUP_PERSON_LIST
} from "../constants/action-types";

export const getPersonList = data => ({
  type: GROUP_PERSONLIST,
  payload: data
});

export const updatePersonList = data => ({
  type: UPDATE_PERSON_LIST,
  payload: data
});

export const addNewPerson = data => ({
  type: ADD_NEW_PERSONLIST,
  payload: data
});
export const setPersonListHasMoreValue = data => ({
  type: GROUP_PERSONLIST_HASMORE,
  payload: data
});

export const setPersonListStartValue = data => ({
  type: GROUP_PERSONLIST_START,
  payload: data
});

export const clearStateGroupPersonDetail = data => ({
  type: GROUP_PERSONLIST_CLEARSTATE,
  payload: data
});

export const updatePersonDetail = data => ({
  type: UPDATE_PERSON_DETAILS,
  payload: data
});
export const deletePersonList = data => ({
  type: GROUP_DETAIL_DELETE,
  payload: data
});
export const reArrangePersonList = data => ({
         type: REARRANGE_GROUP_PERSON_LIST,
         payload: data
       });
