import { REPORT_LIST, EDIT_EVENT_REPORT, UPDATE_REPORT_LIST } from "../constants/action-types";

const initialState = {};
export default function reportListReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_LIST:
      return action.payload;
    case EDIT_EVENT_REPORT:
      return { ...state, ...action.payload };
    case UPDATE_REPORT_LIST:
      return {
        ...state,
        report_list: [...state.report_list, ...action.payload]
      };
    default:
      return state;
  }
}
