import React from "react";

export default function SnippetType(props) {
                 const { handleBrandSelection, item } = props;
                 const { icon, name } = item;
                 return (
                   <React.Fragment>
                     <li
                       className="nav-item pointer"
                       onClick={() => handleBrandSelection(item)}
                     >
                       <a
                         className="nav-link"
                         href="javascript;:"
                         onClick={(e) => {
                           e.preventDefault();
                         }}
                       >
                         <img
                           src={`data:image/png;base64,${icon && icon}`}
                           alt="brand_icon"
                         />{" "}
                         {name && name}
                       </a>
                     </li>
                   </React.Fragment>
                 );
               }
