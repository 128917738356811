/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getpersondatatypedata } from "../../../constants/apiParams";
import apiCall, { METHOD, uploadLink } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import Footer from "../../CommanComponents/Footer";


const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addpersondatatype = gql`
 mutation Addpersondatatype($profile: AddpersondatatypeInput!) 
 {Addpersondatatype(input: $profile) 
  {success 
  message}
}
`;

class EditPersonDataType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      name: '',
      identifier: '',
      description: '',
      field_type: '',
      is_default: 0,
      is_required: 0,
    };
  }


  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getpersondatatypedata(getUserID(), this.props.item.id),
      (data) => this.getDetails(data),
      (isLoading) => { this.setState({ isLoading }) },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }
  getDetails = (data) => {
    const { name, description, field_type, is_default_field, identifier, is_required } = data
    this.setState({
      name,description,identifier,field_type,is_default:is_default_field,is_required
    })
  }

  handleTextChange = (e, type) => {
    if (type == 'name') {
      this.setState({
        name: e.target.value
      })
    } else if (type == 'identifier') {
      this.setState({
        identifier: e.target.value
      })
    } else if (type == 'description') {
      this.setState({
        description: e.target.value
      })
    }
  }
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setFieldType = (event) => {
    this.setState({
      field_type: event.target.value
    })
  }

  set_is_required = (event) => {

    if (event.target.checked) {
      this.setState({
        is_required: 1
      })
    } else {
      this.setState({
        is_required: 0
      })
    }
  }

  set_is_default = (event) => {

    if (event.target.checked) {
      this.setState({
        is_default: 1
      })
    } else {
      this.setState({
        is_default: 0
      })
    }
  }

  render() {
    const {
      name, identifier, description, field_type, is_default, is_required
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addpersondatatype}>
                {(Addpersondatatype, { data, error, loading }) => {
                  if (data) {

                    if (data.Addpersondatatype.success == 0) {
                      toast.error(this.props.t(data.Addpersondatatype.message));
                    } else {
                      toast.success(this.props.t(data.Addpersondatatype.message));
                      this.props.changeRouteStatus(0, {})
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding
                    );
                  }

                  return (
                    <React.Fragment>
                      <div className="header-bg header-green sab-header1 sticky-top">
                        <div className="container">
                          <div className="py-2 d-flex align-items-center">
                            <span>
                              <i
                                className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                                onClick={() => this.props.changeRouteStatus(0, {})}
                              ></i>
                            </span>

                            {loading ? (
                              <div className="btn btn-light font-13 ml-auto pointer">
                                {t("AdminAddUser.save")}
                                <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </div>
                            ) : (
                                <div
                                  className="btn btn-light font-13 ml-auto pointer"
                                  onClick={() => {
                                    const { name, description, identifier, field_type, is_default
                                      , is_required } = this.state
                                    if (!name) {
                                      toast.info(this.props.t("ManagePersonDataType.nameTitleValidation"));
                                    }  else if (!identifier) {
                                      toast.info(this.props.t("ManagePersonDataType.identifierTitleValidation"));
                                    } else if (!field_type) {
                                      toast.info(this.props.t("ManagePersonDataType.fieldTypeTitleValidation"));
                                    } else {

                                      let obj = {
                                        variables: {
                                          profile: {
                                            user_id: getUserID(),
                                            id: this.props.item.id,
                                            name,
                                            description: description?description:null,
                                            identifier,
                                            field_type,
                                            is_default_field:is_default,
                                            is_required,
                                          },
                                        },
                                      };
                                      Addpersondatatype(obj)
                                    }
                                  }}
                                >
                                  {t("AdminAddUser.save")}
                                </div>
                              )}
                          </div>
                          <div className="text-center mt-4 text-light">
                            <h2 className="m-0 text-light">
                              {t("ManagePersonDataType.editPersonDataType")}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="middle-section sab-middle-section1">
                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">

                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.nameTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "name")}
                                      name="name"
                                      value={name && name}
                                      placeholder={t("ManagePersonDataType.nameTitlePlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.descriptionTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "description")}
                                      name="description"
                                      value={description && description}
                                      placeholder={t("ManagePersonDataType.descriptionTitlePlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.identifierTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) => this.handleTextChange(e, "identifier")}
                                      name="identifier"
                                      value={identifier && identifier}
                                      placeholder={t("ManagePersonDataType.identifierTitlePlaceHolder")}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.fieldTypeTitle")}
                                    </label>

                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" checked={field_type =='text'?(true):(false)} value="text" name="link_type" />{t("ManagePersonDataType.fieldType1")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" value="email" checked={field_type == 'email' ? (true) : (false)}  name="link_type" />{t("ManagePersonDataType.fieldType2")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" value="text_multiline" checked={field_type == 'text_multiline' ? (true) : (false)}  name="link_type" />{t("ManagePersonDataType.fieldType3")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" value="checkbox" checked={field_type == 'checkbox' ? (true) : (false)} name="link_type" />{t("ManagePersonDataType.fieldType4")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" value="tel" checked={field_type == 'tel' ? (true) : (false)} name="link_type" />{t("ManagePersonDataType.fieldType5")}
                                    </div>
                                    <div onChange={(e) => this.setFieldType(e)} >
                                      <input type="radio" className="mr-2" value="link" checked={field_type == 'link' ? (true) : (false)} name="link_type" />{t("ManagePersonDataType.fieldType6")}
                                    </div>

                                  </div>


                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.is_required")}
                                    </label>

                                    <div onChange={(e) => this.set_is_required(e)} >
                                      <input type="checkbox" className="mr-2" checked={is_required==1 ?(true) : false} value="0" name="link_type" />
                                      {t("ManagePersonDataType.is_required")}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("ManagePersonDataType.is_default")}
                                    </label>
                                    <div onChange={(e) => this.set_is_default(e)} >
                                      <input type="checkbox" value="0" name="link_type" checked={is_default == 1 ? (true) : false} className="mr-2" />
                                      {t("ManagePersonDataType.is_default")}
                                    </div>
                                  </div>

                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Footer type="2" />
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}

      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditPersonDataType));
