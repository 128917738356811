/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { EditGroupQuery, GrouptypeQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { getUserID } from "../../util/utils";
import { CreateGroupSchema } from "../../util/validationSchema";
import ErrorMessageFormik from "../CommanComponents/ErrorMessageFormik";
import BrandTypeList from "../Event/BrandTypeList";

class EditNewGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
      brandList: [],
      groupType: "",
      groupImage: "",
      groupID: 0,
      isLoading: false,
    };
  }

  handleGroupSelection = (item) => {
    const { name, icon, id } = item;
    this.setState(() => {
      return {
        ...this.state,
        status: false,
        groupType: name,
        groupImage: icon,
        groupID: id,
      };
    });
  };

  closeGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: false,
      };
    });
  };

  openGroupDialogue = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
      };
    });
  };

  handleAfterCreateApiCall = (
    group_id,
    grouptype_id,
    group_name,
    parent_id,
    data
  ) => {
    toast.success(this.props.t(data.message));
    let group = {
      id: group_id,
      name: group_name,
      group_type_id: grouptype_id,
      group_type_icon: this.state.groupImage,
      group_type_name: this.state.groupType,
      parent_id: parent_id,
      description: data.description,
    };

    if (this.props.groupDetail.parent_id == parent_id) {
      this.props.updateGroupById(group);
      this.props.updateGroupDetails(group);
    } else {
      this.props.deleteGroup(group);
    }

    this.props.changeRouteStatus(0);
  };

  handleAfterBrandApiCall = (data) => {
    let brandImageDefault, brandNameDefault, groupID;
    if (data.group_type_list && data.group_type_list.langth !== 0) {
      brandNameDefault = data.group_type_list[0].name;
      brandImageDefault = data.group_type_list[0].icon;
      groupID = data.group_type_list[0].id;
    }

    const {
      group_type_icon,
      group_type_name,
      group_type_id,
    } = this.props.groupDetail;

    this.setState(() => {
      return {
        ...this.state,
        brandList: data.group_type_list,
        groupType: group_type_name ? group_type_name : brandNameDefault,
        groupImage: group_type_icon ? group_type_icon : brandImageDefault,
        groupID: group_type_id ? group_type_id : groupID,
      };
    });
  };

  handleSubmit = (values) => {
    const { group_name, description } = values;
    const { groupID } = this.state;
    const group_id = this.props.match.params.group_id;

    apiCall(
      METHOD.QUERY,
      EditGroupQuery(
        getUserID(),
        groupID,
        group_name,
        description,
        this.props.groupDetail.parent_id,
        group_id
      ),
      (data) =>
        this.handleAfterCreateApiCall(
          group_id,
          groupID,
          group_name,
          description,
          this.props.groupDetail.parent_id,
          data
        ),
      (isLoading) =>
        this.setState(() => {
          return { ...this.state, isLoading: isLoading };
        }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  componentDidMount() {
    this.props.groupDetail.group_type_name &&
      this.setState({ groupType: this.props.groupDetail.group_type_name });

    apiCall(
      METHOD.QUERY,
      GrouptypeQuery(getUserID()),
      (data) => this.handleAfterBrandApiCall(data),
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  render() {
    const { brandList, status, groupImage, groupType, isLoading } = this.state;
    const { changeRouteStatus } = this.props;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <Formik
                enableReinitialize
                initialValues={{
                  group_name:
                    this.props.groupDetail.name && this.props.groupDetail.name,
                  description:
                    this.props.groupDetail.description &&
                    this.props.groupDetail.description,
                }}
                validationSchema={CreateGroupSchema(
                  t("GroupDetailScreen.tooShort"),
                  t("GroupDetailScreen.tooLong"),
                  t("GroupDetailScreen.mailNameErrorMessage"),
                  t("GroupDetailScreen.descriptionErrorMessage")
                )}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, values, handleSubmit, setErrors }) => (
                  <React.Fragment>
                    <div className="header-bg header-blue sab-header1 sticky-top">
                      <div className="container">
                        <div className="py-2 d-flex align-items-center">
                          <a href="javascript;:">
                            <span
                              className="pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                changeRouteStatus(0);
                              }}
                            >
                              <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                            </span>
                          </a>

                          {isLoading ? (
                            <div
                              className="btn btn-light font-13 ml-auto pointer disabled"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                              <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                <span className="sr-only">
                                  {t("GeneralMessages.loading")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="btn btn-light font-13 ml-auto pointer"
                              onClick={handleSubmit}
                            >
                              {t("GroupDetailScreen.btnSave")}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4 text-light">
                          <h2 className="m-0 text-light">
                            {" "}
                            {t("GroupDetailScreen.editGroup")}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="middle-section sab-middle-section1">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group max-170 m-auto text-center">
                            <label className="font-14">
                              {" "}
                              {t("GroupDetailScreen.type")}
                            </label>
                            <div
                              className="icon-drop-bg pointer"
                              onClick={this.openGroupDialogue}
                            >
                              <div className="rounded-circle ">
                                <img
                                  src={`data:image/png;base64,${
                                    groupImage && groupImage
                                  }`}
                                  alt="ico"
                                />
                              </div>
                              <span className="font-12">
                                {groupType && groupType}
                              </span>
                            </div>

                            <BrandTypeList
                              closeBrandDialogue={this.closeGroupDialogue}
                              handleBrandSelection={this.handleGroupSelection}
                              status={status}
                              BrandList={brandList}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("GroupDetailScreen.titleInternal")}
                            </label>
                            <Field
                              type="text"
                              name="group_name"
                              placeholder={t("GroupDetailScreen.titleInternal")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="group_name" />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {" "}
                              {t("GroupDetailScreen.description")}
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder={t("GroupDetailScreen.description")}
                              className="form-control"
                              required
                            />
                            <ErrorMessageFormik name="description" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(EditNewGroup)));
