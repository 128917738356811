/* eslint eqeqeq: 0 */
import {
  ADD_NEW_GROUP,
  CLEAR_GROUP,
  DELETE_GROUP,
  GROUP_HASMORE_VALUE,
  GROUP_LIST,
  GROUP_START_VALUE,
  UPDATE_GROUP_BY_ID,
} from "../constants/action-types";

const initialState = {
  groupList: [],
  start: 0,
  hasMore: true,
};
export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_LIST:
      return {
        ...state,
        groupList: [...state.groupList, ...action.payload],
      };
    case GROUP_START_VALUE:
      return { ...state, start: action.payload };
    case GROUP_HASMORE_VALUE:
      return { ...state, hasMore: action.payload };
    case ADD_NEW_GROUP:
      return {
        ...state,
        groupList: [...action.payload, ...state.groupList],
      };
    case CLEAR_GROUP:
      return initialState;
    case UPDATE_GROUP_BY_ID:
      return {
        ...state,
        groupList: state.groupList.map((item, i) =>
          item.id == action.payload.id ? { ...item, ...action.payload } : item
        ),
      };
    case DELETE_GROUP:
      let groupList = state.groupList.filter((item) => {
        return item.id != action.payload.id;
      });
      return { ...state, groupList: groupList };
    default:
      return state;
  }
}
