/* eslint eqeqeq: 0 */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ApproveUser,
  EditAdminNewUser,
  getUserDetailsbyadminUserID,
  getUserType
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import { EditUserSchema } from "../../../util/validationSchema";
import ErrorMessageFormik from "../../CommanComponents/ErrorMessageFormik";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import UserTypeList from "./UserTypeList";

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_type_name: "",
      user_type_id: 0,
      user_type_list: [],
      manage_user_type_id: [],
      manage_user_type_name: [],
      reportDialogueStatus: false,
      is_active: false,
      addUserLoader: false,
      name: "",
      first_name: "",
      email: "",
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      getUserType(),
      (data) => {
        this.setState(
          {
            user_type_list: data.user_group_list,
          },
          () => {
            this.getUserDetails();
          }
        );
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  checkboxChangeEvent = (e, id, name) => {
    const value = e.target.checked;
    let manage_user_type_id = this.state.manage_user_type_id;
    let manage_user_type_name = this.state.manage_user_type_name;
    if (value) {
      manage_user_type_id.push(id);
      manage_user_type_name.push(name);
    } else {
      manage_user_type_id = manage_user_type_id.filter((u_id) => u_id != id);
      manage_user_type_name = manage_user_type_name.filter(
        (u_name) => u_name != name
      );
    }

    let user_type_id = manage_user_type_id.join(",");
    let user_type_name = manage_user_type_name.join(", ");

    this.setState({
      manage_user_type_id,
      manage_user_type_name,
      user_type_name,
      user_type_id,
    });
  };

  getUserDetails = () => {
    const { id } = this.props.item;
    apiCall(
      METHOD.QUERY,
      getUserDetailsbyadminUserID(getUserID(), id),
      (data) => {
        const { name, first_name, email, is_active, user_group } = data;
        let all_user_type_id = "",
          all_user_type_name = "",
          manage_user_type_id = [],
          manage_user_type_name = [];
          for (var index = 0; index < user_group.length; index++) {
            let item = user_group[index]
              manage_user_type_id.push(item.user_group_id);
              manage_user_type_name.push(item.user_group_name);
              if (index == 0) {
                all_user_type_id = item.user_group_id;
                all_user_type_name = item.user_group_name;
              } else {
                all_user_type_id = all_user_type_id + "," + item.user_group_id;
                all_user_type_name =
                  all_user_type_name + ", " + item.user_group_name;
              }
          }

        this.setState({
          name,
          first_name,
          email,
          is_active: this.props.item.is_approve ? true : is_active,
          user_type_id: all_user_type_id,
          user_type_name: all_user_type_name,
          manage_user_type_id,
          manage_user_type_name,
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  handleBrand = (image, name) => {
    this.setState(() => {
      return {
        status: false,
        brandType: name,
        brandImage: image,
      };
    });
  };

  handleReportSelection = (item) => {
    const { name, id } = item;
    this.setState({
      reportDialogueStatus: false,
      user_type_name: name,
      user_type_id: id,
    });
  };

  handleSubmit = (value) => {
    if (this.state.manage_user_type_id.length == 0) {
      toast.info(this.props.t("AdminAddUser.user_group_validation"));
    } else if (this.props.item.is_approve) {
      this.approveUser(value);
    } else {
      this.apiCallEditUser(value);
    }
  };

  approveUser = (value) => {
    apiCall(
      METHOD.QUERY,
      ApproveUser(getUserID(), this.props.item.id),
      (data) => {
        this.apiCallEditUser(value);
      },
      (isLoading) => {
        this.setState({ addUserLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  apiCallEditUser = (value) => {
    const { user_type_id } = this.state;
    const { name, first_name, email, password, is_active } = value;
    let active_value = 0;
    if (is_active) {
      active_value = 1;
    } else {
      active_value = 0;
    }
    apiCall(
      METHOD.QUERY,
      EditAdminNewUser(
        getUserID(),
        this.props.item.id,
        active_value,
        name,
        first_name,
        email,
        password,
        user_type_id
      ),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0, {});
      },
      (isLoading) => {
        this.setState({ addUserLoader: isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  render() {
    const {
      isLoading,
      user_type_name,
      addUserLoader,
      is_active,
      name,
      manage_user_type_id,
      first_name,
      email,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Formik
              enableReinitialize
              initialValues={{
                name,
                first_name,
                email,
                is_active,
                password: "",
              }}
              validationSchema={EditUserSchema(
                t("AdminAddUser.name_error"),
                t("AdminAddUser.first_name_error"),
                t("AdminAddUser.email_error"),
                t("AdminAddUser.email_valid_error")
              )}
              onSubmit={(values, actions) => {
                this.handleSubmit(values);
              }}
            >
              {({ handleSubmit, values }) => (
                <React.Fragment>
                  <div className="header-bg header-green sab-header1 sticky-top">
                    <div className="container">
                      <div className="py-2 d-flex align-items-center">
                        <span>
                          <i
                            className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                            onClick={() => this.props.changeRouteStatus(0, {})}
                          ></i>
                        </span>

                        {addUserLoader ? (
                          <div className="btn btn-light font-13 ml-auto pointer">
                            {t("AdminAddUser.save")}
                            <div className="spinner-border spinner-border-sm ml-1 text-blue">
                              <span className="sr-only">
                                {t("GeneralMessages.loading")}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="btn btn-light font-13 ml-auto pointer"
                            onClick={handleSubmit}
                          >
                            {t("AdminAddUser.save")}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-4 text-light">
                        <h2 className="m-0 text-light">
                          {t("AdminAddUser.edit_title")}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="middle-section sab-middle-section1">
                    {isLoading && <LoadingPage />}
                    <div className="container my-4 z-index-99">
                      <div className="form-group mb-5">
                      <div className="form-group m-auto text-center">
                          <a
                            href="javascript;:"
                            className="clearfix text-dark"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleReportDialogue();
                            }}
                          >
                            <label className="font-14 d-block">
                              {t("AdminAddUser.group")}
                            </label>
                            <div className="icon-drop-bg d-inline-block mt-3 pointer">
                              <div className="rounded-circle">
                                <strong>
                                  <h2>
                                    {manage_user_type_id &&
                                      manage_user_type_id.length}
                                  </h2>
                                </strong>
                              </div>
                              <span className="font-12 word-break">
                                {user_type_name && user_type_name}
                              </span>
                            </div>
                          </a>
                        </div>

                        <UserTypeList
                          closeBrandDialogue={this.handleReportDialogue}
                          status={this.state.reportDialogueStatus}
                          BrandList={this.state.user_type_list}
                          checklist={this.state.manage_user_type_id}
                          checkboxChangeEvent={this.checkboxChangeEvent}
                        />

                        <div className="form-group">
                          <div className="container my-4 00z-index-99">
                            <form>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("AdminAddUser.name_lable")}
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder={t(
                                    "AdminAddUser.name_placeholder"
                                  )}
                                  className="form-control"
                                  required
                                />
                                <ErrorMessageFormik name="name" />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("AdminAddUser.first_name_lable")}
                                </label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  placeholder={t(
                                    "AdminAddUser.first_name_placeholder"
                                  )}
                                  className="form-control"
                                  required
                                />
                                <ErrorMessageFormik name="first_name" />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("AdminAddUser.email_lable")}
                                </label>
                                <Field
                                  type="text"
                                  name="email"
                                  placeholder={t(
                                    "AdminAddUser.email_placeholder"
                                  )}
                                  className="form-control"
                                  required
                                />
                                <ErrorMessageFormik name="email" />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("AdminAddUser.password_lable")}
                                </label>
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder="************"
                                  className="form-control"
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="is_active" className="mr-4">
                                  {t("AdminAddUser.is_active_lable")}
                                </label>
                                <Field
                                  type="checkbox"
                                  id="is_active"
                                  className="ml-2"
                                  checked={values.is_active}
                                  name="is_active"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Footer type="2" />
                </React.Fragment>
              )}
            </Formik>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EditUser));
