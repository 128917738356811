/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addGeneralGroupToMaillingList,
  GroupListQuery,
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";
import SearchGeneralGroup from "./SearchGeneralGroup";

class GeneralGGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_list: [],
      start: 0,
      end: 20,
      hasMore: true,
      isLoading: false,
      addGroupLoading: false,
      searchGroupList: [],
      parentGroupId: 0,
      parent_group: true,
      breadcrumb: [],
      selectedGroupList: [],
      groupLinkLoader: false,
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        ...this.state,
        isLoading: status,
      };
    });
  };

  loadGeneralGroupList = () => {
    const { start, end, hasMore, isLoading, parentGroupId } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, parentGroupId, "", 0),
        (data) => this.handleGeneralListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  setSearchArrayList = (arrayList) => {
    this.setState({
      searchGroupList: arrayList,
    });
  };

  handleGroupClick = (parentGroupId, person) => {
    if (!person || person == 0) {
      this.setState(
        {
          parentGroupId,
          group_list: [],
          start: 0,
          parent_group: parentGroupId == 0 ? true : false,
          hasMore: true,
        },
        () => {
          this.loadGeneralGroupList();
        }
      );
    } else {
      toast.warn(this.props.t("generalGroupList.groupHasNoChildMessage"));
    }
  };

  loader = (<LoadingPage key={0} />);

  handleCheckBox = (e, group_id) => {
    if (e.target.checked) {
      this.addSelectedGroupID(group_id);
    } else {
      this.removeSelectedGroupID(group_id);
    }
  };

  addSelectedGroupID = (group_id) => {
    this.setState((prev) => {
      return {
        selectedGroupList: [...prev.selectedGroupList, group_id],
      };
    });
  };

  handleGeneralListResponse = (data) => {
    const { start, end, group_list } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }
    let new_group_list = [...group_list, ...data.group_list];
    let tempArray = [];
    tempArray = removeDuplicates(new_group_list, "id");

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        group_list: tempArray,
        isLoading: false,
        breadcrumb: data.breadcrumb,
      };
    });
  };

  removeSelectedGroupID = (group_id) => {
    let afterRemoveGroupList = this.state.selectedGroupList.filter((id) => {
      return id != group_id;
    });

    this.setState({
      selectedGroupList: afterRemoveGroupList,
    });
  };

  handleSubmit = () => {

    const {selectedGroupList} = this.state
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];

    let selectedAllGroup = selectedGroupList.toString();
    console.log("selectedAllGroup",selectedAllGroup);

    apiCall(
      METHOD.QUERY,
      addGeneralGroupToMaillingList(getUserID(), selectedAllGroup, list_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.changeRouteStatus(0);
      },
      (isLoading) => {
        this.setState({
          addGroupLoading: isLoading,
        });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );

  };

  render() {
    const {
      end,
      hasMore,
      group_list,
      addGroupLoading,
      searchGroupList,
      breadcrumb,
      parent_group,
      parentGroupId,
      groupLinkLoader,
      selectedGroupList,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <React.Fragment>
                <div className="header-bg header-yellow sab-header1 sticky-top">
                  <div className="container pb-2">
                    <div className="py-1 d-flex align-items-center">
                      <span
                        className="pointer"
                        onClick={() => this.props.changeRouteStatus(0)}
                      >
                        <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                      </span>

                      {groupLinkLoader ? (
                        <div className="btn btn-light font-13 ml-auto pointer disabled">
                          {t("MailDetailScreen.btnSave")}
                          <div className="spinner-border spinner-border-sm ml-1 text-blue">
                            <span className="sr-only">
                              {t("GeneralMessages.loading")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="btn btn-light font-13 ml-auto pointer"
                          onClick={() => {
                            this.handleSubmit();
                          }}
                        >
                          {t("MailDetailScreen.btnSave")}
                        </div>
                      )}
                    </div>

                    <div className="text-center  text-light">
                      <h2 className="m-0 text-light">
                        {t("generalGroupList.addGroup")}
                      </h2>
                    </div>
                    <SearchGeneralGroup searchArray={this.setSearchArrayList} />
                  </div>
                </div>

                {!parent_group && (
                  <div className="container my-4 z-index-99">
                    <div className="d-block flex-fill z-index-9999">
                      <div className="ml-2 my-2 mb-3 d-flex">
                        <div className="d-flex w-100">
                          <div className="clearfix d-flex0 0align-items-center">
                            <p className="font-12 m-0">
                              <React.Fragment>
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleGroupClick(0, 0);
                                  }}
                                  className="text-muted"
                                  data-tip={"Start"}
                                >
                                  {t("GroupListScreen.start")}
                                  <ReactTooltip
                                    place="top"
                                    type="info"
                                    effect="float"
                                  />
                                </a>
                                {" > "}
                              </React.Fragment>
                              {breadcrumb &&
                                breadcrumb.map((item, index) => {
                                  if (index == 0) {
                                    return (
                                      <React.Fragment key={index}>
                                        <a
                                          href="javascript;:"
                                          className="text-muted"
                                          data-tip={item.name}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(
                                              item.id,
                                              item.person
                                            );
                                          }}
                                        >
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                          {item.name}
                                        </a>
                                        {" > "}
                                      </React.Fragment>
                                    );
                                  } else if (
                                    index != 0 &&
                                    index != breadcrumb.length - 1
                                  ) {
                                    return (
                                      <React.Fragment key={index}>
                                        <a
                                          href="javascript;:"
                                          className="text-muted"
                                          data-tip={item.name}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(
                                              item.id,
                                              item.person
                                            );
                                          }}
                                        >
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                          [...]
                                        </a>

                                        {index != breadcrumb.length - 1 &&
                                          " > "}
                                      </React.Fragment>
                                    );
                                  } else if (index == breadcrumb.length - 1) {
                                    return (
                                      <React.Fragment key={index}>
                                        {item.name && item.name}
                                      </React.Fragment>
                                    );
                                  }
                                })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="middle-section sab-middle-section1">
                  {addGroupLoading && this.loader}
                  {searchGroupList && searchGroupList.length != 0 ? (
                    <div className="container my-4  00z-index-99">
                      {searchGroupList &&
                        searchGroupList.map((item, index) => {
                          const { id, name, has_child, person } = item;
                          return (
                            <div
                              className="icon-box-style3 mb-2 pointer"
                              key={index}
                            >
                              <div className="ml-3 my-2 d-flex pointer">
                                <div className="d-flex w-100">
                                  <div className="align-self-center">
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          this.handleCheckBox(e, id);
                                        }}
                                        checked={selectedGroupList.includes(id)}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  <a
                                    href="#"
                                    className="clearfix d-flex align-items-center flex-fill"
                                    onClick={(e) => {
                                      if (has_child == 1) {
                                        //  this.handleGroupClick(id,person);
                                      }
                                    }}
                                  >
                                    <div className="align-self-center pointer">
                                      <h5 className="m-0 font-18 d-block">
                                        {name && name}
                                      </h5>
                                      <p className="text-muted font-12 m-0">
                                        {/*  {person && person}  {" "}     {person == 1 ? (t("GroupListScreen.singularPerson")) : (t("GroupListScreen.pluralPerson"))}*/}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {has_child == 1 && (
                                <div className="align-self-center float-right ml-auto">
                                  <span
                                    className="text-dark"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (has_child == 1) {
                                        this.handleGroupClick(id);
                                      }
                                    }}
                                  >
                                    <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <InfiniteScroll
                      pageStart={end}
                      loadMore={this.loadGeneralGroupList}
                      hasMore={hasMore}
                      useWindow={false}
                      loader={this.loader}
                    >
                      <div className="</div>container my-2">
                        {group_list &&
                          group_list.map((item, index) => {
                            const { name, id, has_child, person } = item;
                            return (
                              <div
                                className="icon-box-style3 mb-2 pointer"
                                key={index}
                              >
                                <div className="ml-3 my-2 d-flex pointer">
                                  <div className="d-flex w-100">
                                    <div className="align-self-center">
                                      <label className="checkbox-container">
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            this.handleCheckBox(e, id);
                                          }}
                                          checked={selectedGroupList.includes(
                                            id
                                          )}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                    <a
                                      href="#"
                                      className="clearfix d-flex align-items-center flex-fill"
                                      onClick={(e) => {
                                        if (has_child == 1) {
                                          this.handleGroupClick(id, person);
                                        }
                                      }}
                                    >
                                      <div className="align-self-center pointer">
                                        <h5 className="m-0 font-18 d-block">
                                          {name && name}
                                        </h5>
                                        <p className="text-muted font-12 m-0">
                                          {/*                                        {person && person}  {" "}     {person == 1 ? (t("GroupListScreen.singularPerson")) : (t("GroupListScreen.pluralPerson"))}*/}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                {has_child == 1 && (
                                  <div className="align-self-center float-right ml-auto">
                                    <span
                                      className="text-dark"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (has_child == 1) {
                                          this.handleGroupClick(id, person);
                                        }
                                      }}
                                    >
                                      <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </InfiniteScroll>
                  )}
                </div>
              </React.Fragment>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(GeneralGGroupList));
