/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { toast } from "react-toastify";
import { MailDetailsQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";

export default class SubMailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
    };
  }

  loadMailingList = () => {
    apiCall(
      METHOD.QUERY,
      MailDetailsQuery(getUserID(), this.props.mail_id,""),
      (data) => this.handleMailListResponse(data),
      (isLoading) => this.setState({ isLoading }),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleMailListResponse = (data) => {
    this.setState((prevState) => {
      return {
        data,
      };
    });
  };

  componentDidMount() {
    this.loadMailingList();
  }
  loader = (<LoadingPage />);

  movetoMainList = (mail_id) => {
    this.props.changeMailRouteStatus(0, 0);
  };

  handleCheckBox = (e, checkMailID) => {
    if (e.target.checked) {
      this.props.addSelectedMailId(checkMailID);
    } else {
      this.props.removeSelectedMailId(checkMailID);
    }
  };

  loader = (<LoadingPage key={0} />);
  render() {
    const { child_list, name, id } = this.state.data;
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && this.loader}
        <div className="icon-box-style3 mb-2">
          <div className="ml-3 my-3 d-flex">
            <div className="d-flex w-100">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  onChange={(e) => this.handleCheckBox(e, id)}
                  checked={this.props.checkedList.includes(id)}
                />
                <span className="checkmark"></span>
              </label>
              <h5
                className="m-0 font-18 d-block pointer"
                onClick={this.movetoMainList}
              >
                {name && name}
              </h5>
            </div>
          </div>
        </div>

        {child_list &&
          child_list.map((item, index) => {
            const { name, id, person } = item;
            return (
              <div className="icon-box-style2 transparent mb-3" key={index}>
                <div className="ml-3 my-2 d-flex">
                  <div className="d-flex w-100">
                    <div className="align-self-center">
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          onChange={(e) => this.handleCheckBox(e, id)}
                          checked={this.props.checkedList.includes(id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="align-self-center">
                      <h5 className="m-0 font-18 d-block">{name && name}</h5>
                      <p className="text-muted font-12 m-0">
                        {person && person} Ansprechpartner
                      </p>
                    </div>
                    <div className="align-self-center float-right ml-auto">
                      <span className="text-dark">
                        <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }
}
