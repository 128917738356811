import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ArchiveEventQuery, deletePerson } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";

class PersonDetailSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editPersonPermission:false,
      deletePersonPermission:false,
    };
  }
componentDidMount() {
   const editPersonPermission = checkUserPermission("edit_person");
   const deletePersonPermission = checkUserPermission("delete_person");
   this.setState({
     editPersonPermission,
     deletePersonPermission,
   });
}

  handleDelete = item => {
    confirmAlert({
      title: this.props.t("personDetail.confirmTodelete"),
      message: this.props.t("personDetail.confirmTodeleteMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            const group_person_id = this.props.match.params.group_person_id;
            const fields = group_person_id.split("-");
            const group_id = fields[0];
            const person_id = fields[1];

            apiCall(
              METHOD.QUERY,
              deletePerson(getUserID(), group_id, person_id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deletePersonList({ id: person_id });
                this.props.closeSlider();
                this.props.history.push("/group-personlist/" + group_id);
              },
              (isLoading) => this.handleLoader(isLoading),
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          }
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {}
        }
      ]
    });
  };

  archiveEvent = () => {
    const event_id = this.props.match.params.event_id;

    apiCall(
      METHOD.QUERY,
      ArchiveEventQuery(getUserID(), event_id),
      (data) => {
        toast.success(this.props.t(data.message));
        this.props.closeSlider();
        this.props.deleteEvent({ id: event_id });
        this.props.history.push("/home");
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };
  handleLoader = status => {
    this.setState(() => {
      return {
        isLoading: status
      };
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    const { editPersonPermission, deletePersonPermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  {editPersonPermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeRouteStatus(1);
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("personDetail.editPerson")}
                        </p>
                        <div className="float-left rounded-40 blue-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}

                  {deletePersonPermission && (
                    <li className="nav-item">
                      <a
                        href="javascript;:"
                        className="float-right mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleDelete();
                        }}
                      >
                        <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                          {t("personDetail.deletePerson")}
                        </p>
                        <div className="float-left rounded-40 blue-bg text-center">
                          <img
                            className="m-0"
                            src="/images/meldung-neu.png"
                            alt="ico"
                          />
                        </div>
                      </a>
                    </li>
                  )}

                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.eventListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.deleteEventReportDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
    actions.asyncApiDispatchToProps
  ])
)(withTranslation()(withRouter(PersonDetailSetteliteSideMenu)));
