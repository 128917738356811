import { MAIL_PERSONLIST, DELETE_PERSON, ADD_GENERAL_PERSON, UPDATE_PERSON_COUNT } from "../constants/action-types";

export const getMailPersonList = data => ({
  type: MAIL_PERSONLIST,
  payload: data
});
export const deletePersonFromPersonList = data => ({
  type: DELETE_PERSON,
  payload: data
});
export const addGeneralPerson = data => ({
  type: ADD_GENERAL_PERSON,
  payload: data
});
export const updatePersonCount = data => ({
  type: UPDATE_PERSON_COUNT,
  payload: data
});