import { combineReducers } from "redux";
import apiReducer from "./apiCalls";
import evntListReducer from "./evntListReducer";
import groupDetailReducer from "./groupDetailReducer";
import groupReducer from "./groupReducer";
import loginDetailReducer from "./loginDetailReducer";
import mailDetailReducer from "./mailDetailReducer";
import mailPersonListReducer from "./mailPersonListReducer";
import mailReducer from "./mailReducer";
import reportListReducer from "./reportReducer";
import groupDetailPersonListReducer from "./groupDetailPersonListReducer";
import channelListReducer from "./channelListReducer";
import groupPersonDetailReducer from "./groupPersonDetailReducer";
import channelDetailReducer from "./channelDetailReducer";
import sideMenuReduce from "./sideMenuReduce";

// Combine all reducers as root reducer
export default combineReducers({
  apiReducer,
  loginDetailReducer,
  reportListReducer,
  evntListReducer,
  groupReducer,
  mailReducer,
  mailDetailReducer,
  mailPersonListReducer,
  groupDetailReducer,
  groupDetailPersonListReducer,
  channelListReducer,
  groupPersonDetailReducer,
  channelDetailReducer,
  sideMenuReduce,
});
