/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import UserList from "./UserList";
import AddUser from "./AddUser";
import EditUser from "./EditUser";

 class Index extends Component {
   constructor(props) {
     super(props);
     this.state = {
       routeStatus: 0,
       userValue: {},
     };
   }

   changeStatus = (type) => {
     this.setState({
       routeStatus: type,
     });
   };

   changeStatusWithStatusValue = (type, userValue) => {
     this.setState({
       routeStatus: type,
       userValue,
     });
   };

   render() {
     const { routeStatus, userValue } = this.state;
     return (
       <div className="wrapper position-relative">
         {routeStatus == 0 && (
           <UserList
             changeStatusWithStatusValue={this.changeStatusWithStatusValue}
           />
         )}

         {routeStatus == 1 && <AddUser changeRouteStatus={this.changeStatus} />}

         {routeStatus == 2 && (
           <EditUser changeRouteStatus={this.changeStatus} item={userValue} />
         )}
       </div>
     );
   }
 }
export default (withRouter(Index));