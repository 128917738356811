/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";

class SelectionType extends Component {
  render() {
    const { option_list, handleOptionText, handleOptionDelete, addSelectionOption} = this.props
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <h4>{t("AdminSetteliteSideMenu.option")}</h4>

              <div className="form-group">
                <label className="font-14">
                  {t("AdminSnippetSlectiontype.Option")}
                </label>

                <React.Fragment>
                  {option_list && option_list.map((option, index) => (
                    <div className="form-row">
                      <div className="col-8">
                        <span key={index}>
                          <input
                            type="text"
                            className="form-control mb-2"
                            onChange={handleOptionText(index)}
                            value={option}
                          />
                        </span>
                      </div>
                      <div className="col-4 ">
                        {index != 0 && <i className="zmdi zmdi-close zmdi-hc-fw font-24 pointer"
                          onClick={handleOptionDelete(index)}></i>}
                        </div>
                      </div>
                  ))}
                  <div className="rounded-40 rounded-red float-center pointer " onClick={addSelectionOption}>
                    <i className="zmdi zmdi-plus zmdi-hc-fw font-22"></i>
                  </div>
                </React.Fragment>

                <ul className="list-unstyled mb-0 mt-3">
                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.allow_custom_option")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(
                              e,
                              "allow_custom_option"
                            )
                          }
                          checked={this.props.allow_custom_option}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.allow_zero")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(e, "allow_zero")
                          }
                          checked={this.props.allow_zero}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.hide_on_zero")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(
                              e,
                              "hide_on_zero"
                            )
                          }
                          checked={this.props.hide_on_zero}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.is_multiselect")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(
                              e,
                              "is_multiselect"
                            )
                          }
                          checked={this.props.is_multiselect}
                        />
                      </div>
                    </div>
                  </li>

                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span className="font-size-14">
                          {t("AdminSnippetSlectiontype.concatinate")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleSelectionCheckBox(e, "concatinate")
                          }
                          checked={this.props.concatinate}
                        />
                      </div>
                    </div>
                  </li>
                </ul>

                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    this.props.handleSelectionInputtextValue(e)
                  }
                  name="concatinate_value"
                  value={this.props.concatinate_value}
                  placeholder={t("AdminSnippetSlectiontype.concatinate_value")}
                />
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(SelectionType));

 