/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class PersonItem extends Component {
  ItemClick = (id) => {
    const { pathname } = this.props.location;
    const location = {
      pathname: "/person-detail/" + id,
      state: { from: { pathname: pathname } },
    };
    this.props.history.push(location);
  };

  render() {
    const { id, full_name, email, is_valid_email } = this.props.data;
    return (
      <React.Fragment>
        <a
          href="javascript;:"
          onClick={(e) => {
            const group_id_person_id =
              this.props.match.params.group_id + "-" + id;
            e.preventDefault();
            this.ItemClick(group_id_person_id);
          }}
          className="d-block flex-fill mb-4"
        >
          <div className="rounded-40 float-left">
            {is_valid_email && is_valid_email == 1 ? (
              <i className="zmdi zmdi-check-circle zmdi-hc-fw font-22 text-success"></i>
            ) : (
              <i className="zmdi zmdi-alert-circle zmdi-hc-fw font-22 text-blue"></i>
            )}
          </div>
          <div className="pl-2 pt-1 d-flex">
            <div className="d-flex w-100">
              <div className="clearfix">
                <h6 className="m-0">{full_name && full_name}</h6>
                <p className="text-muted font-12 m-0">{email && email}</p>
              </div>
              <div className="align-self-center float-right ml-auto">
                <span className="text-dark">
                  <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                </span>
              </div>
            </div>
          </div>
        </a>
      </React.Fragment>
    );
  }
}
export default withRouter(PersonItem);
