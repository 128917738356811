import React, { Component } from 'react'
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from 'react-router';

 class MailDetailItemListView extends Component {
    handelMailDetailItemClick = (mail_list_id) => {
        const { pathname } = this.props.location;
        const location = {
          pathname: "/mail-details-personlist/" + mail_list_id,
          state: { from: { pathname: pathname } },
        };
        this.props.history.push(location);
      };

      
    render() {
        const {item,id} = this.props
        const { person, name, list_type_icon } = item;
                      let mail_list_id = item.id + "-" + id;
        return (
            <Translation ns="translation">
            {(t, { i18n }) => {
              return (
            <a
                          href="javascript;:"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handelMailDetailItemClick(mail_list_id);
                          }}
                          className="mb-4 d-block"
                        >
                          <div className="rounded-40 yellow-bg float-left ml-5p">
                            <span className="text-light">
                              <img
                                src={`data:image/png;base64,${
                                  list_type_icon && list_type_icon
                                }`}
                                alt="ico"
                                style={{ width: "24px", height: "24px" }}
                              />
                            </span>
                          </div>
                          <div className="pl-3 pt-1 d-flex">
                            <div className="w-100">
                              <h6 className="m-0">{name && name}</h6>
                              <p className="text-muted font-12 mb-2">
                                {person && person} {t("mailDetail.receiver")}
                              </p>
                            </div>
                          </div>
                        </a>
                        );
        }}
      </Translation>
        )
    }
}
export default withRouter(withTranslation()(MailDetailItemListView))