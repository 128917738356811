/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import AddNewMail from "./AddNewMail";
import MailingList from "./MailingList";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectType: 0,
    };
  }

  changeStatus = (type) => {
    this.setState({
      selectType: type,
    });
  };

  render() {
    const { selectType } = this.state;
    return (
      <div className="wrapper position-relative">
        {selectType == 0 ? (
          <MailingList changeStatus={this.changeStatus} />
        ) : (
          <AddNewMail changeStatus={this.changeStatus} />
        )}
      </div>
    );
  }
}
