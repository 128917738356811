import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { removepersonfrommailinglist } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { toast } from 'react-toastify';
import { getUserID } from '../../util/utils';
import { withTranslation } from 'react-i18next';

class ContactPersonDistributor extends Component {
  deletelist = (id) => {
    const group_person_id = this.props.match.params.group_person_id;
    const fields = group_person_id.split("-");
    const person_id = fields[1];
    confirmAlert({
      title: this.props.t("personDetail.deleteDistributor"),
      message: this.props.t("personDetail.deleteDistributorMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              removepersonfrommailinglist(getUserID(), person_id, id),
              (data) => {
                toast.success(this.props.t(data.message));
                this.props.deleteMailList(id)
              },
              (isLoading) => {
                this.setState({ deleteLoader: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => { },
        },
      ],
    });
  }
  render() {

    const { mailing_list } =
      this.props.groupPersonDetail && this.props.groupPersonDetail;
    return (
      <React.Fragment>
        {mailing_list && mailing_list.map((item, index) => {
          const {
            id,
            name,
            list_creator_name,
            list_type_icon
          } = item
          return (
            <a href="javascript;:" onClick={(e)=>{e.preventDefault()}} className="d-flex" key={index}>
              <div className="icon-box-style2 mb-2">
                <div className="icon-box yellow-bg d-flex">
                  <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="rounded-50 bg-light">
                      <img
                        src={`data:image/png;base64,${list_type_icon &&
                          list_type_icon}`}
                        alt="icon"
                        style={{ width: "26px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="ml-3 my-3 d-inline-block">
                  <h5 className="m-0 font-18">{name && name} </h5>
                  <p className="text-muted font-12 mb-0">
                    {list_creator_name && list_creator_name}
                  </p>
                </div>
                <div className="mr-3 my-3 ml-auto align-self-center" onClick={() => { this.deletelist(id) }}>
                  <img
                    src="/images/trash-simple.png"
                    alt="delete icon"
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>
              </div>
            </a>
          );
        })}
      </React.Fragment>
    );
  }
}
export default connect(
  state =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupPersonDetailStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.groupPersonDetailDispatchToProps
  ])
)(withRouter(withTranslation()(ContactPersonDistributor)));