import React from "react";

const HeaderGuest = props => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark header-before-login">
      <div className="container">
        <a href="test.js" className="navbar-brand py-3">
          <img src="images/logo.png" alt="Company Logo" />
        </a>
      </div>
    </nav>
  );
};

export default HeaderGuest;
