/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EditEmail from "./EditEmail";
import EmailList from "./EmailList";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      userValue: {},
    };
  }

  changeStatus = (type) => {
    this.setState({
      routeStatus: type,
    });
  };

  changeStatusWithStatusValue = (type, userValue) => {
    this.setState({
      routeStatus: type,
      userValue,
    });
  };

  render() {
    const { routeStatus, userValue } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <EmailList
            changeStatusWithStatusValue={this.changeStatusWithStatusValue}
          />
        )}

        {routeStatus == 2 && (
          <EditEmail changeRouteStatus={this.changeStatus} item={userValue} />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
