import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/index";
import Notifications from "react-notify-toast";
import { ApolloProvider } from "react-apollo";
import { client } from "./constants/baseUrl";
import { ToastContainer } from "react-toastify";
import "./constants/i18n";
ReactDOM.render(
  
  <ApolloProvider client={client}>
    <ToastContainer
      position="bottom-right"
      autoClose={1500}
      hideProgressBar={true}
      newestOnTop={false}
      closeButton={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
    <Provider store={store}>
      <Notifications options={{ zIndex: 9999, top: "20px" }} />
        <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
