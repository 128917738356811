/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { adminUserList, deleteUser } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import UserSetteliteSideMenu from "./UserSetteliteSideMenu";

class UserList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            user_list: [],
            start: 0,
            end: 10,
            hasMore: true,
            deleteLoadingStatus: false,
            headerMenuStatus: false,
        };
    }

    loadSnippet = () => {
        const { start, end, hasMore, isLoading } = this.state;
        if (!isLoading && hasMore) {
            this.setState({
                isLoading: true,
            });

            apiCall(
                METHOD.QUERY,
                adminUserList(getUserID(), start, end),
                (data) => this.handleUserApiResponse(data),
                (isLoading) => {
                },
                (data) => {
                    toast.error(this.props.t(data.message));
                }
            );
        }
    };

    handleUserApiResponse = (data) => {
        const { start, end } = this.state;
        if (data.is_last == 1) {
            this.setState({ hasMore: false });
        }

        this.setState((prevState) => {
            return {
                ...this.state,
                start: start + end,
                user_list: [...prevState.user_list, ...data.user_list],
                isLoading: false,
            };
        });
    };

    componentDidMount() {
        if(!checkAdminPermission()){
            this.props.history.push("/")
          }
    }
    openSlider = () => {
        this.setState(() => {
            return {
                ...this.state,
                headerMenuStatus: true,
            };
        });
    };
    handleUserApproval = (item) => {

        let tempItem = item;
        tempItem["is_approve"] = true
        this.props.changeStatusWithStatusValue(
            2,
            tempItem
        );

    }
    handleDelete = (item) => {
        const { id } = item;

        confirmAlert({
            title: this.props.t("administration.confirmTodeleteUser"),
            message: this.props.t("administration.confirmTodeleteUserMessage"),
            buttons: [
                {
                    label: this.props.t("personDetail.yes"),
                    onClick: () => {
                        apiCall(
                            METHOD.QUERY,
                            deleteUser(getUserID(), id),
                            (data) => {
                                let afterDeletedUser_list = this.state.user_list.filter(
                                    (user) => {
                                        return user.id != item.id;
                                    }
                                );

                                toast.success(this.props.t(data.message));
                                this.setState({
                                    user_list: afterDeletedUser_list,
                                });
                            },
                            (isLoading) => {
                                this.setState({ deleteLoadingStatus: isLoading });
                            },
                            (data) => {
                                toast.error(this.props.t(data.message));
                            }
                        );
                    },
                },
                {
                    label: this.props.t("personDetail.no"),
                    onClick: () => {
                    },
                },
            ],
        });
    };
    getColorBaseonStatus = (status) => {
        if (status == 0) {
            return ('rounded-40 red-bg float-left ml-5p mb-2')
        } else if (status == 1) {
            return ('rounded-40 green-bg float-left ml-5p mb-2')
        } else {
            return ('rounded-40 yellow-bg float-left ml-5p mb-2')
        }
    }
// ""
closeSlider = () => {
    this.setState(() => {
        return {
            headerMenuStatus: false,
        };
    });
};

handleLoader = (status) => {
    this.setState({
        isLoading: status,
    });
};
loader = (<LoadingPage key={0} />);
render() {
    const { hasMore, end, user_list, deleteLoadingStatus } = this.state;

    return (
        <Translation ns="translation">
            {(t, { i18n }) => {
                return (
                    <React.Fragment>
                        <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                            <div className="container d-flex flex-column flex-fill">
                                <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                                    <TopHeader />
                                    <p className="mb-0 ml-auto text-light">
                                        <Link to="/administration" className='text-light'>
                                            {t("AdminAddUser.user_list_title")}
                                        </Link>
                                    </p>
                                    <LogoutMenu />
                                </div>
                            </div>
                        </div>

                        <div className="middle-section sab-middle-section1">
                            {deleteLoadingStatus && this.loader}

                            <InfiniteScroll
                                pageStart={end}
                                loadMore={this.loadSnippet}
                                hasMore={hasMore}
                                className="left-line left-line-custom"

                                useWindow={false}
                                loader={this.loader}
                            >
                                {user_list &&
                                    user_list.map((item, index) => {
                                        const {name, group_name, status } = item;
                                        return (
                                            <div className={index == user_list.length - 1 ? ("container my-4 z-index-99 last") : ("container my-4 z-index-99 ")} key={index}>

                                                <a
                                                    href="javascript;:"
                                                    className="mb-4 d-block custom-hover-box"
                                                    key={index}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <div className={this.getColorBaseonStatus(status)}>
                                                        <span className="text-light">
                                                        </span>
                                                    </div>
                                                    <div className="pl-3 pt-1 d-flex" key={index}>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <div className="clearfix">
                                                                <h6 className="m-0">{name && name}</h6>
                                                                <p className="text-muted font-12 mb-2">
                                                                    {group_name && group_name}
                                                                </p>
                                                            </div>
                                                            <div className="action-btns clearfix">
                                                                {status != 2 ? (<i
                                                                    className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.changeStatusWithStatusValue(
                                                                            2,
                                                                            item
                                                                        );
                                                                    }}
                                                                />) : (
                                                                        <img src="/images/profile_approve_icon.png"
                                                                         alt=""   className="pointer mr-3"
                                                                            style={{ width: '24px', height: '24px' }}
                                                                            onClick={() => {
                                                                                this.handleUserApproval(item)
                                                                            }} />
                                                                    )}

                                                                <i
                                                                    className="zmdi zmdi-delete zmdi-hc-lg mr-3 pointer"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleDelete(item);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                            </InfiniteScroll>
                            <UserSetteliteSideMenu
                                closeSlider={this.closeSlider}
                                headerMenuStatus={this.state.headerMenuStatus}
                                changeRouteStatus={this.props.changeStatusWithStatusValue}
                            />
                            <FabIcon color="rounded-red" openSlider={this.openSlider} />
                        </div>

                        <Footer />
                    </React.Fragment>
                );
            }}
        </Translation>
    );
}
}

export default withRouter(withTranslation()(UserList));