import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import { withRouter } from "react-router-dom";

class UserSetteliteSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  render() {
    const { headerMenuStatus, closeSlider } = this.props;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <Bounce bottom when={headerMenuStatus}>
              <div
                className={
                  headerMenuStatus
                    ? "navbar-collapse collapse text-left full-toggler background-trans show"
                    : "navbar-collapse collapse text-left background-trans full-toggler"
                }
                id="Brand-toggler"
              >
                <button
                  className="navbar-toggler icon-close collapsed ml-3 mt-3"
                  onClick={closeSlider}
                  type="button"
                  data-toggle="collapse"
                  data-target="#Brand-toggler"
                  aria-controls="Brand-toggler"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw font-30"></i>
                </button>
                <ul className="navbar-nav fab-icon">
                  <li className="nav-item">
                    <a
                      href="javascript;:"
                      className="float-right mr-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.changeRouteStatus(1, {});
                      }}
                    >
                      <p className="float-left mr-3 mt-2 font-14 text-dark mb-0">
                        {t("UserSetteliteSideMenu.createNewUser")}
                      </p>
                      <div className="float-left rounded-40 red-bg text-center">
                        <img
                          className="m-0"
                          src="/images/meldung-neu.png"
                          alt="ico"
                        />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Bounce>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(UserSetteliteSideMenu));
