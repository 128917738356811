import {
  MAILING_LIST,
  MAILING_START_VALUE,
  MAILING_HASMORE_VALUE,
  ADD_NEW_MAIL
} from "../constants/action-types";

const initialState = {
  mailList: [],
  start: 0,
  hasMore: true
};
export default function mailReducer(state = initialState, action) {
  switch (action.type) {
    case MAILING_LIST:
      return {
        ...state,
        mailList: [...state.mailList, ...action.payload]
      };
    case MAILING_START_VALUE:
      return { ...state, start: action.payload };
    case MAILING_HASMORE_VALUE:
      return { ...state, hasMore: action.payload };
    case ADD_NEW_MAIL:
      return {
        ...state,
        mailList: [...action.payload, ...state.mailList]
      };

    default:
      return state;
  }
}
