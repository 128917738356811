/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddPerson from "./AddPerson";
import EditNewGroup from "./EditNewGroup";
import PersonList from "./PersonList";
import RearrangePerson from "./RearrangePerson";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0
    };
  }
  changeRouteStatus = value => {
    this.setState(prevState => {
      return {
        routeStatus: value
      };
    });
  };
  render() {
    const { routeStatus } = this.state;
    return (
      <React.Fragment>
        <div className="wrapper position-relative">
          {routeStatus == 0 && (
            <PersonList changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 1 && (
            <AddPerson changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 2 && (
            <EditNewGroup changeRouteStatus={this.changeRouteStatus} />
          )}
          {routeStatus == 3 && (
            <RearrangePerson changeRouteStatus={this.changeRouteStatus} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(Index);
