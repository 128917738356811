/* eslint eqeqeq: 0 */
import {
  ADD_NEW_PERSONLIST,
  GROUP_DETAIL_DELETE,
  GROUP_PERSONLIST,
  GROUP_PERSONLIST_CLEARSTATE,
  GROUP_PERSONLIST_HASMORE,
  GROUP_PERSONLIST_START,
  REARRANGE_GROUP_PERSON_LIST,
  UPDATE_PERSON_DETAILS,
  UPDATE_PERSON_LIST,
} from "../constants/action-types";

const initialState = { person_list: [], hasMore: true, start: 0 };
export default function groupDetailPersonListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GROUP_PERSONLIST:
      return { ...state, ...action.payload };
    case UPDATE_PERSON_LIST:
      return {
        ...state,
        person_list: [...state.person_list, ...action.payload.person_list],
      };
    case ADD_NEW_PERSONLIST:
      return {
        ...state,
        person_list: [...action.payload, ...state.person_list],
      };
    case REARRANGE_GROUP_PERSON_LIST:
      return {
        ...state,
        person_list: [...action.payload],
      };
    case GROUP_PERSONLIST_HASMORE:
      return { ...state, hasMore: action.payload };
    case GROUP_PERSONLIST_START:
      return { ...state, start: action.payload };
    case GROUP_PERSONLIST_CLEARSTATE:
      return initialState;
    case GROUP_DETAIL_DELETE:
      let deletedState = state.person_list.filter((item) => {
        return item.id != action.payload.id;
      });
      return { ...state, person_list: deletedState };
    case UPDATE_PERSON_DETAILS:
      return {
        ...state,
        ...{
          name: action.payload.name,
          description: action.payload.description,
          group_type_icon: action.payload.group_type_icon,
          parent_id: action.payload.parent_id,
        },
      };
    default:
      return state;
  }
}
