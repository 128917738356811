/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addgrouppersonintomailinglist,
  GroupListQuery,
} from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID, removeDuplicates } from "../../util/utils";
import LoadingPage from "../CommanComponents/LoadingPage";

class GroupListAddContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_list: [],
      start: 0,
      end: 15,
      hasMore: true,
      selectedGroupList: [],
      isLoading: false,
      groupLinkLoader: false,
      parent_group: true,
      breadcrumb: [],
      group_id: 0,
    };
  }

  loadGroupList = () => {
    const { start, end, hasMore, isLoading, group_id } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        GroupListQuery(getUserID(), start, end, group_id, "", 1),
        (data) => this.handleGroupListResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleGroupListResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_group_list = this.state.group_list;
    old_group_list = old_group_list.concat(data.group_list);
    old_group_list = removeDuplicates(old_group_list, "id");

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        group_list: old_group_list,
        breadcrumb: data.breadcrumb,
        isLoading: false,
      };
    });
  };

  handleSubmit = () => {
    const mail_list_id = this.props.match.params.mail_person_id;
    const fields = mail_list_id.split("-");
    const list_id = fields[0];
    const { selectedGroupList } = this.state;
    let group_id = selectedGroupList.join(",");

    if (selectedGroupList.length == 0) {
      toast.error(this.props.t("personDetail.validateGroupSelection"));
    } else {
      apiCall(
        METHOD.QUERY,
        addgrouppersonintomailinglist(list_id, getUserID(), group_id),
        (data) => this.handleResponse(data),
        (isLoading) => {
          this.setState({ groupLinkLoader: isLoading });
        },
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };
  handleResponse = (data) => {
    toast.success(this.props.t(data.message));
    this.props.changeRouteStatus(0);
  };
  loader = (<LoadingPage key={0} />);

  handleCheckBox = (e, group_id) => {
    if (e.target.checked) {
      this.addSelectedGroupID(group_id);
    } else {
      this.removeSelectedGroupID(group_id);
    }
  };

  addSelectedGroupID = (group_id) => {
    this.setState((prev) => {
      return {
        selectedGroupList: [...prev.selectedGroupList, group_id],
      };
    });
  };

  handleGroupClick = (group_id) => {
    this.setState(
      {
        start: 0,
        group_list: [],
        parent_group: group_id == 0 ? true : false,
        hasMore: true,
        group_id,
      },
      () => {
        this.loadGroupList();
      }
    );
  };

  removeSelectedGroupID = (group_id) => {
    let afterRemoveGroupList = this.state.selectedGroupList.filter((id) => {
      return id != group_id;
    });

    this.setState({
      selectedGroupList: afterRemoveGroupList,
    });
  };

  render() {
    const {
      end,
      hasMore,
      group_list,
      groupLinkLoader,
      selectedGroupList,
      parent_group,
      breadcrumb,
    } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <React.Fragment>
                <div className="header-bg header-yellow sab-header1 sticky-top">
                  <div className="container">
                    <div className="py-2 d-flex align-items-center">
                      <span
                        className="pointer"
                        onClick={() => this.props.changeRouteStatus(0)}
                      >
                        <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                      </span>
                      {groupLinkLoader ? (
                        <div className="btn btn-light font-13 ml-auto pointer disabled">
                          {t("MailDetailScreen.btnSave")}
                          <div className="spinner-border spinner-border-sm ml-1 text-blue">
                            <span className="sr-only">
                              {t("GeneralMessages.loading")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="btn btn-light font-13 ml-auto pointer"
                          onClick={() => {
                            this.handleSubmit();
                          }}
                        >
                          {t("MailDetailScreen.btnSave")}
                        </div>
                      )}
                    </div>
                    <div className="text-center mt-4 text-light">
                      <h2 className="m-0 text-light">
                        {t("personDetail.addGroupContacts")}
                      </h2>
                    </div>
                  </div>
                </div>

                {!parent_group && (
                  <div className="container my-4 z-index-99">
                    <div className="d-block flex-fill z-index-9999">
                      <div className="ml-2 my-2 mb-3 d-flex">
                        <div className="d-flex w-100">
                          <div className="clearfix d-flex0 0align-items-center">
                            <p className="font-12 m-0">
                              <React.Fragment>
                                <a
                                  href="javascript;:"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleGroupClick(0);
                                  }}
                                  className="text-muted"
                                  data-tip={"Start"}
                                >
                                  {t("GroupListScreen.start")}
                                  <ReactTooltip
                                    place="top"
                                    type="info"
                                    effect="float"
                                  />
                                </a>
                                {" > "}
                              </React.Fragment>
                              {breadcrumb &&
                                breadcrumb.map((item, index) => {
                                  if (index == 0) {
                                    return (
                                      <React.Fragment key={index}>
                                        <a
                                          href="javascript;:"
                                          className="text-muted"
                                          data-tip={item.name}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(item.id);
                                          }}
                                        >
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                          {item.name}
                                        </a>
                                        {" > "}
                                      </React.Fragment>
                                    );
                                  } else if (
                                    index != 0 &&
                                    index != breadcrumb.length - 1
                                  ) {
                                    return (
                                      <React.Fragment key={index}>
                                        <a
                                          href="javascript;:"
                                          className="text-muted"
                                          data-tip={item.name}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGroupClick(item.id);
                                          }}
                                        >
                                          <ReactTooltip
                                            place="top"
                                            type="info"
                                            effect="float"
                                          />
                                          [...]
                                        </a>

                                        {index != breadcrumb.length - 1 &&
                                          " > "}
                                      </React.Fragment>
                                    );
                                  } else if (index == breadcrumb.length - 1) {
                                    return (
                                      <React.Fragment key={index}>
                                        {item.name && item.name}
                                      </React.Fragment>
                                    );
                                  }
                                })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="middle-section sab-middle-section1">
                  <InfiniteScroll
                    pageStart={end}
                    loadMore={this.loadGroupList}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={this.loader}
                  >
                    <div className="</div>container my-2">
                      {group_list &&
                        group_list.map((item, index) => {
                          const { name, id,  has_child } = item;
                          return (
                            <div className="icon-box-style3 mb-2 pointer"  key={index}  >
                              <div className="ml-3 my-2 d-flex pointer" >
                                <div className="d-flex w-100">
                                  <div className="align-self-center">
                                    <label className="checkbox-container">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>{this.handleCheckBox(e, id)}}
                                        checked={selectedGroupList.includes(id)}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  <a
                                    href="#"
                                    className="clearfix d-flex align-items-center flex-fill"
                                    onClick={(e) => {
                                      if (has_child == 1) {
                                        this.handleGroupClick(id);
                                      }
                                    }}
                                  >
                                    <div className="align-self-center pointer">
                                      <h5 className="m-0 font-18 d-block">
                                        {name && name}
                                      </h5>
                                      <p className="text-muted font-12 m-0">
                                        {/*                                        {person && person}  {" "}     {person == 1 ? (t("GroupListScreen.singularPerson")) : (t("GroupListScreen.pluralPerson"))}*/}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {has_child == 1 && (
                                <div className="align-self-center float-right ml-auto" 
                               >
                                  <span className="text-dark"  onClick={(e) => {
                              e.preventDefault()
                              if (has_child == 1) {
                                this.handleGroupClick(id);
                              }
                            }}>
                                    <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </InfiniteScroll>
                </div>
              </React.Fragment>
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(GroupListAddContacts));
