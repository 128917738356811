import React, { Component } from 'react'
import { Helmet } from "react-helmet";

export default class ProductionHelmetTag extends Component {
    render() {
        return (
          <React.Fragment>
            <Helmet>
              <meta charset="utf-8" />
              <link
                rel="icon"
                type="image/png"
                href="/images/favicon.ico"
                sizes="16x16"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta name="description" content="URGENTpublish crisis communication" />
              <meta name="robots" content="noindex nofollow" />

              <script src="/assets/plugins/jquery/jquery-3.4.1.slim.min.js"></script>
              <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>
              
              <link rel="apple-touch-icon" href="/logo192.png" />
              <link rel="manifest" href="/manifest.json" />
              <title>URGENTpublish</title>

              <link rel="icon" href="/images/favicon.ico" type="image/x-icon" />
              <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
              <link
                rel="stylesheet"
                href="/material-font/css/material-design-iconic-font.min.css"
              />
              <script type="text/jsx" src="/js/mdb.min.js"></script>
              <link rel="stylesheet" href="/css/bootstrap.min.css" />
              <link
                rel="stylesheet"
                href="/css/bootstrap-fileinput/bootstrap-fileinput.css"
              />
              <link rel="stylesheet" href="/css/datepicker3.css" />
              <link rel="stylesheet" href="/css/select2.min.css" />
              <link
                rel="stylesheet"
                href="/css/style.css"
                crossorigin="anonymous"
              />
              
             

            </Helmet>
          </React.Fragment>
        );
    }
}
