/* eslint eqeqeq: 0 */

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import React, { Component } from "react";
import { ApolloProvider, Mutation } from "react-apollo";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadLink } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import Footer from "../../CommanComponents/Footer";

const apolloCache = new InMemoryCache();
const client_mutation = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

const Addeventtype = gql`
  mutation Addeventtype($profile: AddeventtypeInput!) {
    Addeventtype(input: $profile) {
      success
      message
    }
  }
`;

class AddEventType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: undefined,
      icon_file: undefined,
      reportDialogueStatus: false,
      name: "",
      description: "",
      identifier: "",
    };
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else {
      this.setState({
        identifier: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  getIconUrl = (file, uploadFile) => {
    let images_extensions = ["jpg", "jpeg", "png"];
    let image_extension = file.type.split("/")[1];
    const result = images_extensions.indexOf(image_extension);
    if (result == -1) {
      toast.info(`${image_extension} file is not supported`);
    } else {
      this.setState({
        icon: URL.createObjectURL(file),
        icon_file: uploadFile,
      });
    }
  };
  render() {
    const { icon, name, description, identifier } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <ApolloProvider client={client_mutation}>
              <Mutation mutation={Addeventtype}>
                {(Addeventtype, { data, error, loading }) => {
                  if (data) {
                    if (data.Addeventtype.success == 0) {
                      toast.error(this.props.t(data.Addeventtype.message));
                    } else {
                      toast.success(this.props.t(data.Addeventtype.message));
                      this.props.changeRouteStatus(0, {});
                    }
                  }
                  if (error) {
                    toast.error(
                      i18n.getDataByLanguage("en").translation.GeneralMessages
                        .errorServerisNotResponding &&
                        i18n.getDataByLanguage("en").translation.GeneralMessages
                          .errorServerisNotResponding
                    );
                  }

                  return (
                    <React.Fragment>
                      <div className="header-bg header-green sab-header1 sticky-top">
                        <div className="container">
                          <div className="py-2 d-flex align-items-center">
                            <span>
                              <i
                                className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                                onClick={() =>
                                  this.props.changeRouteStatus(0, {})
                                }
                              ></i>
                            </span>

                            {loading ? (
                              <div className="btn btn-light font-13 ml-auto pointer">
                                {t("AdminAddUser.save")}
                                <div className="spinner-border spinner-border-sm ml-1 text-blue">
                                  <span className="sr-only">
                                    {t("GeneralMessages.loading")}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="btn btn-light font-13 ml-auto pointer"
                                onClick={() => {
                                  const {
                                    name,
                                    description,
                                    identifier,
                                    icon_file,
                                  } = this.state;
                                  if (!name) {
                                    toast.info(
                                      this.props.t(
                                        "AdminEventListType.nameValidation"
                                      )
                                    );
                                  } else if (!description) {
                                    toast.info(
                                      this.props.t(
                                        "AdminEventListType.descriptionValidation"
                                      )
                                    );
                                  } else if (!identifier) {
                                    toast.info(
                                      this.props.t(
                                        "AdminEventListType.identifierValidation"
                                      )
                                    );
                                  } else if (!icon_file) {
                                    toast.info(
                                      this.props.t(
                                        "AdminEventListType.iconValidation"
                                      )
                                    );
                                  } else {
                                    let obj = {
                                      variables: {
                                        profile: {
                                          user_id: getUserID(),
                                          id: null,
                                          name,
                                          description,
                                          identifier,
                                          icon: icon_file,
                                        },
                                      },
                                    };
                                    Addeventtype(obj);
                                  }
                                }}
                              >
                                {t("AdminAddUser.save")}
                              </div>
                            )}
                          </div>
                          <div className="text-center mt-4 text-light">
                            <h2 className="m-0 text-light">
                              {t("AdminEventListType.addTitle")}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="middle-section sab-middle-section1">
                        <div className="container my-4 z-index-99">
                          <div className="form-group mb-5">
                            <div className="form-group">
                              <div className="container my-4 00z-index-99">
                                <form>
                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("AdminEventListType.nameTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "name")
                                      }
                                      name="name"
                                      value={name && name}
                                      placeholder={t(
                                        "AdminEventListType.namePlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("AdminEventListType.descriptionTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "description")
                                      }
                                      name="description"
                                      value={description && description}
                                      placeholder={t(
                                        "AdminEventListType.descriptionPlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("AdminEventListType.identifierTitle")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleTextChange(e, "identifier")
                                      }
                                      name="identifier"
                                      value={identifier && identifier}
                                      placeholder={t(
                                        "AdminEventListType.identifierPlaceHolder"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="font-14">
                                      {t("AdminEventListType.iconTitle")}
                                    </label>

                                    <div
                                      className="fileinput text-center fileinput-new d-flex align-items-center"
                                      data-provides="fileinput"
                                    >
                                      <div className="btn-file">
                                        <div
                                          className="thumbnail fileinput-new "
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            background: "#999",
                                          }}
                                        >
                                          <img
                                            src={
                                              icon
                                                ? icon
                                                : "/images/no-image.png"
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <input
                                          type={"file"}
                                          file-model="myFile"
                                          name="image"
                                          accept=""
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            this.getIconUrl(
                                              event.currentTarget.files[0],
                                              file
                                            );
                                          }}
                                        />

                                        <div
                                          className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "100%",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Footer type="2" />
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </ApolloProvider>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(AddEventType));
