/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */
import React, { useEffect, useState } from "react";
import {
  initReactI18next,
  Translation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { withRouter } from "react-router";

function ChangeLanguage(props) {
  const [language, setLanguage] = useState(0);
  const { t, i18n } = useTranslation("translation");

  const handleBackStack = () => {
    let { from } = props.location.state || { from: { pathname: "/" } };
    props.history.push(from);
  };
  console.log(t)
  useEffect(() => {
    i18n.changeLanguage(language);
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({ lng: language });
  }, [language,i18n]);

  const handleRadioButton = (e, language) => {
    if (e.target.checked) {
      setLanguage(language);
    }
  };
  const checkedStatus = (lang) => {
    if (language == lang) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Translation ns="translation">
      {(t, { i18n }) => {
        return (
          <div className="wrapper position-relative">
            <div className="header-bg header-blue sab-header1 sticky-top">
              <div className="container">
                <div className="py-2 d-flex align-items-center">
                  <span>
                    <i
                      className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                      onClick={() => handleBackStack()}
                    ></i>
                  </span>
                </div>
                <div className="text-center mt-4 text-light">
                  <h2 className="m-0 text-light">
                    {t("logOutMenu.changeLanguage")}
                  </h2>
                </div>
              </div>
            </div>

            <div className="middle-section sab-middle-section1">
              <div className="icon-box-style3 mb-2 ">
                <div className="ml-3 my-2 d-flex">
                  <div className="d-flex w-100">
                    <div className="align-self-center">
                      <label className="checkbox-container">
                        <input
                          type="radio"
                          onChange={(e) => handleRadioButton(e, "en")}
                          checked={checkedStatus("en")}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <a
                      href="javascript;:"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="clearfix d-flex align-items-center flex-fill"
                    >
                      <div className="align-self-center d-flex">
                        <img
                          src="/images/en_lang_icon.png"
                          className="mr-2"
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                        <h5 className="m-0 font-18 d-block">
                          {t("logOutMenu.lblEnglish")}
                        </h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="icon-box-style3 mb-2 ">
                <div className="ml-3 my-2 d-flex">
                  <div className="d-flex w-100">
                    <div className="align-self-center">
                      <label className="checkbox-container">
                        <input
                          type="radio"
                          onChange={(e) => handleRadioButton(e, "de")}
                          checked={checkedStatus("de")}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <a
                      href="javascript;:"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="clearfix d-flex align-items-center flex-fill"
                    >
                      <div className="align-self-center d-flex">
                        <img
                          src="/images/de_lang_icon.png"
                          className="mr-2"
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />

                        <h5 className="m-0 font-18 d-block">
                          {t("logOutMenu.lblGermen")}
                        </h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Translation>
  );
}
export default withRouter(withTranslation()(ChangeLanguage));
