/* eslint eqeqeq: 0 */
/* eslint eqeqeq: 0 */

import {
  ADD_GENERAL_PERSON,
  DELETE_PERSON,
  MAIL_PERSONLIST,
  UPDATE_PERSON_COUNT,
} from "../constants/action-types";

const initialState = {
  personList: {},
  start: 0,
  hasMore: true,
};
export default function mailPersonListReducer(state = initialState, action) {
  switch (action.type) {
    case MAIL_PERSONLIST:
      return { ...action.payload };
    case DELETE_PERSON:
      let deletedState = state.person_list.filter((item) => {
        return item.id != action.payload.id;
      });
      return { ...state, person_list: deletedState };
    case ADD_GENERAL_PERSON:
      return {
        ...state,
        person_list: [...state.person_list, ...action.payload],
      };

    case UPDATE_PERSON_COUNT:
      return { ...state, person: action.payload };

    default:
      return state;
  }
}
