/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { assignMailingListtoChannelMessage } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import { getUserID } from "../../util/utils";
import Footer from "../CommanComponents/Footer";
import MailList from "./MailList";
import SubMailList from "./SubMailList";

class ChannelMailLinkIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routeStatus: 0,
      mail_id: 0,
      selectedMailList: [],
      isLoading: false,
    };
  }

  handleLoader = (status) => {
    this.setState(() => {
      return {
        isLoading: status,
      };
    });
  };
  handleClick = () => {
    const { selectedMailList } = this.state;
    const message_id = this.props.match.params.message_id;

    let list_id = selectedMailList.join(",");
    if (selectedMailList.length == 0) {
      toast.error(this.props.t("ChannelDetail.mailListSelectValidation"));
    } else {
      apiCall(
        METHOD.QUERY,
        assignMailingListtoChannelMessage(getUserID(), message_id, list_id),
        (data) => {
          toast.success(this.props.t(data.message));
          this.props.changeRouteStatus(0);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  changeStatus = (type, mail_id) => {
    this.setState({
      routeStatus: type,
      mail_id,
    });
  };

  addSelectedMailId = (mail_id) => {
    this.setState((prev) => {
      return {
        selectedMailList: [...prev.selectedMailList, mail_id],
      };
    });
  };

  removeSelectedMailId = (mail_id) => {
    let deletedMailList = this.state.selectedMailList.filter((id) => {
      return id != mail_id;
    });

    this.setState({
      selectedMailList: deletedMailList,
    });
  };
  loader = (<div>this.props.t("GeneralMessages.loading")</div>);
  render() {
    const { routeStatus, isLoading } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <div className="wrapper position-relative">
              <div className="header-bg header-red sticky-top mb-2">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span
                      className="pointer"
                      onClick={() => this.props.changeRouteStatus(0)}
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw font-30 text-light"></i>
                    </span>

                    <div
                      className="btn btn-light font-13 ml-auto pointer"
                      onClick={this.handleClick}
                    >
                      {t("ChannelMailLink.Save")}
                    </div>
                  </div>
                  <div className="text-center mt-5 text-light">
                    <h2 className="text-light">
                      {t("ChannelMailLink.addRecipient")}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="middle-section ">
                {isLoading && this.loader}
                {/* <div className="container my-4 z-index-99">
                  <div className="mb-4">
                    <div className="clearfix">
                      <span className="font-13 text-muted mr-2">
                        {t("ChannelMailLink.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                        >
                          {t("ChannelMailLink.az")}
                        </button>
                        <button type="button" className="btn btn-dark">
                          {t("ChannelMailLink.crisisContact")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                        >
                          {t("ChannelMailLink.ranking")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <React.Fragment>
                  {routeStatus == 0 ? (
                    <MailList
                      changeMailRouteStatus={this.changeStatus}
                      addSelectedMailId={this.addSelectedMailId}
                      removeSelectedMailId={this.removeSelectedMailId}
                      checkedList={this.state.selectedMailList}
                    />
                  ) : (
                    <SubMailList
                      changeMailRouteStatus={this.changeStatus}
                      addSelectedMailId={this.addSelectedMailId}
                      removeSelectedMailId={this.removeSelectedMailId}
                      mail_id={this.state.mail_id}
                      checkedList={this.state.selectedMailList}
                    />
                  )}
                </React.Fragment>
              </div>
              <Footer type="2" />
            </div>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(ChannelMailLinkIndex));
