/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddPerson from "./AddPerson";
import AddSubGroup from "./AddSubGroup";
import EditNewGroup from "./EditNewGroup";
import GroupDetailItem from "./GroupDetailItem";
import RearrangeGroup from "./RearrangeGroup";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
      groupListPermission:false
    };
  }
  changeRouteStatus = (value) => {
    this.setState((prevState) => {
      return {
        routeStatus: value,
        groupListPermission:false
      };
    });
  };

  
  render() {
    const { routeStatus } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 &&  (
          <GroupDetailItem changeRouteStatus={this.changeRouteStatus} />
        )}
        {routeStatus == 1 && (
          <AddSubGroup changeRouteStatus={this.changeRouteStatus} />
        )}
        {routeStatus == 2 && (
          <EditNewGroup changeRouteStatus={this.changeRouteStatus} />
        )}

        {routeStatus == 3 && (
          <AddPerson changeRouteStatus={this.changeRouteStatus} />
        )}
        {routeStatus == 4 && (
          <RearrangeGroup changeStatus={this.changeRouteStatus} />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
