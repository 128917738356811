/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { GroupListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import NoRecordFound from "../CommanComponents/NoRecordFound";
import TopHeader from "../CommanComponents/TopHeader";
import GroupItem from "./GroupItem";
import GroupSetteliteSideMenu from "./GroupSetteliteSideMenu";
import SearchGroup from "./SearchGroup";
import SearchGroupList from "./SearchGroupList";

class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      groupList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchGroupList: [],
      searchPersonList: [],
      sortType: 1,
      readGroupListPermission:false
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  componentDidMount() {
    const readGroupListPermission  = checkUserPermission("read_group");
    this.setState({
      readGroupListPermission
    })
  }
  loadItem = () => {
    const { end, loader, sortType } = this.state;
    if (!loader && this.props.groupList.hasMore) {
      this.setState({
        loader: true,
      });
      apiCall(
        METHOD.QUERY,
        GroupListQuery(
          getUserID(),
          this.props.groupList.start,
          end,
          0,
          "",
          sortType
        ),
        (data) => {
          this.props.getGroupList(data.group_list);
          this.handleGroupApiResponse(data);
        },
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  changeSortType = (sortType) => {
    this.setState(
      {
        sortType: sortType,
      },
      () => {
        this.props.clearGroupState();
      }
    );
  };
  handleGroupApiResponse = (data) => {
    if (data.is_last == 1) {
      this.props.getGroupHasMoreValue(false);
    }
    this.props.getGroupStartValue(this.props.groupList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  setSearchGroupList = (groupList, personList) => {
    this.setState({
      searchGroupList: groupList,
      searchPersonList: personList,
    });
  };

  render() {
    const { end, searchGroupList, searchPersonList, sortType,readGroupListPermission } = this.state;
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-blue sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      {t("GroupListScreen.headerTitle")}
                    </p>
                    <LogoutMenu />
                  </div>

                  <SearchGroup searchArray={this.setSearchGroupList} />
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
                <div className="container my-4 z-index-99">
                  <div className="mb-4">
                    <div className="clearfix sorting-filter">
                      <span className="font-13 text-muted mr-2">
                        {t("GroupListScreen.sorting")}
                      </span>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          onClick={() => this.changeSortType(1)}
                          className={
                            sortType == 1
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.a-z")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(2)}
                          className={
                            sortType == 2
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.orderOfPriority")}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.changeSortType(3)}
                          className={
                            sortType == 3
                              ? "btn btn-dark"
                              : "btn btn-outline-secondary"
                          }
                        >
                          {t("GroupListScreen.crisisContacts")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {(readGroupListPermission && searchGroupList.length != 0) || (readGroupListPermission && searchPersonList != 0) ? (
                  <SearchGroupList
                    group_list={this.state.searchGroupList}
                    person_list={this.state.searchPersonList}
                  />
                ) : readGroupListPermission?(  <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={this.props.groupList.hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-4">
                    {this.props.groupList &&
                      this.props.groupList.groupList.map((data, index) => {
                        return (
                          <GroupItem data={data} key={index} index={index} />
                        );
                      })}

                    {this.props.groupList &&
                      this.props.groupList.length == 0 && (
                        <NoRecordFound title="Group List" name="group list" />
                      )}
                  </div>
                </InfiniteScroll>
             ):( <span> {t("GeneralMessages.permissionError")}     </span>)}

                <GroupSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeStatus={this.props.changeStatus}
                />
                <FabIcon color="rounded-blue" openSlider={this.openSlider} />
              </div>
              <Footer type="3" />
            </React.Fragment>
          );
        }}
      </Translation>   );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [stateToProps.groupListStateToProps],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(GroupList)));
