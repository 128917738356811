import { flow } from "lodash";
import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { findDOMNode } from "react-dom";

class RearrangePersonItem extends Component {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      name,
      email,
      isDragging,
    } = this.props;
    const opacity = isDragging ? 0.5 : 1;
    // const backgroundColor =
    //   isOver && canDrop
    //     ? "#F3F3F3"
    //     : !isOver && canDrop
    //     ? "transperent"
    //     : "transperent";
    return connectDropTarget(
      connectDragSource(
        <div className="pl-2 pt-1 d-flex cursor-move border-bottom pb-3 mb-3">
          <div className="d-flex align-items-center w-100" style={{ opacity }}>
            <i className="fas fa-grip-vertical mr-3"></i>
            <div className="clearfix">
              <h6 className="m-0">{name && name}</h6>
              <p className="text-muted font-12 m-0">{email && email}</p>
            </div>
          </div>
        </div>
      )
    );
  }
}

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      listId: props.listId,
    };
  },

  endDrag(props, monitor) {
    // const item = monitor.getItem();
    // const dropResult = monitor.getDropResult();
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const sourceListId = monitor.getItem().listId;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    if (props.listId === sourceListId) {
      props.moveCard(dragIndex, hoverIndex);
      monitor.getItem().index = hoverIndex;
    }
  },
};
export default flow(
  DropTarget("CARD", cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })),
  DragSource("CARD", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(RearrangePersonItem);
