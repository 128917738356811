/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AddNewChannelReport from "./AddNewChannelReport";
import ChannelList from "./ChannelList";
import EditNewReport from "./EditNewReport";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeStatus: 0,
    };
  }

  changeStatus = (type) => {
    this.setState({
      routeStatus: type,
    });
  };

  render() {
    const { routeStatus } = this.state;
    return (
      <div className="wrapper position-relative">
        {routeStatus == 0 && (
          <ChannelList changeRouteStatus={this.changeStatus} />
        )}

        {routeStatus == 1 && (
          <AddNewChannelReport changeRouteStatus={this.changeStatus} />
        )}

        {routeStatus == 2 && (
          <EditNewReport changeRouteStatus={this.changeStatus} />
        )}
      </div>
    );
  }
}
export default withRouter(Index);
