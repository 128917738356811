/* eslint eqeqeq: 0 */
import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  EDIT_EVENT,
  EVENT_HASMORE_VALUE,
  EVENT_LIST,
  EVENT_START_VALUE,
  TOTAL_EVENT,
} from "../constants/action-types";

const initialState = {
  eventList: [],
  start: 0,
  hasMore: true,
  total_event: 0,
};
export default function eventListReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_LIST:
      return { ...state, eventList: [...state.eventList, ...action.payload] };
    case ADD_NEW_EVENT:
      return { ...state, eventList: [...action.payload, ...state.eventList] };
    case EVENT_START_VALUE:
      return { ...state, start: action.payload };
    case EVENT_HASMORE_VALUE:
      return { ...state, hasMore: action.payload };
    case TOTAL_EVENT:
      return { ...state, total_event: action.payload };
    case DELETE_EVENT:
      let deletedState = state.eventList.filter((item) => {
        return item.id != action.payload.id;
      });
      return { ...state, eventList: deletedState };
    case EDIT_EVENT:
      const index = state.eventList.findIndex((event) => event.id === action.payload.id);
      let newstate = state;
      newstate.eventList[index] = {
        ...newstate.eventList[index],
        ...action.payload,
      };
      return { ...newstate };

    default:
      return state;
  }
}
