/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";

class SubGroupItem extends Component {
  handleItemClick = (data) => {
    const { id,  person } = data;
    this.props.clearStateGroupDetail({});
    this.props.clearStateGroupPersonDetail({});
    if (person == 0) {
      this.props.history.push("/group-detail/" + id);
    } else {
      this.props.history.push("/group-personlist/" + id);
    }
  };

  render() {
    const { name, person } = this.props.data;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <a
                href="javascript;:"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleItemClick(this.props.data);
                }}
                className="d-block flex-fill"
              >
                <div className="ml-2 my-2 mb-4 d-flex">
                  <div className="d-flex w-100">
                    <div className="clearfix d-flex0 0align-items-center">
                      <h5 className="m-0 font-18 d-block">{name && name} </h5>
                      <p className="text-muted font-12 m-0">
                        {person && person}{" "}
                        {person && person == 1
                          ? t("GroupListScreen.singularPerson")
                          : t("GroupListScreen.pluralPerson")}
                      </p>
                    </div>
                    <div className="align-self-center float-right ml-auto">
                      <span className="text-dark">
                        <i className="zmdi zmdi-chevron-right zmdi-hc-fw font-26"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.groupDetailStateToProps,
        stateToProps.groupListStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.groupDetailDispatchToProps,
    actions.groupDetailPersonListDispatchToProps,
  ])
)(withRouter(withTranslation()(SubGroupItem)));
