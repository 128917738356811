/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { addAdminChannel, adminChannelTypeList } from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { getUserID } from "../../../util/utils";
import Footer from "../../CommanComponents/Footer";
import ChannelTypeList from "./ChannelTypeList";
import TwitterLogin from "react-twitter-login";

class AddChannel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDialogueStatus: false,
      name: "",
      identifier: "",
      description: "",
      is_active: 0,
      sms_api_username: "",
      sms_api_key: "",
      sms_test_phonenumber: "",
      sms_from_email: "",
      sms_source: "",
      sms_country: "",
      sms_custom_string: "",
      t2s_api_username: "",
      t2s_api_key: "",
      t2s_test_phonenumber: "",
      t2s_voice: "",
      t2s_language: "",
      t2s_custom_string: "",
      channel_type_Image: "",
      channel_type_name: "",
      channel_type_id: 0,
      channel_type_identifier: "",
      chaanne_type_list: [],
      loading: false,
      twitter_consumer_key: "",
      twitter_consumer_secret: "",
      tempResponse:{}
    };
  }

  componentDidMount() {
    apiCall(
      METHOD.QUERY,
      adminChannelTypeList(getUserID()),
      (data) => {
        let brandImageDefault, brandNameDefault, brandID, brand_identifier;
        const tempResponse = this.props.tempResponse
        console.log("tempResponse",tempResponse)
        if(Object.keys(tempResponse).length !== 0 && tempResponse.constructor === Object){
          const channelType = tempResponse.channelType
          const textValueObject = tempResponse.textValueObject
          const {name,description,is_active,identifier} = textValueObject && textValueObject
          this.setState({
            name,
            description,
            is_active,
            identifier
          })
          let index =-1
          if(channelType=='twitter'){
             index = data.channel_type_list.findIndex(
              (channelType) => channelType.identifier == 'twitter'
            );
          }else if(channelType=='facebook'){
             index = data.channel_type_list.findIndex(
              (channelType) => channelType.identifier == 'facebook'
            );
          }
        if(index!=-1){
          brandNameDefault = data.channel_type_list[index].name;
          brandImageDefault = data.channel_type_list[index].icon;
          brandID = data.channel_type_list[index].id;
          brand_identifier = data.channel_type_list[index].identifier;
        }
        }else{
          console.log("0 index")
          if (data.channel_type_list && data.channel_type_list.langth !== 0) {
            brandNameDefault = data.channel_type_list[0].name;
            brandImageDefault = data.channel_type_list[0].icon;
            brandID = data.channel_type_list[0].id;
            brand_identifier = data.channel_type_list[0].identifier;
          }
        }
        this.setState({
          chaanne_type_list: data.channel_type_list,
          channel_type_Image: brandImageDefault,
          channel_type_id: brandID,
          channel_type_name: brandNameDefault,
          channel_type_identifier: brand_identifier,
          tempResponse:tempResponse
        });
      },
      (isLoading) => this.handleLoader(isLoading),
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  handleTextChange = (e, type) => {
    if (type == "name") {
      this.setState({
        name: e.target.value,
      });
    } else if (type == "identifier") {
      this.setState({
        identifier: e.target.value,
      });
    } else if (type == "description") {
      this.setState({
        description: e.target.value,
      });
    } else if (type == "sms_api_key") {
      this.setState({
        sms_api_key: e.target.value,
      });
    } else if (type == "sms_api_username") {
      this.setState({
        sms_api_username: e.target.value,
      });
    } else if (type == "sms_country") {
      this.setState({
        sms_country: e.target.value,
      });
    } else if (type == "sms_custom_string") {
      this.setState({
        sms_custom_string: e.target.value,
      });
    } else if (type == "sms_from_email") {
      this.setState({
        sms_from_email: e.target.value,
      });
    } else if (type == "sms_source") {
      this.setState({
        sms_source: e.target.value,
      });
    } else if (type == "sms_test_phonenumber") {
      this.setState({
        sms_test_phonenumber: e.target.value,
      });
    } else if (type == "t2s_api_key") {
      this.setState({
        t2s_api_key: e.target.value,
      });
    } else if (type == "t2s_api_username") {
      this.setState({
        t2s_api_username: e.target.value,
      });
    } else if (type == "t2s_custom_string") {
      this.setState({
        t2s_custom_string: e.target.value,
      });
    } else if (type == "t2s_language") {
      this.setState({
        t2s_language: e.target.value,
      });
    } else if (type == "t2s_test_phonenumber") {
      this.setState({
        t2s_test_phonenumber: e.target.value,
      });
    } else if (type == "t2s_voice") {
      this.setState({
        t2s_voice: e.target.value,
      });
    }
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };

  handleReportDialogue = () => {
    this.setState((prevState) => {
      return {
        reportDialogueStatus: !prevState.reportDialogueStatus,
      };
    });
  };

  setFieldType = (event) => {
    this.setState({
      field_type: event.target.value,
    });
  };

  set_is_active = (event) => {
    if (event.target.checked) {
      this.setState({
        is_active: 1,
      });
    } else {
      this.setState({
        is_active: 0,
      });
    }
  };
  loginHandler = (err, data) => {
  };
  validateInputs = () => {
    const {
      name,
      identifier,
      sms_api_username,
      sms_api_key,
      sms_test_phonenumber,
      sms_from_email,
      sms_source,
      sms_country,
      sms_custom_string,
      t2s_api_username,
      t2s_api_key,
      t2s_test_phonenumber,
      t2s_voice,
      t2s_language,
      channel_type_identifier,
      t2s_custom_string,
    } = this.state;
    let validationStatus = false;
    if (!name) {
      toast.info(this.props.t("ManageChannel.nameValidation"));
    } else if (!identifier) {
      toast.info(this.props.t("ManageChannel.identifierValidation"));
    } else if (!sms_api_username && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_api_usernameValidation"));
    } else if (!sms_api_key && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_api_keyValidation"));
    } else if (!sms_test_phonenumber && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_test_phonenumberValidation"));
    } else if (!sms_from_email && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_from_emailValidation"));
    } else if (!sms_source && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_sourceValidation"));
    } else if (!sms_country && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_countryValidation"));
    } else if (!sms_custom_string && channel_type_identifier == "sms") {
      toast.info(this.props.t("ManageChannel.sms_custom_stringValidation"));
    } else if (!t2s_api_username && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_api_usernameValidation"));
    } else if (!t2s_api_key && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_api_keyValidation"));
    } else if (
      !t2s_test_phonenumber &&
      channel_type_identifier == "text2speech"
    ) {
      toast.info(this.props.t("ManageChannel.t2s_test_phonenumberValidation"));
    } else if (!t2s_voice && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_voiceValidation"));
    } else if (!t2s_language && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_languageValidation"));
    } else if (!t2s_custom_string && channel_type_identifier == "text2speech") {
      toast.info(this.props.t("ManageChannel.t2s_custom_stringValidation"));
    } else {
      validationStatus = true;
    }
    return validationStatus;
  };
  set_is_default = (event) => {
    if (event.target.checked) {
      this.setState({
        is_default: 1,
      });
    } else {
      this.setState({
        is_default: 0,
      });
    }
  };

  createChannel = () => {
    const is_validate = this.validateInputs();
    if (is_validate) {
      const {
        name,
        identifier,
        description,
        is_active,
        sms_api_username,
        sms_api_key,
        sms_test_phonenumber,
        sms_from_email,
        sms_source,
        sms_country,
        sms_custom_string,
        t2s_api_username,
        t2s_api_key,
        t2s_test_phonenumber,
        t2s_voice,
        t2s_language,
        t2s_custom_string,
        channel_type_identifier,
        channel_type_id,
      tempResponse,
      } = this.state;
      let settings = {};
      let fbAccessToken, fbPageId
      if (channel_type_identifier == "sms") {
        settings["api_username"] = sms_api_username;
        settings["api_key"] = sms_api_key;
        settings["test_phonenumber"] = sms_test_phonenumber;
        settings["from_email"] = sms_from_email;
        settings["custom_string"] = sms_custom_string;
        settings["source"] = sms_source;
        settings["country"] = sms_country;
      } else if (channel_type_identifier == "text2speech") {
        settings["api_username"] = t2s_api_username;
        settings["api_key"] = t2s_api_key;
        settings["test_phonenumber"] = t2s_test_phonenumber;
        settings["voice"] = t2s_voice;
        settings["language"] = t2s_language;
        settings["custom_string"] = t2s_custom_string;
      }else if(channel_type_identifier == "twitter"){
        const {isConnected,profileLink,oauth_token,oauth_token_secret} = tempResponse && tempResponse
        settings["oauth_token"] = oauth_token;
        settings["oauth_token_secret"] = oauth_token_secret;
        settings["profile_link"] = profileLink;
        settings["is_connected"] = isConnected;
      }else if(channel_type_identifier == "facebook"){
        const {profileLink,isConnected} = tempResponse && tempResponse
        fbAccessToken =tempResponse.accessToken
        fbPageId =tempResponse.pageId
        settings["fbisConnected"] = isConnected;
        settings["profile_link"] = profileLink;
        settings["pageId"] = fbPageId;
        settings["accessToken"] = fbAccessToken;
      }

     
      apiCall(
        METHOD.QUERY,
        addAdminChannel(
          getUserID(),
          name,
          description,
          identifier,
          channel_type_id,
          JSON.stringify(JSON.stringify(settings)),
          is_active,
          fbAccessToken,
          fbPageId
        ),
        (data) => {
          this.handleCreateResponse(data);
        },
        (isLoading) => this.handleLoader(isLoading),
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleReportSelection = (item) => {
    const { name, secondary_icon, id, identifier } = item;

    this.setState({
      reportDialogueStatus: false,
      channel_type_Image: secondary_icon,
      channel_type_name: name,
      channel_type_id: id,
      channel_type_identifier: identifier,
    });
  };

  handleCreateResponse = (data) => {
    toast.success(this.props.t(data.message));

    this.props.changeRouteStatus(0);
  };
  render() {
    const {
      name,
      identifier,
      description,
      is_active,
      sms_api_username,
      sms_api_key,
      sms_test_phonenumber,
      sms_from_email,
      sms_source,
      sms_country,
      chaanne_type_list,
      sms_custom_string,
      t2s_api_username,
      t2s_api_key,
      t2s_test_phonenumber,
      t2s_voice,
      t2s_language,
      t2s_custom_string,
      channel_type_identifier,
      channel_type_Image,
      loading,
      fbIsConneced,
      tempResponse
    } = this.state;

    const {isConnected,profileLink} = tempResponse && tempResponse
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top">
                <div className="container">
                  <div className="py-2 d-flex align-items-center">
                    <span>
                      <i
                        className="zmdi zmdi-close zmdi-hc-fw font-30 text-light pointer"
                        onClick={() => this.props.changeRouteStatus(0, {})}
                      ></i>
                    </span>

                    {loading ? (
                      <div className="btn btn-light font-13 ml-auto pointer">
                        {t("AdminAddUser.save")}
                        <div className="spinner-border spinner-border-sm ml-1 text-blue">
                          <span className="sr-only">
                            {t("GeneralMessages.loading")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-light font-13 ml-auto pointer"
                        onClick={() => {
                          this.createChannel();
                        }}
                      >
                        {t("AdminAddUser.save")}
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4 text-light">
                    <h2 className="m-0 text-light">
                      {t("ManageChannel.addItemTitle")}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1">
                <div className="container my-4 z-index-99">
                  <div className="form-group max-170 m-auto text-center">
                    <a
                      href="javascript;:"
                      className="clearfix text-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReportDialogue();
                      }}
                    >
                      <label className="font-14">
                        {t("AddNewChannel.type")}
                      </label>
                      <div className="icon-drop-bg pointer">
                        <div className="rounded-circle">
                          <img
                            src={`data:image/png;base64,${
                              channel_type_Image && channel_type_Image
                            }`}
                            alt="ico"
                          />
                        </div>

                        <span className="font-12">
                          {this.state.channel_type_name &&
                            this.state.channel_type_name}
                        </span>
                      </div>
                    </a>
                  </div>

                  <ChannelTypeList
                    closeBrandDialogue={this.handleReportDialogue}
                    handleBrandSelection={this.handleReportSelection}
                    status={this.state.reportDialogueStatus}
                    BrandList={chaanne_type_list}
                  />

                  <div className="form-group mb-5">
                    <div className="form-group">
                      <div className="container my-4 00z-index-99">
                        <form>
                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.nametitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => this.handleTextChange(e, "name")}
                              name="name"
                              value={name && name}
                              placeholder={t("ManageChannel.namePlaceHolder")}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.descriptiontitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                this.handleTextChange(e, "description")
                              }
                              name="description"
                              value={description && description}
                              placeholder={t(
                                "ManageChannel.descriptionPlaceHolder"
                              )}
                            />
                          </div>

                          <div className="form-group">
                            <label className="font-14">
                              {t("ManageChannel.identifiertitle")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                this.handleTextChange(e, "identifier")
                              }
                              name="identifier"
                              value={identifier && identifier}
                              placeholder={t(
                                "ManageChannel.identifierPlaceHolder"
                              )}
                            />
                          </div>

                          {channel_type_identifier == "sms" && (
                            <React.Fragment>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_api_usernametitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_api_username")
                                  }
                                  name="sms_api_username"
                                  value={sms_api_username && sms_api_username}
                                  placeholder={t(
                                    "ManageChannel.sms_api_usernametitle"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_api_keytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_api_key")
                                  }
                                  name="sms_api_key"
                                  value={sms_api_key && sms_api_key}
                                  placeholder={t(
                                    "ManageChannel.sms_api_keyPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_test_phonenumbertitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "sms_test_phonenumber"
                                    )
                                  }
                                  name="sms_test_phonenumber"
                                  value={
                                    sms_test_phonenumber && sms_test_phonenumber
                                  }
                                  placeholder={t(
                                    "ManageChannel.sms_test_phonenumberPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_from_emailtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_from_email")
                                  }
                                  name="sms_from_email"
                                  value={sms_from_email && sms_from_email}
                                  placeholder={t(
                                    "ManageChannel.sms_from_emailPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_custom_stringtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "sms_custom_string"
                                    )
                                  }
                                  name="sms_custom_string"
                                  value={sms_custom_string && sms_custom_string}
                                  placeholder={t(
                                    "ManageChannel.sms_custom_stringPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_sourcetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_source")
                                  }
                                  name="sms_source"
                                  value={sms_source && sms_source}
                                  placeholder={t(
                                    "ManageChannel.sms_sourcePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.sms_countrytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "sms_country")
                                  }
                                  name="sms_country"
                                  value={sms_country && sms_country}
                                  placeholder={t(
                                    "ManageChannel.sms_countryPlaceHolder"
                                  )}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          {channel_type_identifier == "text2speech" && (
                            <React.Fragment>
                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_api_usernametitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_api_username")
                                  }
                                  name="t2s_api_username"
                                  value={t2s_api_username && t2s_api_username}
                                  placeholder={t(
                                    "ManageChannel.t2s_api_usernamePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_api_keytitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_api_key")
                                  }
                                  name="t2s_api_key"
                                  value={t2s_api_key && t2s_api_key}
                                  placeholder={t(
                                    "ManageChannel.t2s_api_keyPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_test_phonenumbertitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "t2s_test_phonenumber"
                                    )
                                  }
                                  name="t2s_test_phonenumber"
                                  value={
                                    t2s_test_phonenumber && t2s_test_phonenumber
                                  }
                                  placeholder={t(
                                    "ManageChannel.t2s_test_phonenumberPlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_voicetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_voice")
                                  }
                                  name="t2s_voice"
                                  value={t2s_voice && t2s_voice}
                                  placeholder={t(
                                    "ManageChannel.t2s_voicePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_languagetitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(e, "t2s_language")
                                  }
                                  name="t2s_language"
                                  value={t2s_language && t2s_language}
                                  placeholder={t(
                                    "ManageChannel.t2s_languagePlaceHolder"
                                  )}
                                />
                              </div>

                              <div className="form-group">
                                <label className="font-14">
                                  {t("ManageChannel.t2s_custom_stringtitle")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleTextChange(
                                      e,
                                      "t2s_custom_string"
                                    )
                                  }
                                  name="t2s_custom_string"
                                  value={t2s_custom_string && t2s_custom_string}
                                  placeholder={t(
                                    "ManageChannel.t2s_custom_stringPlaceHolder"
                                  )}
                                />
                              </div>
                            </React.Fragment>
                          )}

                          <div className="form-group">
                            <div onChange={(e) => this.set_is_active(e)}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={is_active == 1 ? true : false}
                                value="0"
                                name="link_type"
                              />
                              {t("ManageChannel.is_active_title")}
                            </div>
                          </div>

                          {channel_type_identifier == "facebook"  && <div className="form-group">
                            <div>
                            {t("ManageChannel.status")}
                            {!isConnected || isConnected==0?(t("ManageChannel.notConnected")):(t("ManageChannel.connected"))}
                            </div>
                          </div>}
                         
                          {channel_type_identifier == "twitter"  && <div className="form-group">
                            <div>
                            {t("ManageChannel.status")}
                            {!isConnected || isConnected==0?(t("ManageChannel.notConnected")):(t("ManageChannel.connected"))}
                            </div>
                          </div>}

                          <div className="form-group">
                            {channel_type_identifier == "facebook" && (

                                <Link  to={{
                                pathname: "/connect-facebook",
                                state: { isFromCreate: true,
                                textValueObject :{name, description,identifier,is_active} }
                              }}>
                                {t("ManageChannel.connectpages")}
                              </Link>
                            )}
                          </div>

                          <div className="form-group">
                            {channel_type_identifier == "twitter" && (
                              <Link  to={{
                                pathname: "/connect-twitter",
                                state: { isFromCreate: true,
                                textValueObject :{name, description,identifier,is_active} }
                              }}>
                                {t("ManageChannel.connecttwitter")}
                              </Link>
                            )}
                          </div>
                    
                          {profileLink != '' && channel_type_identifier == "twitter" && (
                          <div className="form-group">
                             <a href={profileLink} rel="noopener noreferrer" target="_blank" >{t("ManageChannel.gotoLink")}</a>
                          </div>)}    

                           {/* {profileLink != '' && channel_type_identifier == "facebook" && (
                          <div className="form-group">
                             <a href={profileLink} rel="noopener noreferrer" target="_blank" >{t("ManageChannel.gotoLink")}</a>
                          </div>)}                     */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer type="2" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(AddChannel));
