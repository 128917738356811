/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  deleteDefaultSnippet,
  GetReportTypeQuery,
  reporteventsnippetlist
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID, removeDuplicates } from "../../../util/utils";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";

class EventReportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      report_event_snippet_list: [],
      start: 0,
      end: 20,
      hasMore: true,
      deleteLoadingStatus: false,
      headerMenuStatus: false,
      filterReportType: {},
      options: [],
      report_type_list: [],
      report_type_id: 0,
    };
  }

  componentDidMount() {

    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
    apiCall(
      METHOD.QUERY,
      GetReportTypeQuery(getUserID()),
      (data) => {
        let options = [];
        data.report_type_list &&
          data.report_type_list.map((d) =>
            options.push({ value: d.id, label: d.name })
          );

        this.setState({
          report_type_list: data.report_type_list,
          options,
        });
      },
      (isLoading) => {},
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  }

  loadList = () => {
    const { start, end, hasMore, isLoading, report_type_id } = this.state;
    if (!isLoading && hasMore) {
      this.setState({
        isLoading: true,
      });

      apiCall(
        METHOD.QUERY,
        reporteventsnippetlist(getUserID(), start, end, report_type_id),
        (data) => this.handleResponse(data),
        (isLoading) => {},
        (data) => {
          toast.error(this.props.t(data.message));
        }
      );
    }
  };

  handleResponse = (data) => {
    const { start, end } = this.state;
    if (data.is_last == 1) {
      this.setState({ hasMore: false });
    }

    let old_report_event_snippet_list = this.state.report_event_snippet_list;
    let tempList = data.report_event_snippet_list
    old_report_event_snippet_list = [...old_report_event_snippet_list,...tempList]
    // old_report_event_snippet_list = old_report_event_snippet_list.concat(
    //   data.report_event_snippet_list
    // );

    old_report_event_snippet_list = removeDuplicates(
      old_report_event_snippet_list,
      "id"
    );

    this.setState((prevState) => {
      return {
        ...this.state,
        start: start + end,
        report_event_snippet_list: old_report_event_snippet_list,
        isLoading: false,
      };
    });
  };

  handleFilterChange = (filterReportType) => {
    if (Object.keys(filterReportType).length == 0) {
      this.setState(
        {
          filterReportType: {},
          report_type_id: 0,
          hasMore: true,
          start: 0,
          report_event_snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadList();
        }
      );
    } else {
      this.setState(
        {
          filterReportType,
          report_type_id: filterReportType.value,
          hasMore: true,
          start: 0,
          report_event_snippet_list: [],
          isLoading: false,
        },
        () => {
          this.loadList();
        }
      );
    }
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t(
        "manageDefaultSnippet.confirmTodeleteDefaultSnippetTitle"
      ),
      message: this.props.t(
        "manageDefaultSnippet.confirmTodeleteLocationMessage"
      ),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteDefaultSnippet(getUserID(), id),
              (data) => {
                let afterDeletedMenuList = this.state.report_event_snippet_list.filter(
                  (event_snippet) => {
                    return event_snippet.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  report_event_snippet_list: afterDeletedMenuList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filterReportType: {},
        report_type_id: 0,
        hasMore: true,
        start: 0,
        report_event_snippet_list: [],
        isLoading: false,
      },
      () => {
        this.loadList();
      }
    );
  };
  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  loader = (<LoadingPage key={0} />);
  render() {
    const {
      hasMore,
      end,
      report_event_snippet_list,
      filterReportType,
      options,
      deleteLoadingStatus,
    } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      <Link to="/administration" className="text-light">
                        {t("manageDefaultSnippet.title")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 left-line left-line-custom">
                <div className="d-flex">
                  <Select
                    value={filterReportType}
                    onChange={(value) => this.handleFilterChange(value)}
                    options={options}
                    className="m-2  w-100 z-index-999"
                    clearable={false}
                    placeholder={t("administration.filterSnippet")}
                  />
                  {Object.keys(filterReportType).length != 0 && (
                    <i
                      className="zmdi zmdi-close zmdi-hc-fw font-40 m-auto   pointer"
                      onClick={() => this.clearFilter()}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    />
                  )}
                </div>

                {deleteLoadingStatus && this.loader}

                <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadList}
                  hasMore={hasMore}
                  className="left-line-custom"
                  useWindow={false}
                  loader={this.loader}
                >
                  {report_event_snippet_list &&
                    report_event_snippet_list.map((item, index) => {
                      const { name, snippet_name } = item;
                      return (
                        <div
                          className={
                            index == report_event_snippet_list.length - 1
                              ? "container my-2 z-index-99 last"
                              : "container my-2 z-index-99 "
                          }
                          key={index}
                        >
                          <a
                            href="javascript;:"
                            className="mb-4 d-block custom-hover-box"
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="rounded-40 admin-bg float-left ml-5p">
                              <span className="text-light"></span>
                            </div>
                            <div className="pl-3 pt-1 d-flex" key={index}>
                              <div className="d-flex justify-content-between w-100">
                                <div className="clearfix">
                                  <h6 className="m-0 word-break">
                                    {name && name}
                                  </h6>
                                  <p className="text-muted font-12 mb-2">
                                    {snippet_name && snippet_name}
                                  </p>
                                </div>
                                <div className="action-btns clearfix">
                                  <i
                                    className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.changeStatusWithStatusValue(
                                        1,
                                        item
                                      );
                                    }}
                                  />

                                  <i
                                    className="zmdi zmdi-delete zmdi-hc-lg mr-3 pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleDelete(item);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>

              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(EventReportList));
