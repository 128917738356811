import { LOGIN_DETAILS } from "../constants/action-types";

const initialState = {};
export default function loginDetailReducer(state = null, action) {
  switch (action.type) {
    case LOGIN_DETAILS:
      return { ...initialState, ...action.payload };
    default:
      return state;
  }
}
