import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { withRouter } from "react-router";

class NumberType extends Component {
  render() {
    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <h4>{t("AdminSetteliteSideMenu.option")}</h4>

              <div className="form-group">
                <label className="font-14">
                  {t("AdminSnippetNumbertype.min_value_title")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) =>
                    this.props.handleNumberInputtextValue(e, "min_value")
                  }
                  value={this.props.min_value}
                  placeholder={t(
                    "AdminSnippetNumbertype.min_value_placeholder"
                  )}
                />

                <label className="font-14">
                  {t("AdminSnippetNumbertype.max_value_title")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) =>
                    this.props.handleNumberInputtextValue(e, "max_value")
                  }
                  value={this.props.max_value}
                  placeholder={t(
                    "AdminSnippetNumbertype.max_value_placeholder"
                  )}
                />

                <label className="font-14">
                  {t("AdminSnippetNumbertype.default_value")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) =>
                    this.props.handleNumberInputtextValue(e, "defaultValue")
                  }
                  value={this.props.defualt_number}
                  placeholder={t("AdminSnippetNumbertype.default_value")}
                />
                <ul className="list-unstyled mb-0 mt-3">
                  <li className="pb-2">
                    <div className="form-row">
                      <div className="col-8">
                        <span class="font-size-14">
                          {t("AdminSnippetNumbertype.hide_on_zero")}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            this.props.handleNumberCheckBox(e, "hide_on_zero")
                          }
                          checked={this.props.hide_on_zero}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(NumberType));
