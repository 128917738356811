/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Translation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminLocationList,
  deleteLocation,
} from "../../../constants/apiParams";
import apiCall, { METHOD } from "../../../constants/baseUrl";
import { checkAdminPermission, getUserID } from "../../../util/utils";
import FabIcon from "../../CommanComponents/FabIcon";
import Footer from "../../CommanComponents/Footer";
import LoadingPage from "../../CommanComponents/LoadingPage";
import LogoutMenu from "../../CommanComponents/LogoutMenu";
import TopHeader from "../../CommanComponents/TopHeader";
import LocationSetteliteSideMenu from "./LocationSetteliteSideMenu";
class LocationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      location_list: [],
      deleteLoadingStatus: false,
      headerMenuStatus: false,
    };
  }

  componentDidMount() {

    if(!checkAdminPermission()){
      this.props.history.push("/")
    }
    this.loadListTypeList();
  }

  loadListTypeList = () => {
    apiCall(
      METHOD.QUERY,
      adminLocationList(getUserID(),1),
      (data) => this.handleUserApiResponse(data),
      (isLoading) => {
        this.setState({ isLoading });
      },
      (data) => {
        toast.error(this.props.t(data.message));
      }
    );
  };

  handleUserApiResponse = (data) => {
    this.setState((prevState) => {
      return {
        location_list: data.location_list,
        isLoading: false,
      };
    });
  };

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: true,
      };
    });
  };

  handleDelete = (item) => {
    const { id } = item;

    confirmAlert({
      title: this.props.t("ManageLocation.confirmTodeleteLocationTitle"),
      message: this.props.t("ManageLocation.confirmTodeleteLocationMessage"),
      buttons: [
        {
          label: this.props.t("personDetail.yes"),
          onClick: () => {
            apiCall(
              METHOD.QUERY,
              deleteLocation(getUserID(), id),
              (data) => {
                let afterDeletedLocationList = this.state.location_list.filter(
                  (location) => {
                    return location.id != item.id;
                  }
                );
                toast.success(this.props.t(data.message));
                this.setState({
                  location_list: afterDeletedLocationList,
                });
              },
              (isLoading) => {
                this.setState({ deleteLoadingStatus: isLoading });
              },
              (data) => {
                toast.error(this.props.t(data.message));
              }
            );
          },
        },
        {
          label: this.props.t("personDetail.no"),
          onClick: () => {},
        },
      ],
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        headerMenuStatus: false,
      };
    });
  };

  handleLoader = (status) => {
    this.setState({
      isLoading: status,
    });
  };
  loader = (<LoadingPage key={0} />);
  render() {
    const { location_list, isLoading } = this.state;

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-green sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      <Link to="/administration" className="text-light">
                        {t("ManageLocation.title")}
                      </Link>
                    </p>
                    <LogoutMenu />
                  </div>
                </div>
              </div>

              <div className="middle-section sab-middle-section1 left-line-custom">
                {isLoading && this.loader}

                {location_list &&
                  location_list.map((item, index) => {
                    const {name, description, icon } = item;
                    return (
                      <div
                        className={
                          index == location_list.length - 1
                            ? "container my-4 z-index-99 last"
                            : "container my-4 z-index-99 "
                        }
                        key={index}
                      >
                        <a
                          href="javascript;:"
                          className="mb-4 d-block custom-hover-box"
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className="rounded-40 admin-bg float-left ml-5p mb-2">
                            <span className="text-light">
                              <img
                                src={`data:image/png;base64,${icon && icon}`}
                                alt="ico"
                                style={{ width: "24px" }}
                              />
                            </span>
                          </div>
                          <div className="pl-3 pt-1 d-flex" key={index}>
                            <div className="d-flex justify-content-between w-100">
                              <div className="clearfix">
                                <h6 className="m-0">{name && name}</h6>
                                <p className="text-muted font-12 mb-2">
                                  {description && description}
                                </p>
                              </div>
                              <div className="action-btns clearfix">
                                <i
                                  className="zmdi zmdi-edit zmdi-hc-lg mr-3 pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.changeStatusWithStatusValue(
                                      2,
                                      item
                                    );
                                  }}
                                />
                                <i
                                  className="zmdi zmdi-delete zmdi-hc-lg mr-3 pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleDelete(item);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                <LocationSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeRouteStatus={this.props.changeStatusWithStatusValue}
                />
                <FabIcon color="rounded-red" openSlider={this.openSlider} />
              </div>

              <Footer />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default withRouter(withTranslation()(LocationList));
