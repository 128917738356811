/* eslint eqeqeq: 0 */
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { MaillingListQuery } from "../../constants/apiParams";
import apiCall, { METHOD } from "../../constants/baseUrl";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import { checkUserPermission, getUserID } from "../../util/utils";
import FabIcon from "../CommanComponents/FabIcon";
import Footer from "../CommanComponents/Footer";
import LoadingPage from "../CommanComponents/LoadingPage";
import LogoutMenu from "../CommanComponents/LogoutMenu";
import TopHeader from "../CommanComponents/TopHeader";
import MailingItem from "./MailingItem";
import MailSetteliteSideMenu from "./MailSetteliteSideMenu";
import SearchMail from "./SearchMail";

class MailingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      mailList: [],
      end: 10,
      headerMenuStatus: false,
      loader: false,
      searchMailList: [],
    };
    this.searchRef = React.createRef();
  }

  handleLoader = (status) => {
    this.setState({
      loader: status,
    });
  };

  loader = (<LoadingPage key={0} />);

  openSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        status: true,
        headerMenuStatus: true,
      };
    });
  };

  closeSlider = () => {
    this.setState(() => {
      return {
        ...this.state,
        headerMenuStatus: false,
      };
    });
  };

  loadItem = () => {


      const { end, loader } = this.state;
      if (!loader && this.props.mailList.hasMore) {
        this.setState({
          loader: true,
        });
  
        apiCall(
          METHOD.QUERY,
          MaillingListQuery(getUserID(), this.props.mailList.start, end, ""),
          (data) => {
            this.props.getMailList(data.mailing_list);
            this.handleMailApiResponse(data);
          },
          (isLoading) => {},
          (data) => {
            toast.error(this.props.t(data.message));
            this.props.getMailHasMoreValue(false);
            this.setState({
              loader: false,
            });
          }
        );
      }
  
  };

  handleMailApiResponse = (data) => {
    if (data.is_last === 1) {
      this.props.getMailHasMoreValue(false);
    }
    this.props.getMailStartValue(this.props.mailList.start + this.state.end);

    this.setState({
      loader: false,
    });
  };

  setSearchMailList = (mailList) => {
    this.setState({
      searchMailList: mailList,
    });
  };

  componentDidMount() {
    this.setState(
      {
        hasMore: true,
        start: 0,
        mailList: [],
        isLoading: false,
      },
      () => {
        this.loadItem();
      }
    );
  }

  render() {
    const { end, searchMailList } = this.state;
    const readListPermission  = checkUserPermission("read_list");

    return (
      <Translation ns="translation">
        {(t, { i18n }) => {
          return (
            <React.Fragment>
              <div className="header-bg header-yellow sab-header1 sticky-top d-flex flex-column">
                <div className="container d-flex flex-column flex-fill">
                  <div className="text-center py-2 d-flex  align-items-center justify-content-between">
                    <TopHeader />
                    <p className="mb-0 ml-auto text-light">
                      {t("MailList.title")}
                    </p>
                    <LogoutMenu />
                  </div>
                  <SearchMail searchArray={this.setSearchMailList} />
                </div>
              </div>

              <div className="middle-section sab-middle-section1 ">
                {searchMailList.length != 0 && readListPermission ? (
                  <div className="container my-2">
                    {searchMailList.map((data, index) => {
                      return <MailingItem data={data} key={index} />;
                    })}
                  </div>
                ) : 
                readListPermission ?(    <InfiniteScroll
                  pageStart={end}
                  loadMore={this.loadItem}
                  hasMore={this.props.mailList.hasMore}
                  useWindow={false}
                  loader={this.loader}
                >
                  <div className="container my-3">
                    {this.props.mailList &&
                      this.props.mailList.mailList.map((data, index) => {
                        return (
                          <MailingItem
                            data={data}
                            key={index}
                            index={index}
                          />
                        );
                      })}
                  </div>
                </InfiniteScroll>):(
                  <span> {t("GeneralMessages.permissionError")}     </span>
                )
              
                }

                <MailSetteliteSideMenu
                  closeSlider={this.closeSlider}
                  headerMenuStatus={this.state.headerMenuStatus}
                  changeStatus={this.props.changeStatus}
                />
                <FabIcon color="rounded-yellow" openSlider={this.openSlider} />
              </div>
              <Footer type="4" />
            </React.Fragment>
          );
        }}
      </Translation>
    );
  }
}
export default connect(
  (state) =>
    stateToProps.appMapStateToProps([stateToProps.mailListStateToProps], state),
  actions.appMapDispatchToProps([
    actions.mailListDispatchToProps,
    actions.asyncApiDispatchToProps,
  ])
)(withRouter(withTranslation()(MailingList)));
