import { MAILING_LIST, ADD_NEW_MAIL, MAILING_START_VALUE, MAILING_HASMORE_VALUE } from "../constants/action-types";

export const getMailList = data => ({
  type: MAILING_LIST,
  payload: data
});

export const addNewMail = data => ({
  type: ADD_NEW_MAIL,
  payload: data
});
export const getMailStartValue = data => ({
  type: MAILING_START_VALUE,
  payload: data
});
export const getMailHasMoreValue = data => ({
  type: MAILING_HASMORE_VALUE,
  payload: data
});
